import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "assists/";

export default {
    get(period, partial, lesson) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + period + "/" + partial + "/" + lesson, { headers: { token } });
    },

    getOne(id) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + id, { headers: { token } });
    },

    getTotal(period, partial, lesson) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + "total/assists/" + period + "/" + partial + "/" + lesson, { headers: { token } });
    },

    getByDate(period, partial, date, lesson) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + period + "/" + partial + "/" + date + "/" + lesson, { headers: { token } });
    },

    insert(assist) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, assist, { headers: { token } });
    },

    update(id, assist) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, assist, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },
};