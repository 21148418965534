<template>
  <div class="contain">
    <v-progress-linear fixed :active="loading" :indeterminate="true"></v-progress-linear>
    <v-container v-show="compiler">
      <v-row>
        <v-col cols="12">
          <a @click="goBack()">
            <div class="text-subtitle-2">
              <v-icon class="mr-2" color="primary">mdi-chevron-left</v-icon>
              Administración
            </div>
          </a>
        </v-col>
        <v-fade-transition>
          <v-col cols="12" lg="4" xl="3" v-show="compiler">
            <v-card style="border-radius: 10px" :style="{
              background: $vuetify.theme.themes[theme].images,
            }">
              <v-card-text>
                <v-row align="center">
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center">
                      <div class="mt-5 d-md-none d-lg-flex"></div>
                      <v-avatar size="120">
                        <v-img lazy-src="../../../assets/images/default.png"
                          src="../../../assets/images/period.png"></v-img>
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" lg="12">
                    <div class="text-center">
                      <div class="text-h5 text--primary font-weight-bold">
                        Periodo {{ period_model.period }}
                      </div>
                      <div class="text-subtitle-2">
                        En {{ period_model.partial }}° parcial
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center">
                      <v-btn @click="save()" color="primary" dark elevation="0">
                        <div class="text-subtitle-2" style="text-transform: none">
                          Guardar cambios
                        </div>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-fade-transition>
        <v-slide-y-reverse-transition>
          <v-col cols="12" lg="8" xl="9" v-show="compiler">

            <v-card style="border-radius: 10px;" :style="{
              background: $vuetify.theme.themes[theme].images,
            }" class="mb-4">
              <v-card-text>
                <div class="text-subtitle-1">Conceptos de inscripción</div>
                <div class="text-caption">Por secciones</div>
                <v-row align="center" class="mt-4">
                  <v-col cols="12" sm="6">
                    <v-btn @click="conceptAction(true, 0)" color="primary">
                      <div class="text-subtitle-2" style="text-transform: none;">Nuevo concepto</div>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select v-model="conceptMode" v-on:input="getConcepts()" :items="[
                      { id: 1, name: 'INSCRIP- 273' },
                      { id: 2, name: 'PATBAC- 273' },
                      { id: 3, name: 'ITEBAC- 273' }
                    ]" item-value="id" item-text="name" outlined dense label="Tipo de concepto"
                      hide-details></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-expand-transition>
                <div class="alls" v-if="!openConcept">
                  <v-list-item v-for="item in concepts" :key="item.id" link @click="conceptAction(false, item.id)">
                    <v-list-item-title>
                      <div class="text-subtitle-2 text-truncate">
                        <span class="primary--text">${{ item.amount }}</span>
                        {{ item.description }}
                      </div>
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-icon color="primary">mdi-pencil-outline</v-icon>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item v-if="concepts.length == 0">
                    <v-list-item-title class="text-center">
                      Sin conceptos
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div class="single" v-if="openConcept">
                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="12" sm="6" md="8">
                        <v-text-field v-model="concept.description" filled dense label="Descripción"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="concept.amount" filled dense label="Monto" prefix="$"
                          suffix="MXN"></v-text-field>
                      </v-col>
                      <v-col cols="12" class="text-right">
                        <v-btn v-if="!insertConcept" @click="deleteConcept()" color="red" size="small" outlined>
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                        <v-btn @click="openConcept = false" size="small" outlined class="mx-2">
                          <div class="text-subtitle-2" style="text-transform: none;">
                            Cancelar
                          </div>
                        </v-btn>
                        <v-btn @click="saveConcept()" color="primary">
                          <div class="text-subtitle-2" style="text-transform: none;">
                            {{ insertConcept ? 'Guardar' : 'Actualizar' }} concepto
                          </div>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>

            <v-card style="border-radius: 10px" :style="{
              background: $vuetify.theme.themes[theme].images,
            }">
              <v-list-item>
                <v-list-item-title class="text-subtitle-2">
                  Parciales del semestre
                </v-list-item-title>
                <v-list-item-action>
                  <v-select v-model="period_model.partial" :items="partials" item-value="id" item-text="name" outlined
                    dense label="Actual" hide-details></v-select>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-expansion-panels flat accordion>
                <v-expansion-panel :style="{
                  background: $vuetify.theme.themes[theme].images,
                }">
                  <v-expansion-panel-header class="text-subtitle-2">
                    Primer parcial
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field v-model="period_model.start_first" type="date" filled dense label="Inicio"
                          class="range_selector"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="period_model.final_first" type="date" filled dense
                          label="Final"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel :style="{
                  background: $vuetify.theme.themes[theme].images,
                }">
                  <v-expansion-panel-header class="text-subtitle-2">
                    Segundo parcial
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field v-model="period_model.start_second" type="date" filled dense label="Inicio"
                          class="range_selector"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="period_model.final_second" type="date" filled dense
                          label="Final"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel :style="{
                  background: $vuetify.theme.themes[theme].images,
                }">
                  <v-expansion-panel-header class="text-subtitle-2">
                    Tercer parcial
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field v-model="period_model.start_third" type="date" filled dense label="Inicio"
                          class="range_selector"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="period_model.final_third" type="date" filled dense
                          label="Final"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="primary"> mdi-calendar-check-outline </v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="text-subtitle-2" :class="period_model.evaluation ? 'primary--text' : ''">
                  {{
                    period_model.evaluation
                      ? "Evaluación parcial (activa)"
                      : "Evaluación parcial"
                  }}
                </v-list-item-title>
                <v-list-item-action>
                  <v-switch v-model="period_model.evaluation" inset></v-switch>
                </v-list-item-action>
              </v-list-item>
            </v-card>
            <div class="text-h6 mt-8">Configurar</div>
            <v-row class="mt-2 mb-4">
              <v-col cols="12" sm="6" lg="4" v-for="step in steps" :key="step.id">
                <v-hover v-slot="{ hover }">
                  <v-card @click="navigate(step.id)" link :elevation="hover ? 4 : 2" style="border-radius: 10px" :style="{
                    background: $vuetify.theme.themes[theme].images,
                  }">
                    <v-img lazy-src="../../../assets/images/default.png" :src="step.image" contain></v-img>
                    <v-card-text>
                      <div class="text-subtitle-2 text--primary">
                        {{ step.name }}
                      </div>
                      <div class="text-caption">Configuración</div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-slide-y-reverse-transition>
      </v-row>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">
          {{ responseMessage }}
        </div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import auth from "../../../plugins/auth";
import settings from "../../../plugins/settings";
import periods from "../../../plugins/periods";
import concepts from '../../../plugins/concepts';

// resources
import curriculum_img from "../../../assets/images/curriculum.png";
import teachers_img from "../../../assets/images/teachers.png";
import groups_img from "../../../assets/images/groups.png";

export default {
  name: "Settings",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    period_model: {},

    partial: 0,
    partials: [
      { id: 1, name: "Primer parcial" },
      { id: 2, name: "Segundo parcial" },
      { id: 3, name: "Tercer parcial" },
    ],

    setter: false,
    steps: [
      { id: "/ad/cl/tc", name: "Docentes", image: teachers_img },
      { id: "/ad/cl/cm", name: "Mapa curricular", image: curriculum_img },
      { id: "/ad/cl/ls", name: "Horario escolar", image: groups_img },
    ],

    dates: [],
    loading: false,
    compiler: false,
    responseView: false,
    responseMessage: "",

    // Conceptos: 28 de julio 2024
    conceptMode: 1,
    concepts: [],
    concept: {},
    openConcept: false,
    insertConcept: true,
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;

        await auth.permission().then((response) => {
          if (response.data.directive == "admin") this.getSettings();
          else this.$router.push("/");
        });

        await this.getConcepts();
      }
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;

        this.getPeriod();
      });
    },

    async getPeriod() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.loading = false;
        this.compiler = true;
      });
    },

    // invoked
    async save() {
      await periods
        .update(this.period_model.id, this.period_model)
        .then((response) => {
          this.responseMessage = response.data.message;
          this.responseView = true;
        });
    },

    async getConcepts() {
      this.loading = true;
      await concepts.get(this.conceptMode).then(response => { this.loading = false; this.concepts = response.data; });
    },

    async conceptAction(ins, id) {
      this.insertConcept = ins;
      if (this.insertConcept) this.concept = {};
      else this.concept = this.concepts.find(reg => reg.id === id);
      this.openConcept = true;
    },

    async saveConcept() {
      this.concept.period = this.period_model.period;
      this.concept.type = this.conceptMode;
      this.loading = true;

      if (this.insertConcept)
        await concepts.insert(this.concept).then(response => {
          this.responseMessage = response.data.message; this.responseView = true;
          this.loading = false;
          this.openConcept = false;
        });
      else
        await concepts.update(this.concept.id, this.concept).then(response => {
          this.responseMessage = response.data.message; this.responseView = true;
          this.loading = false;
          this.openConcept = false;
        });

      await this.getConcepts();
    },

    async deleteConcept() {
      await concepts.delete(this.concept.id).then(async response => {
        this.responseMessage = response.data.message; this.responseView = true;
        this.loading = false;
        this.openConcept = false;

        await this.getConcepts();
      });
    },

    navigate(target) {
      this.$router.push(target);
    },

    goBack() {
      this.$router.push("/ad");
    },
  },
};
</script>