<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container>
      <v-row>
        <v-col cols="12">
          <a @click="back()">
            <div class="text-subtitle-2">
              <v-icon class="mr-2" color="primary">mdi-chevron-left</v-icon>
              Administración
            </div>
          </a>
        </v-col>
        <v-fade-transition>
          <v-col cols="12" lg="4" xl="3" v-show="compiler">
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
              class="mb-6"
            >
              <v-card-text>
                <v-row align="center">
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center">
                      <div class="mt-5 d-md-none d-lg-flex"></div>
                      <v-avatar size="120">
                        <v-img
                          lazy-src="../../../assets/images/default.png"
                          src="../../../assets/images/students.png"
                        ></v-img>
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" lg="12">
                    <div class="text-center">
                      <div class="text-h5 text--primary font-weight-bold">
                        Alumnos
                      </div>
                      <div class="text-subtitle-2">
                        Listado y control escolar
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center">
                      <v-btn
                        @click="openAction('sr')"
                        color="primary"
                        outlined
                        elevation="0"
                      >
                        <div
                          class="text-subtitle-2"
                          style="text-transform: none"
                        >
                          Buscar alumno
                        </div>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-list-item
                @click="openAction(step.id)"
                v-for="step in steps"
                :key="step.id"
                link
              >
                <v-list-item-avatar>
                  <v-img
                    lazy-src="../../../assets/images/default.png"
                    :src="step.image"
                    contain
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-title class="text-subtitle-2">
                  {{ step.name }}
                </v-list-item-title>
                <v-list-item-action>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
        </v-fade-transition>
        <v-slide-y-reverse-transition>
          <v-col cols="12" lg="8" xl="9" v-show="compiler">
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
              class="mb-6"
            >
              <v-list-item>
                <v-list-item-title class="text-subtitle-2">
                  Distribución por grupos
                </v-list-item-title>
                <v-list-item-action>
                  <v-menu :disabled="locked">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="toExcel()" link>
                        <v-list-item-icon>
                          <v-icon>mdi-microsoft-office</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Exportar a Excel
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-model="semester"
                      v-on:input="getGroups()"
                      :items="semesters"
                      item-value="id"
                      item-text="name"
                      outlined
                      hide-details
                      label="Semestre"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select
                      v-model="group"
                      v-on:input="getStudents()"
                      :items="groups"
                      item-value="groups"
                      item-text="groups"
                      no-data-text="Sin grupos"
                      outlined
                      hide-details
                      label="Grupo"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-list-item
                @click="openStudent(reg.student)"
                link
                v-for="reg in student_list"
                :key="reg.id"
              >
                <v-list-item-avatar>
                  <v-btn icon color="primary" text outlined>
                    <div class="text-subtitle-2 text-center">
                      {{ reg.number }}
                    </div>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="text-subtitle-2 text-truncate">
                    {{ reg.paternal_surname }}
                    {{ reg.maternal_surname }}
                    {{ reg.names }}
                  </div>
                  <div class="text-body-2 text--secondary">
                    {{ reg.enrollment }}
                  </div>
                </v-list-item-content>
                <v-list-item-action>
                  <v-avatar>
                    <v-img
                      lazy-src="../../../assets/images/default.png"
                      :src="back_url + reg.photo"
                    ></v-img>
                  </v-avatar>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
        </v-slide-y-reverse-transition>
      </v-row>
      <v-dialog v-model="action" width="600">
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-card-title
            class="text-h5 text--primary font-weight-bold text-truncate"
            style="display: block"
          >
            {{ action_info }}
          </v-card-title>
          <v-card-text>
            <div class="text-subtitle-2 text--primary">
              Buscar por el nombre del alumno (verifique ortografía, en especial
              acentos)
            </div>
            <v-autocomplete
              v-model="selected_student"
              :items="students_search"
              item-value="id"
              item-text="name"
              label="Nombre del alumno"
              clearable
              filled
              hide-details
              no-data-text="No hay alumnos"
              class="my-4"
            ></v-autocomplete>
          </v-card-text>
          <v-card-text
            :style="{
              background: $vuetify.theme.themes[theme].background,
            }"
          >
            <v-row>
              <v-col cols="6">
                <v-btn
                  @click="navigate()"
                  color="primary"
                  dark
                  elevation="0"
                  block
                >
                  <div class="text-subtitle-2" style="text-transform: none">
                    Buscar
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn @click="action = false" outlined elevation="0" block>
                  <div class="text-subtitle-2" style="text-transform: none">
                    Cancelar
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="downloading"
        full-screen
        persistent
        :no-click-animation="true"
      >
        <v-snackbar
          v-model="downloading"
          color="success"
          dark
          border="left"
          :timeout="-1"
          bottom
          left
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="text-subtitle-2">Exportando...</div>
              <div class="text-body-2">
                Después de exportar se descargará el archivo.
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <v-progress-circular indeterminate></v-progress-circular>
            </v-list-item-action>
          </v-list-item>
        </v-snackbar>
      </v-dialog>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">{{ responseMessage }}</div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import auth from "../../../plugins/auth";
import settings from "../../../plugins/settings";
import periods from "../../../plugins/periods";
import students from "../../../plugins/students";
import students_active from "../../../plugins/students_active";
import groups from "../../../plugins/groups";
import reports from "../../../plugins/reports";

// resources
import endpoint from "../../../plugins/endpoint";
import profile_img from "../../../assets/images/profile.png";
import inscription_img from "../../../assets/images/inscription.png";
import preinscription_img from "../../../assets/images/preinscription.png";
import reinscription_img from "../../../assets/images/reinscription.png";

export default {
  name: "Inscriptions",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    period_model: {},

    steps: [
      { id: "in", name: "Nuevo ingreso", image: inscription_img },
      {
        id: "pr",
        name: "Preinscripción",
        image: preinscription_img,
      },
      { id: "rn", name: "Reinscripciones", image: reinscription_img },
    ],

    action: false,
    action_type: 0,
    action_info: "",

    student_list: [],
    students_search: [],

    semester: 0,
    semesters: [
      { id: 1, name: "Primer semestre" },
      { id: 2, name: "Segundo semestre" },
      { id: 3, name: "Tercer semestre" },
      { id: 4, name: "Cuarto semestre" },
      { id: 5, name: "Quinto semestre" },
      { id: 6, name: "Sexto semestre" },
    ],
    group: "",
    groups: [],
    group_selection: {},

    insert: true,
    selected_student: 0,
    student: {},

    insert_reg: true,
    inscription_reg: {},

    student_action: false,
    search_action: false,
    reg_action: false,

    reg_status: true,

    applicants: [],
    change: false,

    custom: false,
    photo_url: profile_img,
    photo: [],
    info: "",

    // not taken (insider)
    not_takens: [],
    not_taken: {},
    curriculums: [],
    curriculum: 0,

    back_url: "",

    genders: [
      { id: "F", name: "Femenino" },
      { id: "M", name: "Masculino" },
    ],

    admin_permission: true,

    downloading: false,

    loading: false,
    compiler: false,
    locked: false,
    responseView: false,
    responseMessage: "",
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;
        this.back_url = endpoint.BASE_PATH;

        await auth.permission().then((response) => {
          if (response.data.directive == "admin") this.getSettings();
          else if (response.data.directive == "supervisor") {
            this.admin_permission = false;
            this.getSettings();
          } else this.$router.push("/");
        });
      }
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;

        this.getPeriod();
      });
    },

    async getPeriod() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.loading = false;
        this.compiler = true;
      });
    },

    // invoked
    async openAction(id) {
      // --- type info:}
      // 1 = search
      // 2 = inscriptions
      // 3 = preinscriptions
      // 4 = resinscriptions
      this.action_type = id;

      if (id == "sr") {
        this.action_info = "Consulta de alumno";
        await students.get().then((response) => {
          this.students_search = response.data;
        });
      }
      if (id == "in") this.navigate();
      if (id == "pr") {
        this.action_info = "Buscar preinscripción";
        await students.getApplicants().then((response) => {
          this.students_search = response.data;
        });
      }
      if (id == "rn") {
        this.action_info = "Buscar para reinscribir";
        await students.get().then((response) => {
          this.students_search = response.data;
        });
      }

      this.action = true;
    },

    navigate() {
      if (this.action_type == "in")
        this.$router.push("/ad/ins/" + this.action_type);
      else {
        if (this.selected_student != 0 && this.selected_student != null)
          this.$router.push("/ad/ins/sr/" + this.selected_student);
      }
    },

    openStudent(id) {
      this.$router.push("/ad/ins/sr/" + id);
    },

    async getStudents() {
      this.loading = true;
      await students_active
        .get(this.semester, this.group, this.period)
        .then((response) => {
          this.student_list = response.data;
          console.log(response.data);
          this.loading = false;
        });
    },

    async getGroups() {
      this.loading = true;
      this.group = "";
      this.student_list = [];

      await groups.get(this.semester, this.period).then((response) => {
        this.groups = response.data;
        this.loading = false;
      });
    },

    async toExcel() {
      this.downloading = true;
      var fileDownload = require("js-file-download");
      await reports
        .excelActiveStudents(this.semester, this.group, this.period)
        .then((response) => {
          fileDownload(
            response.data,
            "Lista " +
              this.semester +
              "° " +
              this.group +
              "(" +
              this.period +
              ").xlsx"
          );
          this.downloading = false;
        });
    },

    back() {
      this.$router.push("/ad");
    },
  },
};
</script>