import moment from "moment";

export default {
    inputDate(date) {
        console.log(date);
        var now = moment(date, "YYYYMMDDTHHmmss").format("YYYY-MM-DD");
        return now;
    },

    spellRating(rating) {
        var matrix = rating.split(".");
        var number = matrix[0];
        var decimal = matrix[1];

        var spelled_number = "";
        if (number == 0) spelled_number = "CERO";
        else if (number == 1) spelled_number = "UNO";
        else if (number == 2) spelled_number = "DOS";
        else if (number == 3) spelled_number = "TRES";
        else if (number == 4) spelled_number = "CUATRO";
        else if (number == 5) spelled_number = "CINCO";
        else if (number == 6) spelled_number = "SEIS";
        else if (number == 7) spelled_number = "SIETE";
        else if (number == 8) spelled_number = "OCHO";
        else if (number == 9) spelled_number = "NUEVE";
        else if (number == 10) spelled_number = "DIEZ";

        var complement = "";
        if (decimal == 0 || matrix.length < 2) complement = "";
        else complement = " PUNTO ";

        var spelled_decimal = "";
        if (decimal == 0) spelled_decimal = "";
        else if (decimal == 1) spelled_decimal = "UNO";
        else if (decimal == 2) spelled_decimal = "DOS";
        else if (decimal == 3) spelled_decimal = "TRES";
        else if (decimal == 4) spelled_decimal = "CUATRO";
        else if (decimal == 5) spelled_decimal = "CINCO";
        else if (decimal == 6) spelled_decimal = "SEIS";
        else if (decimal == 7) spelled_decimal = "SIETE";
        else if (decimal == 8) spelled_decimal = "OCHO";
        else if (decimal == 9) spelled_decimal = "NUEVE";
        else if (decimal == 10) spelled_decimal = "DIEZ";

        return spelled_number + complement + spelled_decimal;
    },

    normalizeInput(input) {
        return input.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
}