import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT_PATH = end.PATH + "teachers-data/";

export default {
    getOne(teacher) {
        const token = auth.getSession();
        return axios.get(ENDPOINT_PATH + teacher, { headers: { token } });
    },

    insert(data) {
        const token = auth.getSession();
        return axios.post(ENDPOINT_PATH, data, { headers: { token } });
    },

    update(teacher, data) {
        const token = auth.getSession();
        return axios.put(ENDPOINT_PATH + teacher, data, { headers: { token } });
    },

    delete(teacher) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT_PATH + teacher, { headers: { token } });
    },
};