<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container>
      <v-row>
        <v-col cols="12">
          <a @click="goBack()">
            <div class="text-subtitle-2">
              <v-icon class="mr-2" color="primary">mdi-chevron-left</v-icon>
              Control de asignatura
            </div>
          </a>
          <v-alert type="info" dismissible class="d-flex d-sm-none mt-4">
            Recomendamos rotar el dispositivo horizontalmente
          </v-alert>
          <v-row class="my-4">
            <v-slide-y-reverse-transition>
              <v-col cols="12" v-show="compiler">
                <v-card
                  style="border-radius: 10px"
                  :style="{
                    background: $vuetify.theme.themes[theme].images,
                  }"
                  class="mb-6"
                >
                  <v-card-text>
                    <v-row align="end">
                      <v-col cols="12" md="6">
                        <div class="text-h6 text--primary">
                          <v-icon color="error" class="mr-2">
                            mdi-alert-rhombus-outline
                          </v-icon>
                          Alumnos en riesgo
                        </div>
                        <div class="text-subtitle-2 text--primary mt-4">
                          Alumnos con
                          <span color="error">menos del 50%</span> del valor de
                          las evidencias
                        </div>
                        <div class="text-body-2">
                          {{ subject.name }} de {{ lesson.semester }}°
                          {{ lesson.groups }} ({{ period_model.partial }}°
                          parcial)
                        </div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-btn
                          color="warning"
                          dark
                          elevation="0"
                          style="float: right"
                        >
                          <div
                            class="text-subtitle-2 mx-2"
                            style="text-transform: none"
                          >
                            Informe general
                          </div>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-list-item
                    @click="
                      openValue(
                        student.id,
                        student.paternal_surname +
                          ' ' +
                          student.maternal_surname +
                          ' ' +
                          student.names,
                        student.photo
                      )
                    "
                    link
                    v-for="student in students_list"
                    :key="student.id"
                  >
                    <v-list-item-avatar size="30">
                      <v-img
                        lazy-src="../../../../assets/images/default.png"
                        :src="back_url + student.photo"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <div class="text-subtitle-2 text-truncate">
                        <v-icon color="warning" class="mr-2">
                          mdi-alert-circle-outline
                        </v-icon>
                        {{ student.number }}.
                        {{ student.paternal_surname }}
                        {{ student.maternal_surname }}
                        {{ student.names }}
                      </div>
                      <div class="text-subtitle-2">
                        <strong class="warning--text">
                          {{ student.evidences_value }}%
                        </strong>
                        <span class="text--secondary">
                          | {{ student.evidences }} entregadas,
                          <span
                            class="font-weight-medium"
                            :class="
                              student.fouls > 0
                                ? 'error--text'
                                : 'success--text'
                            "
                          >
                            {{ student.fouls }} faltas
                          </span>
                        </span>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-slide-y-reverse-transition>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="action" width="550" persistent>
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-card-title class="text-h6 text-truncate" style="display: block">
            {{ selected_student }}
          </v-card-title>
          <v-card-text>
            <div class="text-subtitle-2">
              Reporte de actividades al {{ period_model.partial }}° parcial, (en
              riesgo)
            </div>
            <div class="text-center mt-5">
              <v-avatar size="150">
                <v-img
                  lazy-src="../../../../assets/images/default.png"
                  :src="back_url + selected_photo"
                ></v-img>
              </v-avatar>
              <div class="text-h6 warning--text mt-4">
                {{ partial.evidences_value }}% total
              </div>
              <div class="text-subtitle-2 info--text">
                {{ partial.evidences }} de {{ evidences_total }} entregadas
              </div>
              <div class="text-subtitle-2 mb-2">
                <span :class="fouls_color">{{ partial.fouls }} faltas</span>
                en {{ assists_total }} clases {{ beca_info }}
              </div>
            </div>
          </v-card-text>
          <v-card-text
            :style="{
              background: $vuetify.theme.themes[theme].background,
            }"
          >
            <v-row align="center">
              <v-col cols="6">
                <v-btn color="warning" dark elevation="0" block>
                  <div class="text-subtitle-2" style="text-transform: none">
                    Informe
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn @click="action = false" outlined elevation="0" block>
                  <div class="text-subtitle-2" style="text-transform: none">
                    Cancelar
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="updater" width="80" persistent>
        <v-card style="border-radius: 10px" loading="true">
          <div class="text-center my-6">
            <v-progress-circular
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="downloading" width="350" persistent>
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-card-text>
            <div class="text-center">
              <v-progress-circular
                color="primary"
                size="50"
                indeterminate
                class="mt-10 mb-5"
              ></v-progress-circular>
              <div class="text-h6 mt-2 text--primary">Descargando</div>
              <div class="text-subtitle-2 text--primary mt-5">
                Creación de archivo en curso
              </div>
              <div class="text-caption font-weight-medium mt-2">
                Se esta ensamblando el reporte solicitado,
                <span class="primary--text">
                  el tiempo de espera puede ser hasta de 2 minutos (120 seg.)
                </span>
                . Gracias por su paciencia.
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">
          {{ responseMessage }}
        </div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import functions from "../../../../plugins/functions";
import auth from "../../../../plugins/auth";
import settings from "../../../../plugins/settings";
import periods from "../../../../plugins/periods";
import lessons from "../../../../plugins/lessons";
import curriculum from "../../../../plugins/curriculum";
import students_active from "../../../../plugins/students_active";
import partial from "../../../../plugins/partial";
import final from "../../../../plugins/final";
import reports from "../../../../plugins/reports";
import assists from "../../../../plugins/assists";
import evidences from "../../../../plugins/evidences";

// resources
import endpoint from "../../../../plugins/endpoint";

export default {
  name: "Risk",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    period_model: {},

    lesson: {},
    subject: {},
    subject_icon: "",

    students: [],
    students_list: [],

    assists_total: 0,
    beca_info: "",
    fouls_color: "",
    evidences_total: 0,

    action: false,
    partial: {},
    selected_student: "",
    selected_photo: "",

    // registro de todos los parciales (calificación final)
    first_partial: {},
    second_partial: {},
    third_partial: {},

    // finales
    final: {},
    insert_final: true,

    pes: false,
    act: false,

    // Integración de calificación final
    finals: true,

    back_url: "",

    loading: false,
    downloading: false,
    updater: false,
    compiler: false,

    responseView: false,
    responseMessage: "",

    error: false,
    errorInfo: "",
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;
        this.back_url = endpoint.BASE_PATH;
        this.subject.icon = "Ninguna";

        await auth.permission().then((response) => {
          if (response.data.directive == "teacher") this.getLesson();
          else this.$router.push("/");
        });
      }
    },

    async getLesson() {
      await lessons.getOne(this.$route.params.id).then((response) => {
        this.lesson = response.data;
        if (response.data.status == "error") this.goBack();
        else this.directive();
      });
    },

    async directive() {
      await auth.identity().then((response) => {
        if (response.data.directive != this.lesson.teacher) this.goBack();
        else this.getSubject();
      });
    },

    async getSubject() {
      await curriculum.getOne(this.lesson.subject).then((response) => {
        this.subject = response.data;
        this.subject_icon = response.data.icon;

        this.getSettings();
      });
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;

        this.getPeriods();
      });
    },

    async getPeriods() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        // Mostrar la información para la integración final
        if (this.period_model.partial == 3) this.finals = true;
        else this.finals = false;

        this.getStudents();
      });
    },

    async getStudents() {
      await students_active
        .getLesson(
          this.subject.id,
          this.lesson.semester,
          this.lesson.groups,
          this.period
        )
        .then((response) => {
          this.students = response.data;

          this.testPartial();
        });
    },

    async testPartial() {
      this.buildPartial();

      Promise.all(
        this.students.map(async (std) => {
          await partial
            .getBy(
              this.period_model.partial,
              this.lesson.id,
              std.student,
              this.period
            )
            .then(async (response) => {
              if (response.data.status == "error") {
                this.partial.student = std.student;

                await partial.insert(this.partial).then((response) => {
                  this.responseMessage = response.data.message;
                });
              }
            });
        })
      ).then(() => {
        this.getPartial();
      });
    },

    async getPartial() {
      await partial
        .getRisk(
          this.subject.id,
          this.lesson.semester,
          this.lesson.groups,
          this.lesson.period,
          this.period_model.partial
        )
        .then(async (response) => {
          this.students_list = response.data;

          // Seleccionar el total de ASISTENCIAS !! --
          await assists
            .getTotal(
              this.lesson.period,
              this.period_model.partial,
              this.lesson.id
            )
            .then(async (response) => {
              this.assists_total = response.data.total;

              // Seleccionar el total de EVIDENCIAS !! --
              await evidences
                .getTotal(
                  this.lesson.period,
                  this.period_model.partial,
                  this.lesson.id
                )
                .then((response) => {
                  this.evidences_total = response.data.total;

                  this.loading = false;
                  this.compiler = true;
                });
            });
        });
    },

    // invoked
    async openValue(id, student, photo) {
      this.loading = true;
      await partial.getOne(id).then(async (response) => {
        this.partial = response.data;
        this.selected_student = student;
        this.selected_photo = photo;

        if (this.partial.fouls == this.assists_total) {
          this.fouls_color = "error--text";
          this.beca_info = "(Viene por la beca)";
        } else if (this.partial.fouls == 0) {
          this.fouls_color = "success--text";
          this.beca_info = "(No falta)";
        } else {
          this.fouls_color = "warning--text";
          this.beca_info = "(Algunas clases)";
        }

        this.action = true;
        this.loading = false;
      });
    },

    async setValue() {
      if (this.partial.exam != null && this.partial.exam != "") {
        // -- validation
        var rating = parseFloat(this.partial.exam);
        if (isNaN(rating)) this.showError("Ingresar número válido");
        else if (rating < 0 || rating > 10)
          this.showError("Ingresar valor del 0 al 10");
        // -- end validation
        else {
          this.loading = true;

          // -- computed
          var exam_cent =
            parseFloat(this.partial.exam) *
            parseFloat(this.subject.exam_percent);
          var exam = parseFloat(exam_cent / 10);
          if (isNaN(exam)) exam = 0;

          var evidences = parseFloat(this.partial.evidences_value);
          var rating_cent = parseFloat(evidences + exam) / 10;
          var rating = parseFloat(rating_cent)
            .toString()
            .match(/^-?\d+(?:\.\d{0,1})?/)[0];
          if (isNaN(rating)) rating = 0;
          // -- end computed

          this.partial.exam_value = exam;

          // -- rating logic
          if (parseFloat(rating) >= 5) {
            this.partial.rating_number = rating;
            this.partial.rating_letter = functions.spellRating(rating);
          } else {
            if (parseFloat(rating) == 0) {
              this.partial.rating_number = "0";
              this.partial.rating_letter = "NO PRESENTÓ";
            } else {
              this.partial.rating_number = "5";
              this.partial.rating_letter = "CINCO";
            }
          }
          // -- end logic

          await partial
            .update(this.partial.id, this.partial)
            .then(async (response) => {
              this.responseMessage = response.data.message;

              // Integración de la calificación final
              if (this.finals) {
                // Suma de los 3 parciales
                var partial_sum =
                  parseFloat(this.first_partial.rating_number) +
                  parseFloat(this.second_partial.rating_number) +
                  parseFloat(this.partial.rating_number);

                // Calculo del promedio
                var average = partial_sum / 3;

                // Logica de calificaciones
                if (average == 0) {
                  // No presentó
                  this.final.number = "0";
                  this.final.letter = "NO PRESENTÓ";
                  this.final.average = "0";
                  this.final.status = false;
                } else {
                  if (Math.trunc(average) <= 5) {
                    // Reprobado (lógica de redondeo)
                    if (Math.trunc(average) < 5) {
                      // Reprobado con menor a 5 (5 obligatorio)
                      this.final.average = "5";
                    }
                    if (Math.trunc(average) == 5) {
                      // Reprobado con decimales en el 5
                      this.final.average = Math.trunc(average * 10) / 10;
                    }
                    this.final.number = "5";
                    this.final.letter = "CINCO";
                    this.final.status = false;
                  } else {
                    // No reprobado (calculos de redondeo)
                    this.final.number = Math.round(average);
                    this.final.letter = functions.spellRating(
                      this.final.number.toString()
                    );
                    this.final.average = Math.trunc(average * 10) / 10;
                    this.final.status = true;
                  }
                }

                // Inserción/edición  del final
                if (this.insert_final) {
                  // Construcción del final
                  this.final.lesson = this.partial.lesson;
                  this.final.subject = this.partial.subject;
                  this.final.student = this.partial.student;
                  this.final.period = this.period;
                  await final.insert(this.final).then((response) => {
                    this.responseMessage = response.data.message;
                    this.getPartial();
                    this.action = false;
                    this.responseView = true;
                  });
                } else {
                  await final
                    .update(this.final.id, this.final)
                    .then((response) => {
                      this.responseMessage = response.data.message;
                      this.getPartial();
                      this.action = false;
                      this.responseView = true;
                    });
                }
              } else {
                this.getPartial();
                this.action = false;
                this.responseView = true;
              }
            });
        }
      } else this.showError("Ingrese una calificación");
    },

    goBack() {
      this.$router.push("/op/ls/" + this.lesson.id);
    },

    // exports
    async pdfExport(type) {
      this.downloading = true;
      var fileDownload = require("js-file-download");
      await reports
        .pdfPartial(
          type,
          this.subject.id,
          this.lesson.semester,
          this.lesson.groups,
          this.lesson.period,
          this.period_model.partial
        )
        .then((response) => {
          var name;
          if (type == "") name = "Acta ";
          else name = "PES ";
          fileDownload(
            response.data,
            name +
              this.period_model.partial +
              "° parcial " +
              this.lesson.semester +
              "° " +
              this.lesson.groups +
              " (" +
              this.period +
              ").pdf"
          );
          this.downloading = false;
        });
    },

    // support
    buildPartial() {
      this.partial.partial = this.period_model.partial;
      this.partial.lesson = this.lesson.id;
      this.partial.subject = this.subject.id;
      this.partial.fouls = 0;
      this.partial.evidences = "0";
      this.partial.evidences_value = "0";
      this.partial.exam = "0";
      this.partial.exam_value = "0";
      this.partial.rating_number = "";
      this.partial.rating_letter = "";
      this.partial.period = this.period;
    },
    showError(message) {
      this.errorInfo = message;
      this.error = true;
      setTimeout(() => (this.error = false), 3000);
    },
  },
};
</script>