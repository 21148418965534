import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "periods/";

export default {
    get() {
        const token = auth.getSession();
        return axios.get(ENDPOINT, { headers: { token } });
    },

    getOne(period) {
        return axios.get(ENDPOINT + period); // <--- public!
    },

    insert(period) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, period, { headers: { token } });
    },

    update(id, period) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, period, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },
};