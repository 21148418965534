import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "assists-list/";

export default {
    get(subject, semester, group, period, assist) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + subject + "/" + semester + "/" + group + "/" + period + "/" + assist, { headers: { token } });
    },

    getOne(id) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + id, { headers: { token } });
    },

    getBy(assist, student) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + assist + "/" + student, { headers: { token } });
    },

    getTotal(lesson, student, partial, period) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + lesson + "/" + student + "/" + partial + "/" + period, { headers: { token } });
    },

    insert(assist) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, assist, { headers: { token } });
    },

    update(id, assist) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, assist, { headers: { token } });
    },

    assistUpdate(assist, list) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + "assist/" + assist, list, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },
};