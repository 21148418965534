<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container v-show="compiler">
      <v-row>
        <v-col cols="12">
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-avatar>
              <v-btn @click="$router.push('/alumnos/912')" icon>
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-title class="text-subtitle-2">
              Regresar al resumen académico
            </v-list-item-title>
            <v-list-item-action>
              <v-btn @click="welcome = true" color="primary" icon>
                <v-icon>mdi-contactless-payment-circle-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </v-col>
        <v-slide-y-reverse-transition>
          <v-col cols="12" lg="8" xl="9" v-show="compiler">
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
              class="mb-6"
            >
              <v-list-item>
                <v-list-item-title class="text-subtitle-2">
                  Curso por FELIX DARIO BAEZ GALVAN
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn color="primary" icon>
                    <v-icon>mdi-email-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-text>
                <div class="text-subtitle-">0 Bloques en Lógica I</div>
              </v-card-text>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="primary">mdi-bell-ring-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="text-subtitle-2">
                  Mensajes y avisos (1)
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-text>
                <div class="text-subtitle-2 text--primary">
                  Bienvenidos a mi clase
                </div>
                <div class="text-body-2 mt-2">
                  Mensaje de bienvenida, redactado por el maestro para resumir
                  el encuadre y tratar temas relacionados con la clase
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-slide-y-reverse-transition>

        <v-fade-transition>
          <v-col cols="12" lg="4" xl="3" v-show="compiler">
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
            >
              <v-card-text>
                <v-row align="center">
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center">
                      <div class="mt-5 d-md-none d-lg-flex"></div>
                      <v-avatar size="120">
                        <v-img
                          lazy-src="../../../../assets/images/default.png"
                          src="../../../../assets/subjects/Ninguna.png"
                        ></v-img>
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" lg="12">
                    <div class="text-center">
                      <div class="text-h5 text--primary font-weight-bold">
                        Lógica I
                      </div>
                      <div class="text-subtitle-2 text--primary">
                        Curso {{ period }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center">
                      <v-btn
                        @click="ratingsPDF()"
                        color="primary"
                        dark
                        elevation="0"
                      >
                        <div
                          class="text-subtitle-2"
                          style="text-transform: none"
                        >
                          <v-icon class="mr-2"> mdi-download-outline </v-icon>
                          Descargar encuadre
                        </div>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-fade-transition>
      </v-row>
      <v-dialog v-model="class_details" width="500">
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-img
                lazy-src="../../../../assets/images/default.png"
                :src="
                  require('../../../../assets/subjects/' + cl_icon + '.png')
                "
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="text-subtitle-2">{{ cl_subject }}</div>
              <div class="text-caption">Por: {{ cl_teacher }}</div>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="class_details = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <div class="text-center">
            <v-avatar
              :style="{
                background: $vuetify.theme.themes[theme].background,
              }"
              size="100"
              class="my-2"
            >
              <div
                class="text-h3"
                :class="
                  cl_complementary ? '' : cl_number >= 6 ? '' : 'error--text'
                "
              >
                {{ cl_number }}
              </div>
            </v-avatar>
            <div
              class="text-caption"
              :class="
                cl_complementary ? '' : cl_number >= 6 ? '' : 'error--text'
              "
            >
              {{ cl_letter }}
            </div>
          </div>
          <v-divider class="my-4"></v-divider>
          <v-card-text>
            <div class="text-subtitle-2 text--primary">
              Detalles ({{ period_model.partial }}° parcial)
            </div>
            <div class="text-subtitle-2 text--primary my-2">
              <v-icon small class="mr-2">mdi-clipboard-list-outline</v-icon>
              {{ cl_assists }}
            </div>
            <div class="text-subtitle-2 text--primary my-2">
              <v-icon small class="mr-2">
                mdi-file-table-box-multiple-outline
              </v-icon>
              {{ cl_evidences }}
            </div>
            <div class="text-subtitle-2 text--primary mt-2">
              <v-icon small class="mr-2"> mdi-file-star-outline </v-icon>
              {{ cl_test }}
            </div>
          </v-card-text>
          <v-divider v-show="cl_mail"></v-divider>
          <v-list-item
            :href="'mailto:' + cl_mail"
            target="_blank"
            v-show="cl_mail"
            link
          >
            <v-list-item-avatar>
              <v-icon>mdi-email-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="text-subtitle-2">Contactar al docente</div>
              <div class="text-caption">
                {{ cl_mail }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-dialog>
      <v-dialog v-model="welcome" width="900">
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-img
            :src="background_image"
            lazy-src="../../../../assets/images/default.png"
            style="border-radius: 10px"
            height="150"
            class="d-none d-md-flex"
          ></v-img>
          <v-card-title>
            <div class="text-h6">
              <v-avatar size="50" class="mr-3">
                <v-img :src="back_url + principal_img" contain></v-img>
              </v-avatar>
              {{ w_title }}
            </div>
          </v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" md="6">
                <div class="text-subtitle-2">
                  {{ w_body }}
                </div>
                <div class="text-subtitle-2 text--primary my-4">
                  {{ w_footer }}
                </div>
                <v-btn
                  @click="welcome = false"
                  color="primary"
                  dark
                  elevation="0"
                >
                  <div class="text-subtitle-2" style="text-transform: none">
                    Entendido
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-card
                  elevation="10"
                  link
                  class="mx-10"
                  style="border-radius: 10px"
                >
                  <v-img
                    lazy-src="../../../../assets/images/default.png"
                    src="../../../../assets/images/students.png"
                    height="150"
                    contain
                    class="d-none d-sm-flex"
                  ></v-img>
                  <v-list-item>
                    <v-list-item-title class="text-subtitle-2 primary--text">
                      Descargar manual
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-icon color="p ¡Bienvenido!imary"
                        >mdi-download-outline</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="downloading"
        full-screen
        persistent
        :no-click-animation="true"
      >
        <v-snackbar
          v-model="downloading"
          color="primary"
          dark
          border="left"
          :timeout="-1"
          bottom
          left
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-download-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="text-subtitl ¡Bienvenido!-2">Descargando...</div>
              <div class="text-body-2">Tiempo máximo: 120 seg.</div>
              <div class="text-caption">
                Nota: Algunas calificaciones pueden no aparecer si el docente no
                las ha asignado.
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <v-progress-circular indeterminate></v-progress-circular>
            </v-list-item-action>
          </v-list-item>
        </v-snackbar>
      </v-dialog>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">
          {{ responseMessage }}
        </div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import auth from "../../../../plugins/auth";
import settings from "../../../../plugins/settings";
import periods from "../../../../plugins/periods";
import students from "../../../../plugins/students";
import students_active from "../../../../plugins/students_active";
import partial from "../../../../plugins/partial";
import reports from "../../../../plugins/reports";
import assists from "../../../../plugins/assists";
import evidences from "../../../../plugins/evidences";
import teachers from "../../../../plugins/teachers";

// resources
import endpoint from "../../../../plugins/endpoint";
import profile_img from "../../../../assets/images/profile.png";

export default {
  name: "Classroom",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    school: "",
    period_model: {},
    background_image: "",
    data_pr: "",

    w_title: "",
    w_body: "",
    w_footer: "",

    profile: "",
    principal_img: "",
    student: {},
    student_active: {},
    photo_url: profile_img,

    // Detalles de la clase
    class_details: false,
    cl_icon: "Ninguna",
    cl_subject: "",
    cl_teacher: "",
    cl_number: "",
    cl_letter: "",
    cl_assists: "",
    cl_evidences: "",
    cl_test: "",
    cl_mail: "",
    cl_complementary: false,

    back_url: "",

    welcome: false,

    cardex_list: [],

    partials: [
      { id: 1, name: "Primer parcial" },
      { id: 2, name: "Segundo parcial" },
      { id: 3, name: "Tercer parcial" },
    ],

    loading: false,
    compiler: false,

    downloading: false,
    responseView: false,
    responseMessage: "",
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        // Datos generales
        await settings.getOne(1).then(async (response) => {
          this.back_url = endpoint.BASE_PATH;

          this.period = response.data.period;
          this.school = response.data.school;
          this.profile = endpoint.BASE_PATH + response.data.profile_image;
          this.background_image =
            endpoint.BASE_PATH + response.data.background_image;

          this.data_pr = response.data.principal;

          this.w_title = response.data.w_title;
          this.w_body = response.data.w_body;
          this.w_footer = response.data.w_footer;

          // Periodo escolar
          await periods.getOne(this.period).then(async (response) => {
            this.period_model = response.data;

            // Estudiante
            await students
              .getOne(this.$route.params.id)
              .then(async (response) => {
                this.student = response.data;
                this.photo_url = endpoint.BASE_PATH + this.student.photo;

                // Inscripción
                await students_active
                  .getByStudent(this.student.id, this.period)
                  .then((response) => {
                    // No hay inscripción actual (baja)
                    if (response.data.status == "error")
                      this.$router.push("/alumnos");
                    else {
                      this.student_active = response.data;

                      // Materias
                      this.getCardex();
                    }
                  });
              });
          });
        });
      }
    },

    async getCardex() {
      this.loading = true;
      await partial
        .getCardex(this.period_model.partial, this.student.id, this.period)
        .then(async (response) => {
          this.cardex_list = response.data;

          // Foto del director (ay que wapo)
          await teachers
            .getByName(this.data_pr.toUpperCase().trim())
            .then((response) => {
              this.principal_img = response.data.profile_image;
            });
          this.welcome = false;
          this.loading = false;
          this.compiler = true;
        });
    },

    async openStatus(
      icon,
      subject,
      teacher,
      mail,
      number,
      letter,
      assist_info,
      evidences_info,
      evidences_total,
      test,
      test_total,
      lesson,
      complementary
    ) {
      this.loading = true;
      this.cl_icon = icon;
      this.cl_subject = subject;
      this.cl_teacher = teacher;
      this.cl_mail = mail;
      if (complementary) {
        if (number >= 6) {
          this.cl_number = "A";
          this.cl_letter = "APROBADA";
        } else {
          this.cl_number = "N/A";
          this.cl_letter = "NO APROBADA";
        }
      } else {
        this.cl_number = number;
        this.cl_letter = letter;
      }
      this.cl_complementary = complementary;
      // Número de asistencias (pases de lista)
      await assists
        .getTotal(this.period, this.period_model.partial, lesson)
        .then(async (response) => {
          var ast = parseFloat(response.data.total - assist_info);
          this.cl_assists = ast + " de " + response.data.total + " asistencias";

          // Número de evidencias calificadas
          await evidences
            .getTotal(this.period, this.period_model.partial, lesson)
            .then((response) => {
              this.cl_evidences =
                evidences_info +
                " de " +
                response.data.total +
                " evidencias (" +
                evidences_total +
                "%)";

              this.cl_test = test + " de examen (" + test_total + "%)";
              this.class_details = true;
              this.loading = false;
            });
        });
    },

    // Imprimir boleta
    async ratingsPDF() {
      this.downloading = true;
      var fileDownload = require("js-file-download");
      await reports
        .getRatings(this.student_active.id, this.period_model.partial)
        .then((response) => {
          fileDownload(
            response.data,
            "Boleta " +
              this.period_model.partial +
              "° parcial (" +
              this.student.paternal_surname +
              " " +
              this.student.maternal_surname +
              " " +
              this.student.names +
              ").pdf"
          );
          this.downloading = false;
        });
    },
  },
};
</script>                      