import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "nottaken/";

export default {
    get(student, period) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + student + "/" + period, { headers: { token } });
    },

    insert(nt) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, nt, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },
};