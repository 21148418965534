import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "evidences-list/";

export default {
    get(subject, semester, group, period, evidence) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + subject + "/" + semester + "/" + group + "/" + period + "/" + evidence, { headers: { token } });
    },

    getOne(id) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + id, { headers: { token } });
    },

    getBy(evidence, student) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + evidence + "/" + student, { headers: { token } });
    },

    getTotal(lesson, student, partial, period) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + lesson + "/" + student + "/" + partial + "/" + period, { headers: { token } });
    },

    insert(evidence) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, evidence, { headers: { token } });
    },

    update(id, evidence) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, evidence, { headers: { token } });
    },

    evidenceUpdate(evidence, list) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + "evidence/" + evidence, list, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },
};