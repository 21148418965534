import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + 'lessons/';

export default {
    listByTeacher(period, id) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + period + "/" + id, { headers: { token } });
    },

    listByGroup(semester, group, period) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + period + "/" + semester + "/" + group, { headers: { token } });
    },

    getOne(id) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + id, { headers: { token } });
    },

    insert(lesson) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, lesson, { headers: { token } });
    },

    update(id, lesson) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, lesson, { headers: { token } });
    },

    setTeacher(id) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + "teacher/" + id, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },

    deleteCurriculum(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + "curriculum/" + id, { headers: { token } });
    },

    deleteGroup(semester, group, period) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + semester + "/" + group + "/" + period, { headers: { token } });
    },
};