<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container>
      <v-row>
        <v-col cols="12">
          <a @click="exit()">
            <div class="text-subtitle-2">
              <v-icon class="mr-2" color="primary">mdi-chevron-left</v-icon>
              Administración
            </div>
          </a>
        </v-col>
        <v-fade-transition>
          <v-col cols="12" lg="4" xl="3" v-show="compiler">
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
            >
              <v-card-text>
                <v-row align="center">
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center mt-4">
                      <v-avatar size="90" tile>
                        <v-img
                          lazy-src="../../../assets/images/default.png"
                          :src="logotypeURL"
                          contain
                        ></v-img>
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" lg="12">
                    <div class="text-center">
                      <div class="text-h5 text--primary font-weight-bold">
                        {{ settings.school }}
                      </div>
                      <div class="text-subtitle-2">
                        {{ settings.clave }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center">
                      <v-btn
                        @click="selectFile(2)"
                        color="primary"
                        outlined
                        elevation="0"
                      >
                        <div
                          class="text-subtitle-2"
                          style="text-transform: none"
                        >
                          Cambiar logotipo oficial
                        </div>
                      </v-btn>
                      <div class="mt-4">
                        <v-btn @click="custom = !custom" color="primary" text>
                          <div
                            class="text-subtitle-2"
                            style="text-transform: none"
                          >
                            Identidad
                          </div>
                          <v-icon class="ml-3">
                            {{ custom ? "mdi-chevron-up" : "mdi-chevron-down" }}
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-expand-transition>
                <div v-show="custom">
                  <v-divider></v-divider>
                  <v-expand-transition>
                    <div class="light" v-show="!$vuetify.theme.dark">
                      <v-list-item>
                        <v-list-item-title
                          class="text-subtitle-2 primary--text"
                        >
                          Tema claro
                        </v-list-item-title>
                      </v-list-item>
                      <v-card-text>
                        <v-row justify="center" class="mb-4">
                          <v-btn
                            @click="setPalette(true, color.code)"
                            v-for="color in lightColors"
                            :key="color.code"
                            icon
                          >
                            <v-avatar :color="color.code" size="25"> </v-avatar>
                          </v-btn>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                    </div>
                  </v-expand-transition>
                  <v-expand-transition>
                    <div class="dark" v-show="$vuetify.theme.dark">
                      <v-list-item>
                        <v-list-item-title
                          class="text-subtitle-2 primary--text"
                        >
                          Tema oscuro
                        </v-list-item-title>
                      </v-list-item>
                      <v-card-text>
                        <v-row justify="center" class="mb-4">
                          <v-btn
                            @click="setPalette(false, color.code)"
                            v-for="color in darkColors"
                            :key="color.code"
                            icon
                          >
                            <v-avatar :color="color.code" size="25"> </v-avatar>
                          </v-btn>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                    </div>
                  </v-expand-transition>
                  <v-list-item @click="selectFile(1)" link>
                    <v-list-item-avatar tile>
                      <v-img
                        lazy-src="../../../assets/images/default.png"
                        :src="profileURL"
                        contain
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title class="text-subtitle-2">
                      Cambiar imagen de perfil
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="selectFile(3)" link>
                    <v-list-item-avatar>
                      <v-img
                        lazy-src="../../../assets/images/default.png"
                        :src="backgroundURL"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title class="text-subtitle-2">
                      Cambiar fondo de pantalla
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-fade-transition>
        <v-slide-y-reverse-transition>
          <v-col cols="12" lg="8" xl="9" v-show="compiler">
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
            >
              <v-list-item>
                <v-list-item-title class="text-subtitle-2">
                  Datos del plantel
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-text>
                <v-text-field
                  v-model="settings.school"
                  hide-details
                  filled
                  autocomplete="off"
                  label="Nombre de la escuela"
                ></v-text-field>
                <v-text-field
                  v-model="settings.clave"
                  maxlength="10"
                  hide-details
                  filled
                  autocomplete="off"
                  label="Clave"
                  class="my-4"
                ></v-text-field>
                <v-text-field
                  v-model="settings.address"
                  hide-details
                  filled
                  autocomplete="off"
                  label="Domicilio actual"
                ></v-text-field>
                <v-text-field
                  v-model="settings.city"
                  hide-details
                  filled
                  autocomplete="off"
                  label="Ciudad o localidad"
                  class="my-4"
                ></v-text-field>
                <v-textarea
                  v-model="settings.contacts"
                  filled
                  auto-grow
                  hide-details
                  autocomplete="off"
                  label="Mensaje de contacto"
                ></v-textarea>
                <v-select
                  v-model="settings.principal"
                  :items="teachers"
                  item-value="username"
                  item-text="username"
                  hide-details
                  filled
                  label="Director del plantel"
                  no-data-text="Sin docentes"
                  class="my-4"
                ></v-select>
                <v-select
                  v-model="settings.vice_principal"
                  :items="teachers"
                  item-value="username"
                  item-text="username"
                  hide-details
                  filled
                  no-data-text="Sin docentes"
                  label="Subdirector"
                ></v-select>
                <v-text-field
                  v-model="settings.administrative"
                  hide-details
                  filled
                  autocomplete="off"
                  label="Administrativo escolar"
                  class="mt-4"
                ></v-text-field>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <div class="text-subtitle-2 mb-4">Fechas de referencia</div>
                <v-text-field
                  v-model="settings.ref_date_1"
                  type="date"
                  hide-details
                  filled
                  autocomplete="off"
                  label="Fecha reinco primer parcial"
                ></v-text-field>
                <v-text-field
                  v-model="settings.ref_date_2"
                  type="date"
                  hide-details
                  filled
                  autocomplete="off"
                  label="Fecha reinco segundo parcial"
                  class="my-4"
                ></v-text-field>
                <v-text-field
                  v-model="settings.ref_date_3"
                  type="date"
                  hide-details
                  filled
                  autocomplete="off"
                  label="Fecha reinco tercer parcial"
                ></v-text-field>
              </v-card-text>
            </v-card>
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
              class="my-6"
            >
              <v-list-item>
                <v-list-item-title class="text-subtitle-2">
                  Modalidad y turno
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="primary"> mdi-school-outline </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="text-body-2">
                  La modalidad determina las funciones especificas que le
                  ayudarán a controlar de mejor manera su plantel, como son la
                  configuración de horarios, el diseño de los formatos reinco y
                  la normatividad de la evaluación.
                </v-list-item-content>
              </v-list-item>
              <v-card-text>
                <v-select
                  v-model="settings.mode"
                  :items="modes"
                  item-text="name"
                  item-value="id"
                  solo
                  flat
                  dense
                  hide-details
                  menu-props="auto"
                  label="Escolaridad*"
                ></v-select>
              </v-card-text>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="primary"> mdi-clock-outline </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="text-body-2">
                  El turno es auxiliar en la configuración de los horarios
                  escolares y aparecerá como referencia en los reportes
                </v-list-item-content>
              </v-list-item>
              <v-card-text>
                <v-select
                  v-model="settings.turn"
                  :items="turns"
                  item-text="name"
                  item-value="id"
                  solo
                  flat
                  dense
                  hide-details
                  menu-props="auto"
                  label="Turno*"
                ></v-select>
              </v-card-text>
            </v-card>
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
            >
              <v-list-item>
                <v-list-item-title class="text-subtitle-2">
                  Periodo escolar y edición
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn @click="open_period = true" color="primary" icon>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="primary"> mdi-calendar-range-outline </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="text-body-2">
                  El periodo o ciclo, se encarga de organizar todos los
                  registros de acuerdo a la normatividad escolar, por su
                  seguridad, la edición sensible (eliminación de campos) está
                  desactivada.
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-text>
                <v-select
                  v-model="settings.period"
                  :items="periods"
                  item-value="period"
                  item-text="period"
                  solo
                  flat
                  dense
                  hide-details
                  label="Seleccionar periodo actual*"
                ></v-select>
              </v-card-text>
              <v-divider></v-divider>
              <v-list-item @click="settings.editions = !settings.editions" link>
                <v-list-item-avatar>
                  <v-icon :color="settings.editions ? 'warning' : ''">
                    {{
                      settings.editions
                        ? "mdi-alert-octagon"
                        : "mdi-alert-octagon-outline"
                    }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title
                  class="text-subtitle-2"
                  :class="
                    settings.editions ? 'font-weight-bold warning--text' : ''
                  "
                  >Edición sensible ({{
                    settings.editions ? "Activada" : "Desactivada"
                  }})
                </v-list-item-title>
                <v-list-item-action>
                  <v-icon x-large :color="settings.editions ? 'warning' : ''">
                    {{
                      settings.editions
                        ? "mdi-toggle-switch"
                        : "mdi-toggle-switch-off-outline"
                    }}
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-card>
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
              class="my-4"
            >
              <v-list-item>
                <v-list-item-title class="text-subtitle-2">
                  Aviso a la comunidad
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-text>
                <v-text-field
                  v-model="settings.w_title"
                  hide-details
                  filled
                  autocomplete="off"
                  label="Título del aviso"
                ></v-text-field>
                <v-textarea
                  v-model="settings.w_body"
                  filled
                  hide-details
                  autocomplete="off"
                  label="Contenido del aviso"
                  class="my-4"
                ></v-textarea>
                <v-text-field
                  v-model="settings.w_footer"
                  hide-details
                  filled
                  autocomplete="off"
                  label="Despedida"
                ></v-text-field>
              </v-card-text>
            </v-card>
            <v-btn
              @click="save(true)"
              class="mt-6 mb-10"
              color="primary"
              dark
              large
              elevation="0"
            >
              <div class="text-subtitle-2 mx-5" style="text-transform: none">
                Guardar todo y salir
              </div>
            </v-btn>
          </v-col>
        </v-slide-y-reverse-transition>
      </v-row>
      <v-dialog v-model="open_period" width="400">
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-card-title
            class="text-h5 text--primary font-weight-bold text-truncate"
            style="display: block"
          >
            Nuevo periodo escolar
          </v-card-title>
          <v-card-text>
            <div class="text-subtitle-2 text--primary">
              Alta de un periodo escolar
            </div>
            <v-text-field
              v-model="newPeriod"
              label="Periodo escolar"
              filled
              hide-details
              class="my-4"
            ></v-text-field>
            <div class="text-subtitle-2 text--primary mt-6">
              Recomendaciones
            </div>
            <div class="text-body-2 text--primary my-2">
              Cambiar e ingresar con el formato correcto (aaaa-aaaa) un periodo
              es responsabilidad del usuario operador de tipo Dirección Escolar.
              Ningun otro tipo de usuario tendrá acceso a este cambio, por lo
              que su gestión es única de la dirección escolar.
            </div>
          </v-card-text>
          <v-expand-transition>
            <div v-show="error">
              <v-alert dense text type="error">
                <div class="text-subtitle-2">{{ errorInfo }}</div>
              </v-alert>
            </div>
          </v-expand-transition>
          <v-card-text
            :style="{
              background: $vuetify.theme.themes[theme].background,
            }"
          >
            <v-row>
              <v-col cols="6">
                <v-btn
                  @click="addPeriod()"
                  color="primary"
                  dark
                  elevation="0"
                  block
                >
                  <div class="text-subtitle-2" style="text-transform: none">
                    Guardar
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="open_period = false"
                  outlined
                  elevation="0"
                  block
                >
                  <div class="text-subtitle-2" style="text-transform: none">
                    Cancelar
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="upload" width="600">
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-card-title
            class="text-h5 text--primary font-weight-bold text-truncate"
            style="display: block"
          >
            {{ uploadSelection }}
          </v-card-title>
          <v-card-text>
            <div class="text-subtitle-2 text--primary">
              Seleccionar un archivo de tipo imagen de pc local
            </div>
            <v-file-input
              v-model="selectedFile"
              label="Seleccionar archivo"
              accept="image/png, image/jpeg, image/svg"
              hide-details
              class="my-4"
            ></v-file-input>
            <div class="text-subtitle-2 text--primary mt-6">
              Recomendaciones
            </div>
            <div class="text-body-2 text--primary my-2">
              Para la imagen de perfil y el logotipo oficial, se recomienda
              utilizar el formato PNG (.png) o SVG (.svg), este tipo de formato
              no cuenta con un fondo y podrá adaptarse mejor a la
              personalización.
            </div>
          </v-card-text>
          <v-card-text
            :style="{
              background: $vuetify.theme.themes[theme].background,
            }"
          >
            <v-row>
              <v-col cols="6">
                <v-btn
                  @click="uploadFile()"
                  color="primary"
                  dark
                  elevation="0"
                  block
                >
                  <div class="text-subtitle-2" style="text-transform: none">
                    Guardar
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn @click="upload = false" outlined elevation="0" block>
                  <div class="text-subtitle-2" style="text-transform: none">
                    Cancelar
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="output" timeout="2000" color="info" top right>
        <div class="text-subtitle-2">{{ outMessage }}</div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import auth from "../../../plugins/auth";
import settings from "../../../plugins/settings";
import files from "../../../plugins/files";
import periods from "../../../plugins/periods";
import teachers from "../../../plugins/teachers";
import functions from "../../../plugins/functions";

// resources
import endpoint from "../../../plugins/endpoint";

export default {
  name: "Settings",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    insert: true,
    settings: {},

    profileURL: "",
    logotypeURL: "",
    backgroundURL: "",

    open_period: false,
    newPeriod: "",
    newPeriodModel: {},

    selectedFile: [],

    lightColors: [
      { code: "#1565C0", name: "Azul" },
      { code: "#283593", name: "Azul oscuro" },
      { code: "#388E3C", name: "Verde" },
      { code: "#1B5E20", name: "Verde opaco" },
      { code: "#5D4037", name: "Café" },
      { code: "#455A64", name: "Gris" },
      { code: "#E65100", name: "Naranja obscuro" },
      { code: "#4A148C", name: "Morado" },
      { code: "#B71C1C", name: "Rojo" },
    ],
    darkColors: [
      { code: "#1976D2", name: "Azul" },
      { code: "#1E88E5", name: "Azul claro" },
      { code: "#43A047", name: "Verde" },
      { code: "#E53935", name: "Rojo" },
      { code: "#D81B60", name: "Rosa" },
      { code: "#F57C00", name: "Naranja" },
      { code: "#FFA000", name: "Amarillo" },
      { code: "#00897B", name: "Verde opaco" },
      { code: "#00ACC1", name: "Cyan" },
    ],

    loading: false,
    compiler: false,

    modes: [
      { id: 1, name: "Escolarizado" },
      { id: 2, name: "Mixto" },
      { id: 3, name: "Sabatino" },
      { id: 4, name: "Experimental" },
    ],
    turns: [
      { id: 1, name: "Matutino" },
      { id: 2, name: "Vespertino" },
      { id: 3, name: "Discontínuo" },
    ],
    periods: [],
    teachers: [],

    alertIndicator: false,
    alertMessage: "",
    output: false,
    outMessage: "",

    custom: false,
    upload: false,
    uploadType: 0,
    uploadSelection: "",

    error: false,
    errorInfo: "",
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;

        await auth.permission().then((response) => {
          if (response.data.directive == "admin") this.getSettings();
          else this.$router.push("/");
        });
      }
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        if (response.data.status == "error") {
          this.insert = true;
        } else {
          this.settings = response.data;
          this.profileURL = endpoint.BASE_PATH + response.data.profile_image;
          this.logotypeURL = endpoint.BASE_PATH + response.data.logotype;
          this.backgroundURL =
            endpoint.BASE_PATH + response.data.background_image;
          this.settings.ref_date_1 = functions.inputDate(
            this.settings.ref_date_1
          );
          this.settings.ref_date_2 = functions.inputDate(
            this.settings.ref_date_2
          );
          this.settings.ref_date_3 = functions.inputDate(
            this.settings.ref_date_3
          );

          this.getPeriods();
        }
      });
    },

    async getPeriods() {
      this.loading = true;
      await periods.get().then((response) => {
        this.periods = response.data;

        this.getTeachers();
      });
    },

    async getTeachers() {
      await teachers.selector().then((response) => {
        this.teachers = response.data;

        this.insert = false;
        this.loading = false;
        this.compiler = true;
      });
    },

    // invoked
    async addPeriod() {
      if (this.newPeriod != "" && this.newPeriod != null) {
        this.newPeriodModel.period = this.newPeriod;
        this.newPeriodModel.partial = 1;
        this.newPeriodModel.evaluation = 0;

        await periods.insert(this.newPeriodModel).then((response) => {
          this.outMessage = response.data.message;
          this.getPeriods();
          this.output = true;

          this.open_period = false;
        });
      } else this.showError("Ingresar el periodo");
    },

    selectFile(type) {
      this.uploadType = type;
      if (type == 1) this.uploadSelection = "Cambio de imagen de perfil";
      if (type == 2) this.uploadSelection = "Cambio de logotipo oficial";
      if (type == 3) this.uploadSelection = "Cambio de fondo de pantalla";
      this.upload = true;
    },

    async uploadFile() {
      const formData = new FormData();
      let docType = "";
      formData.append("file", this.selectedFile);

      if (this.uploadType == 1) docType = "profile";
      if (this.uploadType == 2) docType = "logotype";
      if (this.uploadType == 3) docType = "background";

      await files.upload(formData, docType).then((response) => {
        if (this.uploadType == 1)
          this.settings.profile_image = response.data.path;
        if (this.uploadType == 2) this.settings.logotype = response.data.path;
        if (this.uploadType == 3)
          this.settings.background_image = response.data.path;

        this.save(false);
      });
    },

    async save(exit) {
      if (
        this.settings.school != "" &&
        this.settings.clave != "" &&
        this.settings.address != "" &&
        this.settings.mode != 0
      ) {
        this.loading = true;

        let last_update = new Date();
        this.settings.last_update = last_update.toISOString();
        this.settings.user = "Dirección";

        this.settings.school = this.settings.school.trim();
        this.settings.clave = this.settings.clave.trim();
        this.settings.address = this.settings.address.trim();
        this.settings.contacts = this.settings.contacts.trim();
        this.settings.principal = this.settings.principal.trim();
        this.settings.vice_principal = this.settings.vice_principal.trim();
        this.settings.administrative = this.settings.administrative.trim();

        if (this.insert) {
          await settings.insert(this.settings).then((response) => {
            this.outMessage = response.data.message;
          });
        } else {
          await settings.update(1, this.settings).then((response) => {
            this.outMessage = response.data.message;

            this.output = true;
            this.loading = false;

            console.log(response.data);

            if (exit) this.exit();
            else location.reload();
          });
        }
      } else {
        this.alertMessage = "Los campos marcados * son obligatorios";
        this.alertIndicator = true;
      }
    },

    setPalette(light, code) {
      if (light) {
        this.settings.primary_light = code;
        this.$vuetify.theme.themes.light.primary = code;
      } else {
        this.settings.primary_dark = code;
        this.$vuetify.theme.themes.dark.primary = code;
      }
    },

    showError(message) {
      this.errorInfo = message;
      this.error = true;
      setTimeout(() => (this.error = false), 3000);
    },

    exit() {
      this.$router.push("/ad");
    },
  },
};
</script>


<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>