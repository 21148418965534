<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container v-show="compiler">
      <v-row>
        <v-col cols="12">
          <a @click="goBack()">
            <div class="text-subtitle-2">
              <v-icon class="mr-2" color="primary">mdi-chevron-left</v-icon>
              Administración
            </div>
          </a>
        </v-col>
        <v-fade-transition>
          <v-col cols="12" lg="4" xl="3" v-show="compiler">
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
            >
              <v-card-text>
                <v-row align="center">
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center">
                      <div class="mt-5 d-md-none d-lg-flex"></div>
                      <v-avatar size="120">
                        <v-img
                          lazy-src="../../../assets/images/default.png"
                          src="../../../assets/images/contents.png"
                        ></v-img>
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" lg="12">
                    <div class="text-center">
                      <div class="text-h5 text--primary font-weight-bold">
                        Contenidos
                      </div>
                      <div class="text-subtitle-2">
                        Registros globales para el {{ period }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center">
                      <v-btn color="primary" outlined elevation="0" disabled>
                        <div
                          class="text-subtitle-2"
                          style="text-transform: none"
                        >
                          Habilitar plataforma
                        </div>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-fade-transition>
        <v-slide-y-reverse-transition>
          <v-col cols="12" lg="8" xl="9" v-show="compiler">
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
            >
              <v-list-item>
                <v-list-item-title class="text-subtitle-2">
                  Plataforma educativa
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-close-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="text-subtitle-2 text--secondary">
                  No disponible
                </v-list-item-title>
              </v-list-item>
            </v-card>
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
              class="my-6"
            >
              <v-list-item>
                <v-list-item-title class="text-subtitle-2">
                  Actividades
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-text>
                <div class="text-subtitle-2 text--primary mb-4">
                  <span class="primary--text">
                    {{ evidences_types.length }} tipos
                  </span>
                  de actividades registradas
                </div>
                <v-btn
                  @click="openEvidence(false, 0)"
                  color="primary"
                  dark
                  elevation="0"
                >
                  <div
                    class="text-subtitle-2 mx-2"
                    style="text-transform: none"
                  >
                    Nueva actividad
                  </div>
                </v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-data-iterator
                :items="evidences_types"
                item-key="id"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                no-data-text=""
                no-results-text=""
                hide-default-footer
                class="my-6"
              >
                <template v-slot:header>
                  <v-row class="mx-1 mb-2" align="center">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          class="ml-2"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        >
                          <div
                            class="text-subtitle-2"
                            style="text-transform: none"
                          >
                            Últimas {{ itemsPerPage }}
                          </div>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(number, index) in itemsPerPageArray"
                          :key="index"
                          @click="updateItemsPerPage(number)"
                        >
                          <v-list-item-title>
                            {{ number }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <div class="text-body-2 text--secondary">
                      Lista {{ page }} de {{ numberOfPages }}
                    </div>
                    <v-btn icon class="mx-1 my-2" @click="formerPage">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon class="ml-1 mr-2 my-2" @click="nextPage">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                  <v-divider></v-divider>
                </template>
                <template v-slot:default="{ items }">
                  <template v-for="(item, i) in items">
                    <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>
                    <v-list-item
                      @click="openEvidence(true, item.id)"
                      link
                      :key="`${i}-${item.id}`"
                    >
                      <v-list-item-avatar>
                        <v-icon color="primary"
                          >mdi-bookmark-check-outline</v-icon
                        >
                      </v-list-item-avatar>
                      <v-list-item-title class="text-subtitle-2">
                        {{ item.type }}
                        <span class="primary--text"> ({{ item.id }}) </span>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </template>
              </v-data-iterator>
              <v-divider></v-divider>
              <v-list-item @click="comments = !comments" link>
                <v-list-item-title class="subtitle-2">
                  Observaciones ({{ evidences_comments.length }})
                </v-list-item-title>
                <v-list-item-action>
                  <v-icon>
                    {{ comments ? "mdi-chevron-up" : "mdi-chevron-down" }}
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-expand-transition>
                <div v-show="comments">
                  <v-card-text>
                    <v-text-field
                      v-model="evidence_comment.comment"
                      filled
                      dense
                      hide-details
                      label="Nueva observación"
                      append-icon="mdi-plus"
                      @click:append="saveComment()"
                      class="mb-4"
                    ></v-text-field>
                    <v-expand-transition>
                      <div v-show="error">
                        <v-alert dense text type="error">
                          <div class="text-subtitle-2">{{ errorInfo }}</div>
                        </v-alert>
                      </div>
                    </v-expand-transition>
                  </v-card-text>
                  <v-list-item
                    link
                    v-for="comment in evidences_comments"
                    :key="comment.id"
                  >
                    <v-list-item-avatar>
                      <v-icon color="primary">
                        mdi-comment-processing-outline
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title class="text-subtitle-2">
                      {{ comment.comment }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="deleteComment(comment.id)"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            color="error"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-slide-y-reverse-transition>
      </v-row>
      <v-dialog v-model="evidence_action" width="600">
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-card-title class="text-h5 font-weight-bold">
            Actividad
          </v-card-title>
          <v-card-text>
            <div class="text-subtitle-2 mt-2">
              Evidencia para evaluar a los alumnos
            </div>
            <v-text-field
              v-model="evidence_type.type"
              filled
              dense
              hide-details
              label="Nombre de la actividad"
              class="my-4"
            ></v-text-field>
            <v-expand-transition>
              <div v-show="error">
                <v-alert dense text type="error">
                  <div class="text-subtitle-2">{{ errorInfo }}</div>
                </v-alert>
              </div>
            </v-expand-transition>
          </v-card-text>
          <v-card-text
            :style="{
              background: $vuetify.theme.themes[theme].background,
            }"
          >
            <v-row align="center">
              <v-col cols="6">
                <v-btn
                  @click="saveEvidence()"
                  color="primary"
                  dark
                  elevation="0"
                  block
                >
                  <div class="text-subtitle-2" style="text-transform: none">
                    Guardar
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="evidence_action = false"
                  outlined
                  elevation="0"
                  block
                >
                  <div class="text-subtitle-2" style="text-transform: none">
                    Cancelar
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">
          {{ responseMessage }}
        </div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import auth from "../../../plugins/auth";
import settings from "../../../plugins/settings";
import periods from "../../../plugins/periods";
import evidences_types from "../../../plugins/evidences_types";
import evidences_comments from "../../../plugins/evidences_comments";

// resources

export default {
  name: "Settings",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    numberOfPages() {
      return Math.ceil(this.evidences_types.length / this.itemsPerPage);
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    period_model: {},

    evidences_types: [],
    evidence_type: {},
    evidence_action: false,

    insert: true,

    evidences_comments: [],
    evidence_comment: {},
    comments: false,

    loading: false,
    compiler: false,

    responseView: false,
    responseMessage: "",

    error: false,
    errorInfo: "",

    itemsPerPageArray: [3, 6, 9],
    page: 1,
    itemsPerPage: 3,
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;

        await auth.permission().then((response) => {
          if (response.data.directive == "admin") this.getSettings();
          else this.$router.push("/");
        });
      }
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;

        this.getPeriod();
      });
    },

    async getPeriod() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.getEvidences();
      });
    },

    // invoked
    async getEvidences() {
      this.loading = true;
      await evidences_types.get().then((response) => {
        this.evidences_types = response.data;

        this.getComments();
      });
    },

    async getComments() {
      this.loading = true;
      await evidences_comments.get().then((response) => {
        this.evidences_comments = response.data;

        this.loading = false;
        this.compiler = true;
      });
    },

    async openEvidence(edit, id) {
      if (!edit) {
        this.evidence_type = {};

        this.insert = true;
        this.evidence_action = true;
      } else {
        await evidences_types.getOne(id).then((response) => {
          this.evidence_type = response.data;

          this.insert = false;
          this.evidence_action = true;
        });
      }
    },

    async saveEvidence() {
      if (this.evidence_type.type != null && this.evidence_type.type != "") {
        this.loading = true;
        if (this.insert) {
          await evidences_types.insert(this.evidence_type).then((response) => {
            this.responseMessage = response.data.message;

            this.responseView = true;
            this.loading = false;
            this.getEvidences();
            this.evidence_action = false;
          });
        } else {
          await evidences_types
            .update(this.evidence_type.id, this.evidence_type)
            .then((response) => {
              this.responseMessage = response.data.message;

              this.responseView = true;
              this.loading = false;
              this.getEvidences();
              this.evidence_action = false;
            });
        }
      } else this.showError("Falta el nombre de la actividad");
    },

    async saveComment() {
      if (
        this.evidence_comment.comment != null &&
        this.evidence_comment.comment != ""
      ) {
        this.loading = true;
        await evidences_comments
          .insert(this.evidence_comment)
          .then((response) => {
            this.responseMessage = response.data.message;

            this.getComments();
            this.evidence_comment = {};
            this.responseView = true;
            this.loading = false;
          });
      } else this.showError("Falta la observación");
    },

    async deleteComment(id) {
      this.loading = true;
      await evidences_comments.delete(id).then((response) => {
        this.responseMessage = response.data.message;

        this.getComments();
        this.evidence_comment = {};
        this.responseView = true;
        this.loading = false;
      });
    },

    goBack() {
      this.$router.push("/ad");
    },

    // support
    showError(message) {
      this.errorInfo = message;
      this.error = true;
      setTimeout(() => (this.error = false), 3000);
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
};
</script>