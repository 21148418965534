<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="11" lg="10" xl="9">
          <a @click="goBack()">
            <div class="text-subtitle-2">
              <v-icon class="mr-2" color="primary">mdi-chevron-left</v-icon>
              Periodo escolar
            </div>
          </a>
          <v-alert v-show="editions" type="warning" class="my-4">
            Edición de campos importantes activada.
          </v-alert>
          <v-row class="my-4">
            <v-slide-y-transition>
              <v-col cols="12" sm="4" lg="3" v-show="compiler">
                <v-card color="primary" style="border-radius: 10px">
                  <v-img
                    lazy-src="../../../../assets/images/default.png"
                    src="../../../../assets/images/groups.png"
                    height="300"
                    contain
                  ></v-img>
                </v-card>
              </v-col>
            </v-slide-y-transition>
            <v-fade-transition>
              <v-col cols="12" sm="8" lg="9" v-show="compiler">
                <div class="text-h5 font-weight-bold">Horario escolar</div>
                <div class="text-subtitle-2 text--secondary mt-2">
                  Por grupos del periodo {{ period_model.period }}
                </div>
                <v-row class="my-4">
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="semester"
                      v-on:input="getGroups()"
                      :items="semesters"
                      item-value="id"
                      item-text="name"
                      label="Seleccionar semestre"
                      outlined
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-expand-transition>
                    <v-col v-show="!locked" cols="12" md="6">
                      <v-select
                        v-model="group_id"
                        v-on:input="getLessons()"
                        :items="groups"
                        item-value="groups"
                        item-text="groups"
                        label="Grupo"
                        outlined
                        hide-details
                        no-data-text="No hay grupos"
                      ></v-select>
                    </v-col>
                  </v-expand-transition>
                  <v-col cols="12">
                    <v-btn
                      @click="openGroup(false)"
                      color="primary"
                      dark
                      elevation="0"
                      :disabled="locked"
                      class="mt-4"
                    >
                      <div class="text-subtitle-2" style="text-transform: none">
                        Nuevo grupo
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-fade-transition>
            <v-slide-y-reverse-transition>
              <v-col cols="12" v-show="compiler">
                <v-card
                  style="border-radius: 10px"
                  :style="{
                    background: $vuetify.theme.themes[theme].images,
                  }"
                  class="mb-6"
                >
                  <v-list-item>
                    <v-list-item-title class="text-subtitle-2">
                      Clases {{ header }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-btn
                        @click="openGroup(true)"
                        color="primary"
                        icon
                        elevation="0"
                        :disabled="!isGroup"
                      >
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-row no-gutters>
                    <v-col
                      cols="6"
                      md="4"
                      lg="3"
                      v-for="lesson in lessons"
                      :key="lesson.id"
                    >
                      <v-card
                        @click="openLesson(lesson.id)"
                        link
                        outlined
                        style="border-radius: 10px"
                        :style="{
                          background: $vuetify.theme.themes[theme].images,
                        }"
                      >
                        <div class="text-center">
                          <v-avatar size="120" class="my-4">
                            <v-img
                              lazy-src="../../../../assets/images/default.png"
                              :src="
                                require('../../../../assets/subjects/' +
                                  lesson.icon +
                                  '.png')
                              "
                            ></v-img>
                          </v-avatar>
                        </div>
                        <v-card-text>
                          <div
                            class="text-subtitle-2 text--primary text-truncate"
                          >
                            {{ lesson.name }}
                          </div>
                          <div class="text-caption text--secondary">
                            {{
                              lesson.complementary
                                ? "Complementaria"
                                : "Curricular"
                            }}
                          </div>
                          <div
                            class="
                              text-caption
                              font-weight-medium
                              text-truncate
                              mt-6
                            "
                          >
                            <v-avatar size="25" class="mr-3">
                              <v-img
                                lazy-src="../../../../assets/images/default.png"
                                :src="back_url + lesson.profile_image"
                              ></v-img>
                            </v-avatar>
                            {{ lesson.teacher }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-slide-y-reverse-transition>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="new_group" width="400">
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-card-title
            class="text-h5 text--primary font-weight-bold text-truncate"
            style="display: block"
          >
            <v-btn
              v-show="editions"
              @click="leaveGroup()"
              color="warning"
              dark
              elevation="0"
              class="mr-2"
              :disabled="edit_disabled"
            >
              <div class="text-subtitle-2 mx-2" style="text-transform: none">
                Baja
              </div>
            </v-btn>
            Grupo
          </v-card-title>
          <v-card-text>
            <div class="text-subtitle-2 text--primary mt-2">
              Referencia: {{ semester }}° semestre. grupo:
              {{ group.groups }}
            </div>
            <v-text-field
              v-model="group.groups"
              dense
              filled
              hide-details
              label="Letra o número"
              class="my-4"
            ></v-text-field>
            <v-expand-transition>
              <div v-show="isArea">
                <v-select
                  v-model="group.area"
                  :items="areas"
                  dense
                  filled
                  hide-details
                  label="Área propedéutica"
                  clearable
                  :disabled="disable_area"
                ></v-select>
              </div>
            </v-expand-transition>
            <div class="text-body-2 my-4">
              <v-icon small class="mr-2">mdi-information-outline</v-icon>
              Al presionar el botón de guardar, los cambios serán reflejados en
              el selector del grupo.
            </div>
            <v-expand-transition>
              <div v-show="error">
                <v-alert dense text type="error">
                  <div class="text-subtitle-2">{{ errorInfo }}</div>
                </v-alert>
              </div>
            </v-expand-transition>
          </v-card-text>
          <v-card-text
            :style="{
              background: $vuetify.theme.themes[theme].background,
            }"
          >
            <v-row>
              <v-col cols="6">
                <v-btn @click="save()" color="primary" dark elevation="0" block>
                  <div class="text-subtitle-2" style="text-transform: none">
                    Guardar
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn @click="new_group = false" outlined elevation="0" block>
                  <div class="text-subtitle-2" style="text-transform: none">
                    Cancelar
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="action" fullscreen hide-overlay scrollable>
        <v-card
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-toolbar
            :style="{
              background: $vuetify.theme.themes[theme].images,
            }"
          >
            <v-app-bar-nav-icon class="ml-1">
              <v-btn @click="action = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-app-bar-nav-icon>
            <v-toolbar-title class="text-subtitle-1 font-weight-medium">
              Clase impartida
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="saveLesson()"
              color="primary"
              dark
              elevation="0"
              class="mr-2"
            >
              <div class="text-subtitle-2 mx-2" style="text-transform: none">
                Guardar
              </div>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row justify="center" class="my-8">
              <v-col cols="12" md="9" lg="8" xl="7">
                <div v-show="error">
                  <v-alert dense text type="error">
                    <div class="text-subtitle-2">{{ errorInfo }}</div>
                  </v-alert>
                </div>
                <v-row>
                  <v-col cols="12" md="4" lg="3">
                    <div class="text-center">
                      <v-avatar size="150">
                        <v-img
                          lazy-src="../../../../assets/images/default.png"
                          :src="
                            require('../../../../assets/subjects/' +
                              selected_icon +
                              '.png')
                          "
                        ></v-img>
                      </v-avatar>
                      <div class="text-subtitle-2">
                        {{
                          selected_cm.complementary
                            ? "Complementaria"
                            : "Regular"
                        }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="8" lg="9">
                    <div class="text-h5 font-weight-bold text--primary">
                      Clase de {{ selected_cm.name }} {{ period }}
                    </div>
                    <div class="text-subtitle-2 mt-2">
                      En {{ lesson.semester }}° semestre, grupo
                      {{ lesson.groups }}
                    </div>
                    <v-select
                      v-model="selected_teacher"
                      :items="teachers"
                      item-value="id"
                      item-text="name"
                      filled
                      dense
                      hide-details
                      label="Docente que imparte esta clase"
                      class="my-4"
                    >
                    </v-select>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Horario de clase
                    </div>
                    <v-checkbox
                      v-model="lesson.monday"
                      label="Lunes"
                    ></v-checkbox>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="lesson.monday_start"
                          type="time"
                          filled
                          dense
                          hide-details
                          label="Hora inicial"
                          :disabled="!lesson.monday"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="lesson.monday_end"
                          type="time"
                          filled
                          dense
                          hide-details
                          label="Hora final"
                          :disabled="!lesson.monday"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-checkbox
                      v-model="lesson.tuesday"
                      label="Martes"
                    ></v-checkbox>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="lesson.tuesday_start"
                          type="time"
                          filled
                          dense
                          hide-details
                          label="Hora inicial"
                          :disabled="!lesson.tuesday"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="lesson.tuesday_end"
                          type="time"
                          filled
                          dense
                          hide-details
                          label="Hora final"
                          :disabled="!lesson.tuesday"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-checkbox
                      v-model="lesson.wednesday"
                      label="Miércoles"
                    ></v-checkbox>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="lesson.wednesday_start"
                          type="time"
                          filled
                          dense
                          hide-details
                          label="Hora inicial"
                          :disabled="!lesson.wednesday"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="lesson.wednesday_end"
                          type="time"
                          filled
                          dense
                          hide-details
                          label="Hora final"
                          :disabled="!lesson.wednesday"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-checkbox
                      v-model="lesson.thursday"
                      label="Jueves"
                    ></v-checkbox>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="lesson.thursday_start"
                          type="time"
                          filled
                          dense
                          hide-details
                          label="Hora inicial"
                          :disabled="!lesson.thursday"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="lesson.thursday_end"
                          type="time"
                          filled
                          dense
                          hide-details
                          label="Hora final"
                          :disabled="!lesson.thursday"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-checkbox
                      v-model="lesson.friday"
                      label="Viernes"
                    ></v-checkbox>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="lesson.friday_start"
                          type="time"
                          filled
                          dense
                          hide-details
                          label="Hora inicial"
                          :disabled="!lesson.friday"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="lesson.friday_end"
                          type="time"
                          filled
                          dense
                          hide-details
                          label="Hora final"
                          :disabled="!lesson.friday"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-checkbox
                      v-model="lesson.saturday"
                      label="Sábado"
                    ></v-checkbox>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="lesson.saturday_start"
                          type="time"
                          filled
                          dense
                          hide-details
                          label="Hora inicial"
                          :disabled="!lesson.saturday"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="lesson.saturday_end"
                          type="time"
                          filled
                          dense
                          hide-details
                          label="Hora final"
                          :disabled="!lesson.saturday"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">
          {{ responseMessage }}
        </div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import auth from "../../../../plugins/auth";
import settings from "../../../../plugins/settings";
import periods from "../../../../plugins/periods";
import groups from "../../../../plugins/groups";
import curriculum from "../../../../plugins/curriculum";
import lessons from "../../../../plugins/lessons";
import teachers from "../../../../plugins/teachers";

// resources
import endpoint from "../../../../plugins/endpoint";
import profile_img from "../../../../assets/images/profile.png";

export default {
  name: "Lessons",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    period_model: {},

    groups: [],
    group: {},
    group_id: "",

    curriculums: [],
    lessons: [],
    lesson: {},

    semester: 0,
    semesters: [
      { id: 1, name: "Primer semestre" },
      { id: 2, name: "Segundo semestre" },
      { id: 3, name: "Tercer semestre" },
      { id: 4, name: "Cuarto semestre" },
      { id: 5, name: "Quinto semestre" },
      { id: 6, name: "Sexto semestre" },
    ],
    areas: [
      "Físico-Matemáticas",
      "Químico-Biológicas",
      "Sociales-Humanidades",
      "Económico-Administrativas",
    ],

    new_group: false,
    isGroup: false,
    insert: true,

    isArea: false,
    disable_area: false,

    header: "(seleccionar semestre y grupo)",

    back_url: "",
    profile_url: profile_img,

    // Baja de curriculum
    editions: false,
    edit_disabled: true,

    // lessons (internal module)
    action: false,
    selected_cm: {},
    selected_icon: "Ninguna",
    selected_teacher: 0,

    teachers: [],

    loading: false,
    compiler: false,
    locked: true,

    responseView: false,
    responseMessage: "",

    error: false,
    errorInfo: "",
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;
        this.back_url = endpoint.BASE_PATH;

        await auth.permission().then((response) => {
          if (response.data.directive == "admin") this.getSettings();
          else this.$router.push("/");
        });
      }
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;
        this.editions = response.data.editions;

        this.getPeriod();
      });
    },

    async getPeriod() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.getTeachers();
      });
    },

    async getTeachers() {
      await teachers.get().then((response) => {
        this.teachers = Object.values(response.data).filter(
          (type) => type.type === 3
        );

        this.loading = false;
        this.compiler = true;
      });
    },

    // invoked
    async getGroups() {
      this.loading = true;
      this.group_id = "";
      this.lessons = [];
      this.isGroup = false;
      this.header = "(seleccionar semestre y grupo)";
      await groups.get(this.semester, this.period).then((response) => {
        this.groups = response.data;
        this.locked = false;
        this.loading = false;
      });
    },

    async getLessons() {
      this.loading = true;
      await lessons
        .listByGroup(this.semester, this.group_id, this.period)
        .then((response) => {
          this.lessons = response.data;

          if (this.group_id != "") {
            this.header =
              this.semester +
              "° semestre, grupo " +
              this.group_id +
              " (" +
              this.lessons.length +
              ")";

            this.isGroup = true;
            this.loading = false;
          }
        });
    },

    async openGroup(edit) {
      if (this.semester == 5 || this.semester == 6) this.isArea = true;
      else this.isArea = false;

      if (!edit) {
        this.edit_disabled = true;
        this.group = {};

        this.insert = true;
        this.disable_area = false;
        this.new_group = true;
      } else {
        this.edit_disabled = false;
        await groups
          .getOne(this.semester, this.group_id, this.period)
          .then((response) => {
            this.group = response.data;

            this.insert = false;
            this.disable_area = true;
            this.new_group = true;
          });
      }
    },

    async save() {
      if (this.group.groups != null && this.group.groups != "") {
        if (this.semester == 5 || this.semester == 6) {
          if (this.group.area != null) this.saveGroup();
          else this.showError("Seleccione un área");
        } else this.saveGroup();
      } else this.showError("Especifíque un grupo");
    },

    async saveGroup() {
      if (this.insert) {
        this.loading = true;

        this.group.semester = this.semester;
        this.group.period = this.period;

        await groups.insert(this.group).then((response) => {
          this.lessonInsertion();

          this.responseMessage = response.data.message;

          this.refresh();
        });
      } else {
        await groups.update(this.group.id, this.group).then((response) => {
          this.responseMessage = response.data.message;

          this.refresh();
        });
      }
    },

    async refresh() {
      this.getGroups();
      this.loading = false;
      this.new_group = false;
      this.responseView = true;
    },

    async lessonInsertion() {
      this.loading = true;
      this.lesson = {};
      await curriculum
        .getBySemester(this.semester, this.period)
        .then((response) => {
          if (this.semester == 5 || this.semester == 6) {
            this.curriculums = Object.values(response.data).filter(
              (area) => area.area === this.group.area
            );
          } else this.curriculums = response.data;

          // Insertar la nueva clase
          this.lesson.semester = this.semester;
          this.lesson.groups = this.group.groups;
          this.lesson.teacher = 1;
          this.lesson.monday = false;
          this.lesson.monday_start = "00:00:00";
          this.lesson.monday_end = "00:00:00";
          this.lesson.tuesday = false;
          this.lesson.tuesday_start = "00:00:00";
          this.lesson.tuesday_end = "00:00:00";
          this.lesson.wednesday = false;
          this.lesson.wednesday_start = "00:00:00";
          this.lesson.wednesday_end = "00:00:00";
          this.lesson.thursday = false;
          this.lesson.thursday_start = "00:00:00";
          this.lesson.thursday_end = "00:00:00";
          this.lesson.friday = false;
          this.lesson.friday_start = "00:00:00";
          this.lesson.friday_end = "00:00:00";
          this.lesson.saturday = false;
          this.lesson.saturday_start = "00:00:00";
          this.lesson.saturday_end = "00:00:00";
          this.lesson.period = this.period;
          // Por cada una de las materias, insertar una nueva clase

          Promise.all(
            this.curriculums.map(async (crm) => {
              this.lesson.subject = crm.id;
              await lessons.insert(this.lesson).then((response) => {
                console.log(response.data.message);
              });
            })
          ).then(() => {
            console.log("Terminado");
            this.loading = false;
          });
        });
    },

    async leaveGroup() {
      await lessons
        .deleteGroup(this.group.semester, this.group.groups, this.period)
        .then(async (response) => {
          console.log(response.data.message);

          await groups.delete(this.group.id).then((response) => {
            this.refresh();

            this.responseMessage = response.data.message;
            this.responseView = true;
          });
        });
    },

    // --- lessons (internal module) ---
    async openLesson(id) {
      this.loading = true;
      await lessons.getOne(id).then((response) => {
        this.lesson = response.data;

        this.selected_teacher = response.data.teacher;
        this.getSelectedCm(this.lesson.subject);
      });
    },

    async getSelectedCm(subject) {
      await curriculum.getOne(subject).then((response) => {
        this.selected_cm = response.data;
        this.selected_icon = response.data.icon;

        this.action = true;
        this.loading = false;
      });
    },

    async saveLesson() {
      this.lesson.teacher = this.selected_teacher;
      await lessons.update(this.lesson.id, this.lesson).then((response) => {
        this.responseMessage = response.data.message;

        this.getLessons();

        this.action = false;
        this.responseView = true;
      });
    },

    showError(message) {
      this.errorInfo = message;
      this.error = true;
      setTimeout(() => (this.error = false), 3000);
    },

    goBack() {
      this.$router.push("/ad/cl");
    },
  },
};
</script>