<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="11" lg="10" xl="9">
          <a @click="goBack()">
            <div class="text-subtitle-2">
              <v-icon class="mr-2" color="primary">mdi-chevron-left</v-icon>
              Periodo escolar
            </div>
          </a>
          <v-alert v-show="editions" type="warning" class="my-4">
            Edición de campos importantes activada.
          </v-alert>
          <v-row class="my-4">
            <v-slide-y-transition>
              <v-col cols="12" sm="4" lg="3" v-show="compiler">
                <v-card color="primary" style="border-radius: 10px">
                  <v-img
                    lazy-src="../../../../assets/images/default.png"
                    src="../../../../assets/images/teachers.png"
                    height="300"
                    contain
                  ></v-img>
                </v-card>
              </v-col>
            </v-slide-y-transition>
            <v-fade-transition>
              <v-col cols="12" sm="8" lg="9" v-show="compiler">
                <div class="text-h5 font-weight-bold">Docentes</div>
                <div class="text-subtitle-2 text--secondary mt-2">
                  Usuarios activos del periodo escolar
                  {{ period_model.period }}
                </div>
                <v-row class="my-4">
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="type"
                      v-on:input="getTeachers()"
                      :items="types"
                      item-value="id"
                      item-text="name"
                      label="Tipo de cuenta operativa"
                      outlined
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="openTeacher(false, 0)"
                      color="primary"
                      dark
                      elevation="0"
                      :disabled="locked"
                      class="mt-4"
                    >
                      <div class="text-subtitle-2" style="text-transform: none">
                        Nueva cuenta
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-fade-transition>
            <v-slide-y-reverse-transition>
              <v-col cols="12" v-show="compiler">
                <v-card
                  style="border-radius: 10px"
                  :style="{
                    background: $vuetify.theme.themes[theme].images,
                  }"
                  class="mb-6"
                >
                  <v-list-item>
                    <v-list-item-title class="text-subtitle-2">
                      {{ account_type }} Activos {{ header }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-row no-gutters>
                    <v-col
                      cols="6"
                      md="4"
                      lg="3"
                      v-for="teacher in teachers"
                      :key="teacher.id"
                    >
                      <v-card
                        @click="openTeacher(true, teacher.id)"
                        link
                        outlined
                        style="border-radius: 10px"
                        :style="{
                          background: $vuetify.theme.themes[theme].images,
                        }"
                      >
                        <div class="text-center mt-4">
                          <v-avatar size="120" class="my-4">
                            <v-img
                              lazy-src="../../../../assets/images/default.png"
                              :src="back_url + teacher.profile_image"
                            ></v-img>
                          </v-avatar>
                        </div>
                        <v-card-text class="text-center">
                          <div
                            class="text-subtitle-2 text--primary text-truncate"
                          >
                            {{ teacher.name }}
                          </div>
                          <div
                            class="text-caption text--secondary text-truncate"
                          >
                            {{ teacher.mail || "Sin correo" }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-slide-y-reverse-transition>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="action" fullscreen hide-overlay scrollable>
        <v-card
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-toolbar
            :style="{
              background: $vuetify.theme.themes[theme].images,
            }"
          >
            <v-app-bar-nav-icon class="ml-1">
              <v-btn @click="action = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-app-bar-nav-icon>
            <v-toolbar-title class="text-subtitle-1 font-weight-medium">
              Docente
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-show="editions"
              @click="teacherLeave()"
              color="warning"
              dark
              elevation="0"
              class="mr-2"
              :disabled="edit_disabled"
            >
              <div class="text-subtitle-2 mx-2" style="text-transform: none">
                Baja
              </div>
            </v-btn>
            <v-btn
              @click="save()"
              color="primary"
              dark
              elevation="0"
              class="mr-2"
            >
              <div class="text-subtitle-2 mx-2" style="text-transform: none">
                Guardar
              </div>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row justify="center" class="my-8">
              <v-col cols="12" md="9" lg="8" xl="7">
                <v-expand-transition>
                  <div v-show="error">
                    <v-alert dense text type="error">
                      <div class="text-subtitle-2">{{ errorInfo }}</div>
                    </v-alert>
                  </div>
                </v-expand-transition>
                <v-row>
                  <v-col cols="12" md="4" lg="3">
                    <div class="text-center">
                      <v-avatar size="150">
                        <v-img
                          lazy-src="../../../../assets/images/default.png"
                          :src="profile_url"
                        ></v-img>
                      </v-avatar>
                    </div>
                    <div class="text-center">
                      <v-btn
                        @click="custom = !custom"
                        color="primary"
                        outlined
                        elevation="0"
                        class="mt-8 mb-4"
                        :disabled="insert"
                      >
                        <div
                          class="text-subtitle-2"
                          style="text-transform: none"
                        >
                          Cambiar imagen
                        </div>
                        <v-icon class="ml-4">
                          {{ custom ? "mdi-chevron-up" : "mdi-chevron-down" }}
                        </v-icon>
                      </v-btn>
                    </div>
                    <v-expand-transition>
                      <div v-show="custom">
                        <v-file-input
                          v-model="image"
                          label="Seleccionar nueva"
                          accept="image/png, image/jpeg"
                          hide-details
                        ></v-file-input>
                        <v-btn
                          @click="uploadImage()"
                          color="primary"
                          dark
                          class="my-4"
                        >
                          <div
                            class="text-subtitle-2"
                            style="text-transform: none"
                          >
                            Guardar nueva
                          </div>
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-col>
                  <v-col cols="12" md="8" lg="9">
                    <div class="text-h5 font-weight-bold text--primary">
                      Cuenta en CEi
                    </div>
                    <div class="text-subtitle-2 mt-2">
                      De tipo {{ account_type }}
                    </div>
                    <v-text-field
                      v-model="teacher.name"
                      hide-details
                      filled
                      dense
                      label="Nombre completo"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher.password"
                      :type="show ? 'text' : 'password'"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show = !show"
                      :disabled="!insert"
                      hide-details
                      filled
                      dense
                      label="Contraseña"
                    ></v-text-field>
                    <v-card
                      style="border-radius: 10px"
                      :style="{
                        background: $vuetify.theme.themes[theme].background,
                      }"
                      class="mt-4"
                      :disabled="edit_disabled"
                    >
                      <v-list-item @click="pass_change = !pass_change" link>
                        <v-list-item-avatar>
                          <v-icon>mdi-form-textbox-password</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title class="text-subtitle-2">
                          Cambiar contraseña
                        </v-list-item-title>
                        <v-list-item-action>
                          <v-icon>
                            {{
                              pass_change
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            }}
                          </v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-expand-transition>
                          <div class="changer" v-show="pass_change">
                            <v-text-field
                              v-model="new_password"
                              :type="show ? 'text' : 'password'"
                              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              @click:append="show = !show"
                              label="Nueva contraseña"
                              filled
                              dense
                              hide-details
                              class="my-4"
                            ></v-text-field>
                            <v-expand-transition>
                              <div v-show="error">
                                <v-alert dense text type="error">
                                  <div class="text-subtitle-2">
                                    {{ errorInfo }}
                                  </div>
                                </v-alert>
                              </div>
                            </v-expand-transition>
                            <v-btn
                              @click="change_pass()"
                              color="primary"
                              dark
                              elevation="0"
                              class="mt-2"
                            >
                              <div
                                class="text-subtitle-2 mx-2"
                                style="text-transform: none"
                              >
                                Cambiar contraseña
                              </div>
                            </v-btn>
                          </div>
                        </v-expand-transition>
                      </v-card-text>
                    </v-card>
                    <v-card
                      v-show="show_academy"
                      style="border-radius: 10px"
                      :style="{
                        background: $vuetify.theme.themes[theme].images,
                      }"
                      elevation="8"
                      class="my-6"
                      :disabled="edit_disabled"
                    >
                      <v-list-item @click="show_lessons = !show_lessons" link>
                        <v-list-item-avatar>
                          <v-icon color="primary">
                            mdi-calendar-clock-outline
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title class="text-subtitle-2">
                          Horario escolar
                        </v-list-item-title>
                        <v-list-item-action>
                          <v-icon>
                            {{
                              show_lessons
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            }}
                          </v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-expand-transition>
                        <div class="changer" v-show="show_lessons">
                          <v-row no-gutters>
                            <v-col
                              cols="6"
                              md="4"
                              xl="3"
                              v-for="lesson in teacher_lessons"
                              :key="lesson.id"
                            >
                              <v-card
                                outlined
                                style="border-radius: 10px"
                                :style="{
                                  background:
                                    $vuetify.theme.themes[theme].images,
                                }"
                              >
                                <div class="text-center">
                                  <v-avatar size="120" class="my-2">
                                    <v-img
                                      lazy-src="../../../../assets/images/default.png"
                                      :src="
                                        require('../../../../assets/subjects/' +
                                          lesson.icon +
                                          '.png')
                                      "
                                    ></v-img>
                                  </v-avatar>
                                </div>
                                <v-card-text class="text-center">
                                  <div class="text-h5">
                                    {{ lesson.semester }}° {{ lesson.groups }}
                                  </div>
                                  <div
                                    class="
                                      text-subtitle-2
                                      text--primary
                                      text-truncate
                                    "
                                  >
                                    {{ lesson.name }}
                                  </div>
                                </v-card-text>
                                <v-expand-transition>
                                  <div class="weekly" v-show="week">
                                    <v-list-item>
                                      <v-list-item-avatar>
                                        <v-icon>mdi-clock-outline</v-icon>
                                      </v-list-item-avatar>
                                      <v-list-item-title
                                        class="text-subtitle-2"
                                      >
                                        lunes:
                                        <span
                                          v-show="lesson.monday"
                                          class="font-weight-bold"
                                        >
                                          {{ lesson.monday_start }} -
                                          {{ lesson.monday_end }}
                                        </span>
                                      </v-list-item-title>
                                    </v-list-item>
                                  </div>
                                </v-expand-transition>
                                <v-expand-transition>
                                  <div class="mixed" v-show="!week">
                                    <v-divider></v-divider>
                                    <v-list-item>
                                      <v-list-item-avatar>
                                        <v-icon>mdi-clock-outline</v-icon>
                                      </v-list-item-avatar>
                                      <v-list-item-content>
                                        <div class="text-subtitle-2">
                                          Sábado:
                                          <span
                                            v-show="lesson.saturday"
                                            class="font-weight-bold"
                                          >
                                            {{ lesson.saturday_start }} -
                                            {{ lesson.saturday_end }}
                                          </span>
                                        </div>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </div>
                                </v-expand-transition>
                              </v-card>
                            </v-col>
                          </v-row>
                        </div>
                      </v-expand-transition>
                    </v-card>
                    <v-checkbox
                      v-show="show_academy"
                      v-model="teacher_data.academy"
                      label="Presidente(a) de academia"
                      hide-details
                    ></v-checkbox>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Información personal
                    </div>
                    <v-text-field
                      v-model="teacher_data.birth_date"
                      hide-details
                      filled
                      dense
                      label="Fecha de nacimiento"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.birth_place"
                      hide-details
                      filled
                      dense
                      label="Lugar de nacimiento"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.social_security"
                      hide-details
                      filled
                      dense
                      label="Número de seguridad social"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.personal_number"
                      hide-details
                      filled
                      dense
                      label="Número de personal"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.designation"
                      hide-details
                      filled
                      dense
                      label="Nombramiento"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.squares_key"
                      hide-details
                      filled
                      dense
                      label="Clave de plazas"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.member_since"
                      hide-details
                      filled
                      dense
                      label="Miembro desde"
                    ></v-text-field>
                    <div class="text-subtitle-2 mt-8 mb-4">Escolaridad</div>
                    <v-text-field
                      v-model="teacher_data.degree"
                      hide-details
                      filled
                      dense
                      label="Licenciatura"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.degree_number"
                      hide-details
                      filled
                      dense
                      label="Cédula de licenciatura"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.master"
                      hide-details
                      filled
                      dense
                      label="Maestría"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.master_number"
                      hide-details
                      filled
                      dense
                      label="Cédula de la maestría"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.doctorate"
                      hide-details
                      filled
                      dense
                      label="Doctorado"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.doctorate_number"
                      hide-details
                      filled
                      dense
                      label="Cédula del doctorado"
                      class="mt-4"
                    ></v-text-field>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Domicilio del docente
                    </div>
                    <v-text-field
                      v-model="teacher_data.street"
                      hide-details
                      filled
                      dense
                      label="Calle"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.number"
                      hide-details
                      filled
                      dense
                      label="Número"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.interior"
                      hide-details
                      filled
                      dense
                      label="Interior"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.postal_code"
                      hide-details
                      filled
                      dense
                      label="Código postal"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.colony"
                      hide-details
                      filled
                      dense
                      label="Colonia"
                    ></v-text-field>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Medios de contacto
                    </div>
                    <v-text-field
                      v-model="teacher_data.phone"
                      hide-details
                      filled
                      dense
                      label="Teléfono"
                    ></v-text-field>
                    <v-row class="mt-4">
                      <v-col cols="6">
                        <v-text-field
                          v-model="is_mail_name"
                          hide-details
                          filled
                          label="Correo institucional"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="is_mail_domain"
                          :items="mails"
                          hide-details
                          outlined
                          single-line
                          prefix="@"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="ps_mail_name"
                          hide-details
                          filled
                          label="Correo personal"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="ps_mail_domain"
                          :items="mails"
                          hide-details
                          outlined
                          single-line
                          prefix="@"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">{{ responseMessage }}</div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import auth from "../../../../plugins/auth";
import settings from "../../../../plugins/settings";
import periods from "../../../../plugins/periods";
import lessons from "../../../../plugins/lessons";
import teachers from "../../../../plugins/teachers";
import teachers_data from "../../../../plugins/teachers_data";
import files from "../../../../plugins/files";

// resources
import endpoint from "../../../../plugins/endpoint";
import profile_img from "../../../../assets/images/profile.png";
import functions from "../../../../plugins/functions";

export default {
  name: "Teachers",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    period_model: {},

    week: false,

    teachers: [],
    teacher: {},
    is_mail_name: "",
    is_mail_domain: "msev.gob.mx",
    ps_mail_name: "",
    ps_mail_domain: "bac.edu.mx",
    teacher_data: {},

    // Correos
    mails: [
      "bac.edu.mx",
      "msev.gob.mx",
      "hotmail.com",
      "hotmail.es",
      "hotmail.com.es",
      "outlook.com",
      "outlook.es",
      "outlook.com.es",
      "gmail.com",
      "yahoo.com",
      "yahoo.es",
      "yahoo.com.es",
    ],

    // Cambiar contraseña
    new_password: "",
    pass_change: false,
    show: false,

    // Horario individual
    show_lessons: false,
    teacher_lessons: [],

    type: 0,
    types: [
      { id: 1, name: "Dirección escolar" },
      { id: 2, name: "Supervisión académica" },
      { id: 3, name: "Docente" },
    ],
    header: "(Seleccionar tipo)",
    account_type: "",
    show_academy: false,

    action: false,
    insert: true,
    editions: false,
    edit_disabled: true,

    back_url: "",
    profile_url: profile_img,
    image: [],

    loading: false,
    compiler: false,
    locked: true,
    custom: false,

    responseView: false,
    responseMessage: "",

    error: false,
    errorInfo: "",
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;
        this.back_url = endpoint.BASE_PATH;

        await auth.permission().then((response) => {
          if (response.data.directive == "admin") this.getSettings();
          else this.$router.push("/");
        });
      }
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;
        // -- Documentación del modo:
        // 1 = Escolarizado (lunes a viernes)
        // 2 - Mixto (todos los dias)
        // 3 - Sabatino (solo sabado)
        if (response.data.mode == 1) this.week = true;
        else this.week = false;
        this.mode = response.data.mode;
        this.editions = response.data.editions;

        this.getPeriod();
      });
    },

    async getPeriod() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.loading = false;
        this.compiler = true;
      });
    },

    // invoked
    async getTeachers() {
      this.loading = true;
      await teachers.get().then((response) => {
        this.teachers = Object.values(response.data).filter(
          (type) => type.type === this.type
        );

        // Documentación del tipo de cuenta: -- > !
        if (this.type == 1) {
          this.account_type = "Directores";
          this.teachers = Object.values(this.teachers).filter(
            (username) => username.username !== "NO ASIGNADO"
          );
        }
        if (this.type == 2) this.account_type = "Supervisores";
        if (this.type == 3) {
          this.account_type = "Docentes";
          this.show_academy = true;
        } else this.show_academy = false;

        this.header = "(" + this.teachers.length + ")";
        this.loading = false;
        this.locked = false;
      });
    },

    async openTeacher(edit, id) {
      if (!edit) {
        this.edit_disabled = true;
        this.teacher = {};
        this.teacher_data = {};
        this.teacher_data.academy = 0;

        this.custom = false;
        this.profile_url = profile_img;
        this.insert = true;
        this.action = true;
      } else {
        this.loading = true;
        await teachers.getOne(id).then((response) => {
          this.teacher = response.data;
          this.profile_url = this.back_url + response.data.profile_image;

          if (this.teacher.mail != "" && this.teacher.mail != null) {
            var is_mail = this.teacher.mail.toLowerCase().split("@");
            this.is_mail_name = is_mail[0];
            this.is_mail_domain = is_mail[1];
          } else {
            this.is_mail_name = "";
            this.is_mail_domain = "";
          }

          this.getData(response.data.id);

          this.edit_disabled = false;
          this.insert = false;
          this.action = true;
          this.loading = false;
        });
      }
    },

    async getData(teacher) {
      this.loading = true;
      await teachers_data.getOne(teacher).then(async (response) => {
        this.teacher_data = response.data;

        if (this.teacher_data.mail != "" && this.teacher_data.mail != null) {
          var ps_mail = this.teacher_data.mail.toLowerCase().split("@");
          this.ps_mail_name = ps_mail[0];
          this.ps_mail_domain = ps_mail[1];
        } else {
          this.ps_mail_name = "";
          this.ps_mail_domain = "";
        }

        await lessons.listByTeacher(this.period, teacher).then((response) => {
          this.teacher_lessons = response.data;
          this.loading = false;
        });
      });
    },

    async save() {
      if (this.is_mail_name == "" || this.is_mail_name == null)
        this.is_mail_name = "example";
      if (this.ps_mail_name == "" || this.ps_mail_name == null)
        this.ps_mail_name = "example";
      this.teacher.username = this.teacher.name;
      this.teacher.mail = this.is_mail_name + "@" + this.is_mail_domain;
      if (
        this.teacher.name != null &&
        this.teacher.username != null &&
        this.teacher.password != null
      ) {
        this.uppercaseModel();
        this.loading = true;
        this.teacher.type = this.type;
        if (this.insert) {
          this.teacher.profile_image = "uploads/teachers/default.png";

          await teachers.insert(this.teacher).then((response) => {
            this.responseMessage = response.data.message;

            this.getTeacher();
          });
        } else {
          await teachers
            .update(this.teacher.id, this.teacher)
            .then((response) => {
              this.responseMessage = response.data.message;
              console.log(response.data.message);

              this.saveData(false);
            });
        }
      } else
        this.showError("El nombre y las credenciales de acceso son necesarios");
    },

    async change_pass() {
      if (this.new_password != "" && this.new_password != null) {
        this.loading = true;
        await teachers
          .updatePassword(this.teacher.id, this.new_password)
          .then((response) => {
            console.log(response.data);
            this.responseMessage = response.data.message;

            this.refresh();
            this.loading = false;

            this.responseView = true;
          });
      } else this.showError("Ingresar una contraseña nueva");
    },

    async getTeacher() {
      await teachers.getByName(this.teacher.name).then((response) => {
        this.teacher_data.teacher = response.data.id;

        this.saveData(true);
      });
    },

    async saveData(insert) {
      this.teacher_data.mail = this.ps_mail_name + "@" + this.ps_mail_domain;
      if (insert)
        await teachers_data.insert(this.teacher_data).then((response) => {
          this.responseMessage = response.data.message;

          this.refresh();
          this.loading = false;
        });
      else
        await teachers_data
          .update(this.teacher_data.id, this.teacher_data)
          .then((response) => {
            this.responseMessage = response.data.message;
            console.log(response.data);

            this.refresh();
            this.loading = false;
          });
    },

    refresh() {
      this.getTeachers();
      this.action = false;
      this.responseView = true;
      this.loading = false;
    },

    async uploadImage() {
      this.loading = true;

      const formData = new FormData();
      formData.append("file", this.image);

      await files
        .teachersProfile(formData, this.teacher.id)
        .then((response) => {
          this.teacher.profile_image = response.data.path;
          this.save();

          this.loading = false;
          location.reload();
        });
    },

    showError(message) {
      this.errorInfo = message;
      this.error = true;
      setTimeout(() => (this.error = false), 3000);
    },

    async teacherLeave() {
      this.loading = true;
      // Reemplazar al maestro por el NO ASIGNADO en clases
      await lessons.setTeacher(this.teacher.id).then(async (response) => {
        console.log(response.data.message);

        // Eliminar los datos del maestro
        await teachers_data.delete(this.teacher.id).then(async (response) => {
          console.log(response.data.message);

          // Eliminar al maestro
          await teachers.delete(this.teacher.id).then((response) => {
            this.refresh();
            this.loading = false;

            this.responseMessage = response.data.message;
            this.responseView = true;
          });
        });
      });
    },

    goBack() {
      this.$router.push("/ad/cl");
    },

    // uppercase value
    uppercaseModel() {
      this.teacher.name = functions.normalizeInput(
        this.teacher.name.trim().toUpperCase()
      );
      this.teacher.username = this.teacher.name;
    },
  },
};
</script>