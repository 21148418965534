<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="11" lg="10" xl="9">
          <a @click="goBack()">
            <div class="text-subtitle-2">
              <v-icon class="mr-2" color="primary">mdi-chevron-left</v-icon>
              Control de asignatura
            </div>
          </a>
          <v-row class="my-4">
            <v-slide-y-transition>
              <v-col cols="12" sm="4" lg="3" v-show="compiler">
                <v-card color="primary" style="border-radius: 10px">
                  <v-img
                    lazy-src="../../../../assets/images/default.png"
                    src="../../../../assets/images/assists.png"
                    height="300"
                    contain
                  ></v-img>
                </v-card>
              </v-col>
            </v-slide-y-transition>
            <v-fade-transition>
              <v-col cols="12" sm="8" lg="9" v-show="compiler">
                <div class="text-h5 font-weight-bold">
                  Asistencia ({{ students_list.length }})
                </div>
                <div class="text-subtitle-2 text--secondary mt-2">
                  Contabilizada dentro del {{ assist.partial }}° parcial
                </div>
                <v-row>
                  <v-col cols="12" md="8" lg="6">
                    <v-text-field
                      v-model="assist.date"
                      type="date"
                      filled
                      dense
                      hide-details
                      label="Fecha del pase"
                      :disabled="!edit"
                      class="my-4"
                    ></v-text-field>
                    <v-checkbox
                      v-model="edit"
                      label="Cambiar fecha del pase"
                      hide-details
                      class="mb-4"
                    ></v-checkbox>
                    <v-btn
                      @click="updateAssist()"
                      color="primary"
                      dark
                      elevation="0"
                      :disabled="!edit"
                      class="mt-4"
                    >
                      <div
                        class="text-subtitle-2 mx-2"
                        style="text-transform: none"
                      >
                        Actualizar registro
                      </div>
                    </v-btn>
                    <v-alert
                      type="info"
                      dismissible
                      class="d-flex d-sm-none mt-4"
                    >
                      Recomendamos rotar el dispositivo horizontalmente
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>
            </v-fade-transition>
            <v-slide-y-reverse-transition>
              <v-col cols="12" v-show="compiler">
                <v-card
                  style="border-radius: 10px"
                  :style="{
                    background: $vuetify.theme.themes[theme].images,
                  }"
                  class="mb-6"
                >
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img
                        lazy-src="../../../../assets/images/default.png"
                        :src="
                          require('../../../../assets/subjects/' +
                            subject.icon +
                            '.png')
                        "
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title class="text-subtitle-2">
                      {{ subject.name }}, {{ lesson.semester }}
                      {{ lesson.groups }} el {{ assist_date }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item @click="deleted()" link>
                            <v-list-item-icon>
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Eliminar lista
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    link
                    v-for="student in students_list"
                    :key="student.id"
                  >
                    <v-list-item-avatar size="30">
                      <v-img
                        lazy-src="../../../../assets/images/default.png"
                        :src="back_url + student.photo"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title class="text-subtitle-2">
                      {{ student.number }}.
                      {{ student.paternal_surname }}
                      {{ student.maternal_surname }} {{ student.names }}
                      <span class="font-italic text--disabled">
                        {{ student.value ? "" : "(Falta)" }}
                      </span>
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-checkbox
                        @change="
                          setValue(student.id, student.value, student.student)
                        "
                        v-model="student.value"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-slide-y-reverse-transition>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="updater" width="80" persistent>
        <v-card style="border-radius: 10px" loading="true">
          <div class="text-center my-6">
            <v-progress-circular
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">
          {{ responseMessage }}
        </div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import functions from "../../../../plugins/functions";
import auth from "../../../../plugins/auth";
import settings from "../../../../plugins/settings";
import periods from "../../../../plugins/periods";
import lessons from "../../../../plugins/lessons";
import curriculum from "../../../../plugins/curriculum";
import students_active from "../../../../plugins/students_active";
import partial from "../../../../plugins/partial";
import assists from "../../../../plugins/assists";
import assists_list from "../../../../plugins/assists_list";

// resources
import endpoint from "../../../../plugins/endpoint";
import evidences_list from "../../../../plugins/evidences_list";

export default {
  name: "Assist",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    period_model: {},

    assists: [],
    assist: {},
    new_assist: {},
    assist_date: "",
    lesson: {},
    subject: {},

    students: [],
    partial: {},
    students_list: [],
    assist_list: {},

    edit: false,

    partials: [
      { id: 1, name: "Primer parcial" },
      { id: 2, name: "Segundo parcial" },
      { id: 3, name: "Tercer parcial" },
    ],
    back_url: "",

    loading: false,
    updater: false,
    compiler: false,

    responseView: false,
    responseMessage: "",

    error: false,
    errorInfo: "",
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;
        this.back_url = endpoint.BASE_PATH;
        this.subject.icon = "Ninguna";

        await auth.permission().then((response) => {
          if (response.data.directive == "teacher") this.getAssist();
          else this.$router.push("/");
        });
      }
    },

    async getAssist() {
      await assists.getOne(this.$route.params.assist).then((response) => {
        this.assist = response.data;
        this.assist.date = functions.inputDate(this.assist.date);
        this.assist_date = this.assist.date;

        if (response.data.status == "error") this.goBack();
        else this.getLesson();
      });
    },

    async getLesson() {
      await lessons.getOne(this.$route.params.id).then((response) => {
        this.lesson = response.data;

        if (response.data.status == "error") this.goBack();
        else this.directive();
      });
    },

    async directive() {
      await auth.identity().then((response) => {
        if (response.data.directive != this.lesson.teacher) this.goBack();
        else this.getSubject();
      });
    },

    async getSubject() {
      await curriculum.getOne(this.lesson.subject).then((response) => {
        this.subject = response.data;

        this.getSettings();
      });
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;

        this.getPeriod();
      });
    },

    async getPeriod() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.getStudents();
      });
    },

    async getStudents() {
      await students_active
        .getLesson(
          this.subject.id,
          this.lesson.semester,
          this.lesson.groups,
          this.period
        )
        .then((response) => {
          this.students = response.data;

          this.updater = true;
          this.testPartial();
        });
    },

    async testPartial() {
      this.buildPartial();

      Promise.all(
        this.students.map(async (std) => {
          await partial
            .getBy(
              this.assist.partial,
              this.lesson.id,
              std.student,
              this.period
            )
            .then(async (response) => {
              if (response.data.status == "error") {
                this.partial.student = std.student;

                await partial.insert(this.partial).then((response) => {
                  this.responseMessage = response.data.message;
                });
              }
            });
        })
      ).then(() => {
        this.testStudents();
      });
    },

    async testStudents() {
      this.buildAssistList();

      Promise.all(
        this.students.map(async (student) => {
          await assists_list
            .getBy(this.assist.id, student.student)
            .then(async (response) => {
              this.new_assist.student = student.student;

              if (response.data.status == "error")
                await assists_list.insert(this.new_assist).then((response) => {
                  this.responseMessage = response.data.message;
                });
            });
        })
      ).then(() => {
        this.getList();
      });
    },

    async getList() {
      await assists_list
        .get(
          this.subject.id,
          this.lesson.semester,
          this.lesson.groups,
          this.period,
          this.assist.id
        )
        .then((response) => {
          this.students_list = response.data;

          this.getAssists();
        });
    },

    async getAssists() {
      await assists
        .get(this.period, this.assist.partial, this.lesson.id)
        .then((response) => {
          this.assists = response.data;

          this.updater = false;
          this.loading = false;
          this.compiler = true;
        });
    },

    // invoked
    async updateAssist() {
      this.loading = true;
      await assists.update(this.assist.id, this.assist).then((response) => {
        this.responseMessage = response.data.message;

        this.assist_list = {};
        this.assist_list.date = this.assist.date;
        this.updateList(this.assist.id);
      });
    },

    async setValue(id, value, student) {
      this.updater = true;

      this.assist_list.value = value;
      await assists_list.update(id, this.assist_list).then(async (response) => {
        this.responseMessage = response.data.message;

        await assists_list
          .getTotal(this.lesson.id, student, this.assist.partial, this.period)
          .then(async (response) => {
            var total = response.data.total;
            var fouls = parseFloat(this.assists.length) - parseFloat(total);

            this.partial = {};
            this.partial.fouls = fouls;
            await partial
              .updateBy(
                this.assist.partial,
                this.lesson.id,
                student,
                this.period,
                this.partial
              )
              .then((response) => {
                this.responseMessage = response.data.message;
                this.updater = false;
                this.responseView = true;
              });
          });
      });
    },

    async deleted() {
      await assists_list.delete(this.assist.id).then(async (response) => {
        console.log(response.data.message);

        await assists.delete(this.assist.id).then(async (response) => {
          console.log(response.data.message);

          Promise.all(
            this.students.map(async (student) => {
              await assists_list
                .getTotal(
                  this.lesson.id,
                  student.student,
                  this.assist.partial,
                  this.period
                )
                .then(async (response) => {
                  var total = response.data.total;
                  var fouls =
                    parseFloat(this.assists.length - 1) - parseFloat(total);

                  this.partial = {};
                  this.partial.fouls = fouls;

                  await partial
                    .updateBy(
                      this.assist.partial,
                      this.lesson.id,
                      student.student,
                      this.period,
                      this.partial
                    )
                    .then((response) => {
                      console.log(response.data.message);
                    });
                });
            })
          ).then(() => {
            this.goBack();
          });
        });
      });
    },

    goBack() {
      this.$router.push("/op/ls/" + this.lesson.id);
    },

    // support
    async updateList(assist) {
      await assists_list
        .assistUpdate(assist, this.assist_list)
        .then((response) => {
          this.responseMessage = response.data.message;

          this.responseView = true;
          this.edit = false;
          this.loading = false;
        });
    },
    buildPartial() {
      this.partial.partial = this.assist.partial;
      this.partial.lesson = this.lesson.id;
      this.partial.subject = this.subject.id;
      this.partial.fouls = 0;
      this.partial.evidences = "0";
      this.partial.evidences_value = "0";
      this.partial.exam = "0";
      this.partial.exam_value = "0";
      this.partial.rating_number = "";
      this.partial.rating_letter = "";
      this.partial.period = this.period;
    },
    buildAssistList() {
      this.new_assist.assist = this.assist.id;
      this.new_assist.lesson = this.assist.lesson;
      this.new_assist.value = false;
      this.new_assist.partial = this.assist.partial;
      this.new_assist.period = this.assist.period;
      this.new_assist.date = this.assist.date;
    },
  },
};
</script>