import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "partial/";

export default {
    get(subject, semester, group, period, partial) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + "list/" + subject + "/" + semester + "/" + group + "/" + period + "/" + partial, { headers: { token } });
    },

    getRisk(subject, semester, group, period, partial) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + "risk/list/" + subject + "/" + semester + "/" + group + "/" + period + "/" + partial, { headers: { token } });
    },

    getCardex(partial, student, period) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + partial + "/" + student + "/" + period, { headers: { token } });
    },

    getOne(id) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + id, { headers: { token } });
    },

    getBy(partial, lesson, student, period) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + "select/" + partial + "/" + lesson + "/" + student + "/" + period, { headers: { token } });
    },

    insert(control) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, control, { headers: { token } });
    },

    update(id, control) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, control, { headers: { token } });
    },

    updateBy(partial, lesson, student, period, control) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + partial + "/" + lesson + "/" + student + "/" + period, control, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },
};