import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "files/upload/";

export default {
    upload(formData, name) {
        const token = auth.getSession();
        return axios.post(ENDPOINT + name, formData, {
            headers: {
                'token': token,
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    teachersProfile(formData, id) {
        const token = auth.getSession();
        return axios.post(ENDPOINT + "teacher/" + id, formData, {
            headers: {
                'token': token,
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    studentsPhoto(formData, id) {
        const token = auth.getSession();
        return axios.post(ENDPOINT + "contents/student/" + id, formData, {
            headers: {
                'token': token,
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    delete(type) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + type, { headers: { token } });
    },
};