import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "groups/";

export default {
    get(semester, period) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + period + "/" + semester, { headers: { token } });
    },

    getOne(semester, groups, period) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + semester + "/" + groups + "/" + period, { headers: { token } });
    },

    insert(group) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, group, { headers: { token } });
    },

    update(id, group) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, group, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },
};