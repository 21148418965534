<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-divider></v-divider>
    <v-toolbar
      flat
      :style="{
        background: $vuetify.theme.themes[theme].background,
      }"
    >
      <v-app-bar-nav-icon class="ml-1">
        <v-avatar tile size="30">
          <v-img
            lazy-src="../../../../assets/images/default.png"
            :src="profile"
            contain
          ></v-img>
        </v-avatar>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="text-subtitle-1 font-weight-medium">
        {{ school }}
      </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container fluid>
      <v-slide-y-reverse-transition>
        <v-row justify="center" class="my-8" v-show="compiler">
          <v-col cols="12" md="9" lg="8" xl="7">
            <v-row>
              <v-col cols="12" md="4" lg="3">
                <div class="text-center">
                  <v-avatar size="150">
                    <v-img
                      lazy-src="../../../../assets/images/default.png"
                      :src="logo"
                    ></v-img>
                  </v-avatar>
                  <div class="text-subtitle-2 mt-2">
                    {{ clave }}
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="8" lg="9">
                <div class="text-h5 font-weight-bold text--primary">
                  Preinscripción
                </div>
                <div class="text-subtitle-2 mt-2">
                  Completa cuidadosamente con tus datos los siguientes campos
                </div>
                <v-text-field
                  v-model="student.paternal_surname"
                  hide-details
                  filled
                  dense
                  label="Apellido paterno *"
                  class="my-4"
                ></v-text-field>
                <v-text-field
                  v-model="student.maternal_surname"
                  hide-details
                  filled
                  dense
                  label="Apellido materno *"
                ></v-text-field>
                <v-text-field
                  v-model="student.names"
                  hide-details
                  filled
                  dense
                  label="Nombre(s) *"
                  class="my-4"
                ></v-text-field>
                <v-select
                  :items="genders"
                  item-value="id"
                  item-text="name"
                  v-model="student.gender"
                  hide-details
                  filled
                  dense
                  label="Genero"
                ></v-select>
                <v-text-field
                  v-model="student.phone"
                  onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                  maxlength="10"
                  hide-details
                  filled
                  dense
                  label="Teléfono"
                  class="my-4"
                ></v-text-field>
                <div class="text-subtitle-2 mt-8 mb-4">
                  Correo electrónico (medio de contacto)
                </div>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="st_mail_name"
                      hide-details
                      filled
                      label="Correo electrónico *"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="st_mail_domain"
                      :items="mails"
                      hide-details
                      outlined
                      single-line
                      prefix="@"
                    ></v-select>
                  </v-col>
                </v-row>
                <div class="text-body-2 mt-3 mb-4">
                  Recomendamos utilizar tu correo institucional (autogenerado)
                </div>
                <div class="text-subtitle-2 mt-8 mb-4">
                  Información de tu acta de nacimiento (revisar cuidadosamente)
                </div>
                <v-text-field
                  v-model="student.birth_date"
                  type="date"
                  hide-details
                  filled
                  dense
                  label="Fecha de nacimiento"
                ></v-text-field>
                <v-text-field
                  v-model="student.birth_city"
                  hide-details
                  filled
                  dense
                  label="Localidad de nacimiento"
                  class="my-4"
                ></v-text-field>
                <v-text-field
                  v-model="student.birth_municipality"
                  hide-details
                  filled
                  dense
                  label="Municipio de nacimiento"
                ></v-text-field>
                <v-text-field
                  v-model="student.birth_state"
                  hide-details
                  filled
                  dense
                  label="Entidad de nacimiento"
                  class="my-4"
                ></v-text-field>
                <v-text-field
                  v-model="student.nationality"
                  hide-details
                  filled
                  dense
                  label="Nacionalidad"
                ></v-text-field>
                <v-text-field
                  v-model="student.curp"
                  maxlength="18"
                  hide-details
                  filled
                  dense
                  label="Clave única de registro de población *"
                  class="mt-4"
                ></v-text-field>
                <div class="text-subtitle-2 mt-8 mb-4">Tu domicilio actual</div>
                <v-text-field
                  v-model="student.street"
                  hide-details
                  filled
                  dense
                  label="Calle o avenida"
                ></v-text-field>
                <v-text-field
                  v-model="student.number"
                  onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                  maxlength="10"
                  hide-details
                  filled
                  dense
                  label="Número"
                  class="my-4"
                ></v-text-field>
                <v-text-field
                  v-model="student.postal_code"
                  onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                  maxlength="5"
                  hide-details
                  filled
                  dense
                  label="Código postal"
                ></v-text-field>
                <v-text-field
                  v-model="student.colony"
                  hide-details
                  filled
                  dense
                  label="Colonia"
                  class="my-4"
                ></v-text-field>
                <v-text-field
                  v-model="student.city"
                  hide-details
                  filled
                  dense
                  label="Ciudad o localidad"
                ></v-text-field>
                <v-text-field
                  v-model="student.municipality"
                  hide-details
                  filled
                  dense
                  label="Municipio"
                  class="mt-4"
                ></v-text-field>
                <div class="text-subtitle-2 mt-8 mb-4">
                  Salud y servicios medicos
                </div>
                <v-select
                  v-model="student.blood_type"
                  :items="blood_types"
                  hide-details
                  filled
                  dense
                  label="Tipo sanguíneo"
                ></v-select>
                <v-checkbox
                  v-model="student.imss"
                  hide-details
                  label="IMSS"
                  class="my-4"
                ></v-checkbox>
                <v-checkbox
                  v-model="student.issste"
                  hide-details
                  label="ISSSTE"
                ></v-checkbox>
                <v-checkbox
                  v-model="student.insabi"
                  hide-details
                  label="INSABI"
                  class="my-4"
                ></v-checkbox>
                <v-checkbox
                  v-model="student.other"
                  hide-details
                  label="Otro (o no sé)"
                ></v-checkbox>
                <v-text-field
                  v-model="student.social_security"
                  onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                  maxlength="11"
                  hide-details
                  filled
                  dense
                  label="Número de seguro social"
                  class="my-4"
                ></v-text-field>
                <v-expand-transition>
                  <div v-show="error">
                    <v-alert dense text type="error">
                      <div class="text-subtitle-2">{{ errorInfo }}</div>
                    </v-alert>
                  </div>
                </v-expand-transition>
                <v-alert type="warning" class="mt-10 mb-2">
                  <div class="text-body-2">
                    <span class="font-weight-bold"> ATENCIÓN: </span>
                    Comprueba tus datos con mucho cuidado, no podrás editarlos
                    hasta el día que entregues tus documentos en nuestro plantel
                    escolar, ubicado en
                    <span class="font-weight-medium">{{ address }}</span>
                    <br />
                    <br />
                    <span class="font-weight-bold">
                      NO ABANDONES ESTA PÁGINA HASTA QUE VEAS EL MENSAJE EN
                      VERDE QUE TE LO INDIQUE
                    </span>
                  </div>
                </v-alert>
                <v-btn
                  @click="saveStudent()"
                  color="primary"
                  dark
                  elevation="0"
                  class="my-4"
                  :disabled="post_ins"
                >
                  <div
                    class="text-subtitle-2 mx-2"
                    style="text-transform: none"
                  >
                    Entiendo, envíar y continuar
                  </div>
                </v-btn>
                <v-expand-transition>
                  <div class="post-inscription" v-show="post_ins">
                    <div class="text-h5 font-weight-bold text--primary mt-10">
                      Tutor(es)
                    </div>
                    <div class="text-subtitle-2 mt-2 mb-4">
                      Obligatoriamente, para continuar con tu registro, ingresa
                      los datos de al menos un tutor
                    </div>
                    <v-card
                      style="border-radius: 10px"
                      :style="{
                        background: $vuetify.theme.themes[theme].images,
                      }"
                    >
                      <v-list-item
                        @click="getTutor(tutor.id)"
                        v-for="tutor in tutors"
                        :key="tutor.id"
                        link
                      >
                        <v-list-item-avatar>
                          <v-icon>mdi-account-circle</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <div class="text-subtitle-2">
                            {{ tutor.paternal_surname }}
                            {{ tutor.maternal_surname }} {{ tutor.names }}
                          </div>
                          <div class="text-caption font-weight-medium">
                            Click para editar datos
                          </div>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                    <v-card
                      style="border-radius: 10px"
                      :style="{
                        background: $vuetify.theme.themes[theme].images,
                      }"
                      class="my-5"
                    >
                      <v-list-item @click="expandTutor()" link>
                        <v-list-item-title class="text-subtitle-2">
                          {{
                            insert_tutor ? "Nuevo tutor" : "Datos de mi tutor"
                          }}
                        </v-list-item-title>
                        <v-list-item-action>
                          <v-icon>
                            {{
                              tutor_expand
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            }}
                          </v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-expand-transition>
                        <v-card-text v-show="tutor_expand">
                          <v-text-field
                            v-model="tutor.paternal_surname"
                            hide-details
                            filled
                            dense
                            label="Apellido paterno *"
                            class="mb-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.maternal_surname"
                            hide-details
                            filled
                            dense
                            label="Apellido materno *"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.names"
                            hide-details
                            filled
                            dense
                            label="Nombre(s) *"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.occupation"
                            hide-details
                            filled
                            dense
                            label="Ocupación"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.street"
                            hide-details
                            filled
                            dense
                            label="Calle"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.number"
                            hide-details
                            filled
                            dense
                            label="Número"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.colony"
                            hide-details
                            filled
                            dense
                            label="Colonia"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.postal_code"
                            onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                            maxlength="5"
                            hide-details
                            filled
                            dense
                            label="Código postal"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.municipality"
                            hide-details
                            filled
                            dense
                            label="Municipio"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.city"
                            hide-details
                            filled
                            dense
                            label="Localidad"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.birth_date"
                            type="date"
                            hide-details
                            filled
                            dense
                            label="Fecha de nacimiento"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.curp"
                            maxlength="18"
                            hide-details
                            filled
                            dense
                            label="CURP *"
                          ></v-text-field>
                          <v-select
                            :items="yes_no"
                            v-model="tutor.knows_read"
                            hide-details
                            filled
                            dense
                            label="¿Sabe leer?"
                            class="my-4"
                          ></v-select>
                          <v-text-field
                            v-model="tutor.scholarship"
                            hide-details
                            filled
                            dense
                            label="Escolaridad"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.civil_status"
                            hide-details
                            filled
                            dense
                            label="Estado civil"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.residence_state"
                            hide-details
                            filled
                            dense
                            label="Entidad de residencia"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.phone"
                            onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                            maxlength="10"
                            hide-details
                            filled
                            dense
                            label="Teléfono"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.movil"
                            onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                            maxlength="10"
                            hide-details
                            filled
                            dense
                            label="Celular *"
                          ></v-text-field>
                          <v-row class="mt-4">
                            <v-col cols="6">
                              <v-text-field
                                v-model="tr_mail_name"
                                hide-details
                                filled
                                label="Correo electrónico *"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-select
                                v-model="tr_mail_domain"
                                :items="mails"
                                hide-details
                                outlined
                                single-line
                                prefix="@"
                              ></v-select>
                            </v-col>
                          </v-row>
                          <v-expand-transition>
                            <div v-show="error">
                              <v-alert dense text type="error">
                                <div class="text-subtitle-2">
                                  {{ errorInfo }}
                                </div>
                              </v-alert>
                            </div>
                          </v-expand-transition>
                          <v-btn
                            @click="saveTutor()"
                            color="primary"
                            dark
                            elevation="0"
                            class="mt-4"
                          >
                            <div
                              class="text-subtitle-2 mx-2"
                              style="text-transform: none"
                            >
                              Guardar el tutor
                            </div>
                          </v-btn>
                        </v-card-text>
                      </v-expand-transition>
                    </v-card>
                    <v-expand-transition>
                      <v-alert type="success" class="mt-10" v-show="post_tut">
                        <div class="text-body-2">
                          <span class="font-weight-bold">Hecho</span>
                          Tus datos se han enviado, para continuar con tu
                          inscripción deberás acudir a nuestro plantel educativo
                          {{ school }} ubicado en
                          <span class="font-weight-medium">
                            {{ address }}
                          </span>
                          <br />
                          <br />
                          <span class="font-weight-bold">
                            ya puedes abandonar esta página o agregar otro
                            tutor. ¡Gracias por tu registro!
                          </span>
                        </div>
                      </v-alert>
                    </v-expand-transition>
                    <v-btn x-large block color="primary" dark class="my-10">
                      <div class="text-subtitle-2" style="text-transform: none">
                        <a href="https://bac.edu.mx/" class="white--text">
                          Aceptar
                        </a>
                      </div>
                    </v-btn>
                  </div>
                </v-expand-transition>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-slide-y-reverse-transition>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">{{ responseMessage }}</div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import settings from "../../../../plugins/settings";
import periods from "../../../../plugins/periods";
import students from "../../../../plugins/students";
import tutors from "../../../../plugins/tutors";

// resources
import endpoint from "../../../../plugins/endpoint";
import functions from "../../../../plugins/functions";

export default {
  name: "Preinscription",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    loading: false,
    compiler: false,
    school: "",
    address: "",
    clave: "",
    logo: "",
    profile: "",
    period: "",

    // Mostrar los campos post inscripcion
    post_ins: false,

    // Control de inserción para el tutor
    insert_tutor: true,
    tutor_expand: true,

    // Control de inserción para inscripción
    post_tut: false,

    genders: [
      { id: "F", name: "FEMENINO" },
      { id: "M", name: "MASCULINO" },
    ],

    student: {},
    // student mail
    st_mail_name: "",
    st_mail_domain: "alumno.msev.gob.mx",

    // Tutor del estudiante
    tutor: {},
    // tutor mail
    tr_mail_name: "",
    tr_mail_domain: "hotmail.com",
    tutors: [],

    // Correos
    mails: [
      "alumno.msev.gob.mx",
      "hotmail.com",
      "hotmail.es",
      "hotmail.com.es",
      "outlook.com",
      "outlook.es",
      "outlook.com.es",
      "gmail.com",
      "yahoo.com",
      "yahoo.es",
      "yahoo.com.es",
    ],

    blood_types: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
    yes_no: ["SI", "NO"],

    error: false,
    errorInfo: "",

    responseView: false,
    responseMessage: "",
  }),

  methods: {
    // start chain
    async main() {
      this.getSettings();
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;
        this.school = response.data.school;
        this.address = response.data.address;
        this.clave = response.data.clave;

        this.logo = endpoint.BASE_PATH + response.data.logotype;
        this.profile = endpoint.BASE_PATH + response.data.profile_image;

        this.getPeriod();
      });
    },

    async getPeriod() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.loading = false;
        this.compiler = true;
      });
    },

    // Metodos llamados
    async saveStudent() {
      this.student.mail = this.st_mail_name + "@" + this.st_mail_domain;
      // Validación de campos
      if (
        this.student.paternal_surname != "" &&
        this.student.paternal_surname != null &&
        this.student.maternal_surname != "" &&
        this.student.maternal_surname != null &&
        this.student.names != "" &&
        this.student.names != null &&
        this.student.mail != "" &&
        this.student.mail != null &&
        this.student.curp != "" &&
        this.student.curp != null
      ) {
        // Validación de email (IMPORTANTE)
        if (this.mailValidate(this.student.mail.trim())) {
          // Completar el modelo de datos
          this.uppercaseModel();

          // Proceso de inserción
          this.loading = true;
          await students.insert(this.student).then(async (response) => {
            this.responseMessage = response.data.message;
            // Despues de insertar, lo buscamos para obtener su ID
            await students
              .getByName(
                this.student.paternal_surname,
                this.student.maternal_surname,
                this.student.names
              )
              .then((response) => {
                // Completamos el modelo
                this.student = response.data;

                // Mostramos resultados
                this.responseView = true;
                this.post_ins = true;
                this.loading = false;
              });
          });
        } else this.showError("Correo electrónico no válido");
      } else this.showError("Completar campos obligatorios (*)");
    },

    mailValidate(mail) {
      if (
        /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
          mail
        )
      )
        return true;
      else return false;
    },

    async getTutor(id) {
      await tutors.getOne(id).then((response) => {
        this.tutor = response.data;

        if (this.tutor.mail != null && this.tutor.mail != "") {
          var mail = this.tutor.mail.toLowerCase().split("@");
          this.tr_mail_name = mail[0];
          this.tr_mail_domain = mail[1];
        }

        this.insert_tutor = false;
        this.tutor_expand = [1];
      });
    },

    async saveTutor() {
      this.tutor.mail = this.tr_mail_name + "@" + this.tr_mail_domain;
      if (
        this.tutor.paternal_surname != "" &&
        this.tutor.paternal_surname != null &&
        this.tutor.maternal_surname != "" &&
        this.tutor.maternal_surname != null &&
        this.tutor.names != "" &&
        this.tutor.names != null &&
        this.tutor.curp != "" &&
        this.tutor.curp != null &&
        this.tutor.phone != "" &&
        this.tutor.phone != null &&
        this.tutor.mail != "" &&
        this.tutor.mail != null
      ) {
        if (this.mailValidate(this.tutor.mail.trim())) {
          this.loading = true;
          this.uppercaseModelTutor();
          if (this.insert_tutor)
            await tutors.insert(this.tutor).then((response) => {
              this.responseMessage = response.data.message;
              this.responseView = true;

              this.getTutors();
              this.tutor_expand = false;
              this.post_tut = true;
              this.loading = false;
            });
          else
            await tutors.update(this.tutor.id, this.tutor).then((response) => {
              this.responseMessage = response.data.message;
              this.responseView = true;

              this.getTutors();
              this.tutor_expand = false;
              this.post_tut = true;
              this.loading = false;
            });
        } else this.showError("Correo electrónico no válido (*)");
      } else this.showError("Completar campos obligatorios (*)");
    },

    async getTutors() {
      await tutors.get(this.student.id).then((response) => {
        this.tutors = response.data;

        this.tutor = {};
      });
    },

    showError(message) {
      this.error = true;
      this.errorInfo = message;
      setTimeout(() => (this.error = false), 3000);
    },

    expandTutor() {
      this.tutor_expand = !this.tutor_expand;
      this.tutor = {};
    },

    back() {
      this.$router.push("/ad/ins");
    },

    // Validar y dar formato al alumno
    uppercaseModel() {
      // Datos en mayusculas y sin acentos
      this.student.paternal_surname = functions.normalizeInput(
        this.student.paternal_surname.trim().toUpperCase()
      );
      this.student.maternal_surname = functions.normalizeInput(
        this.student.maternal_surname.trim().toUpperCase()
      );
      this.student.names = functions.normalizeInput(
        this.student.names.trim().toUpperCase()
      );
      this.student.mail = this.student.mail.trim().toLowerCase();
      this.student.curp = this.student.curp.trim().toUpperCase();
      this.student.street = this.student.street.trim().toUpperCase();
      this.student.colony = this.student.colony.trim().toUpperCase();
      this.student.city = this.student.city.trim().toUpperCase();
      this.student.municipality = this.student.municipality
        .trim()
        .toUpperCase();
      this.student.birth_city = this.student.birth_city.trim().toUpperCase();
      this.student.birth_municipality = this.student.birth_municipality
        .trim()
        .toUpperCase();
      this.student.birth_state = this.student.birth_state.trim().toUpperCase();
      this.student.nationality = this.student.nationality.trim().toUpperCase();

      // Datos faltantes
      this.student.enrollment = "";
      this.student.status = 0;
      this.student.photo = "uploads/students/default.png";
    },

    // Validar y dar formato al tutor
    uppercaseModelTutor() {
      // Datos en mayusculas
      this.tutor.paternal_surname = this.tutor.paternal_surname
        .trim()
        .toUpperCase();
      this.tutor.maternal_surname = this.tutor.maternal_surname
        .trim()
        .toUpperCase();
      this.tutor.names = this.tutor.names.trim().toUpperCase();
      this.tutor.occupation = this.tutor.occupation.trim().toUpperCase();
      this.tutor.street = this.tutor.street.trim().toUpperCase();
      this.tutor.colony = this.tutor.colony.trim().toUpperCase();
      this.tutor.city = this.tutor.city.trim().toUpperCase();
      this.tutor.municipality = this.tutor.municipality.trim().toUpperCase();
      this.tutor.curp = this.tutor.curp.trim().toUpperCase();
      this.tutor.scholarship = this.tutor.scholarship.trim().toUpperCase();
      this.tutor.civil_status = this.tutor.civil_status.trim().toUpperCase();
      this.tutor.residence_state = this.tutor.residence_state
        .trim()
        .toUpperCase();
      this.tutor.mail = this.tutor.mail.trim().toLowerCase();

      // Datos faltantes
      this.tutor.student = this.student.id;
    },
  },
};
</script>