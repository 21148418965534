<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-divider></v-divider>
    <v-toolbar
      flat
      :style="{
        background: $vuetify.theme.themes[theme].background,
      }"
    >
      <v-app-bar-nav-icon class="ml-1">
        <v-btn @click="back()" icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="text-subtitle-1 font-weight-medium">
        Datos del alumno
      </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container fluid>
      <v-slide-y-reverse-transition>
        <v-row justify="center" class="my-8" v-show="compiler">
          <v-col cols="12" md="9" lg="8" xl="7">
            <v-row>
              <v-col cols="12" md="4" lg="3">
                <div class="text-center">
                  <v-avatar size="150">
                    <v-img
                      lazy-src="../../../../assets/images/default.png"
                      :src="photo_url"
                    ></v-img>
                  </v-avatar>
                </div>
              </v-col>
              <v-col cols="12" md="8" lg="9">
                <div class="text-h5 font-weight-bold text--primary">
                  Aspirante
                </div>

                <v-divider class="mt-4"></v-divider>
                <v-list-item @click="data_expand = !data_expand" link>
                  <v-list-item-avatar>
                    <v-icon>mdi-form-select</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title class="text-subtitle-2">
                    Datos personales del alumno
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-icon>
                      {{ data_expand ? "mdi-chevron-up" : "mdi-chevron-down" }}
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>

                <v-expand-transition>
                  <div class="data" v-show="data_expand">
                    <v-text-field
                      v-model="student.paternal_surname"
                      hide-details
                      filled
                      dense
                      label="Apellido paterno *"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="student.maternal_surname"
                      hide-details
                      filled
                      dense
                      label="Apellido materno *"
                    ></v-text-field>
                    <v-text-field
                      v-model="student.names"
                      hide-details
                      filled
                      dense
                      label="Nombre(s) *"
                      class="my-4"
                    ></v-text-field>
                    <v-select
                      :items="genders"
                      item-value="id"
                      item-text="name"
                      v-model="student.gender"
                      hide-details
                      filled
                      dense
                      label="Genero"
                    ></v-select>
                    <v-text-field
                      v-model="student.phone"
                      onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                      maxlength="10"
                      hide-details
                      filled
                      dense
                      label="Teléfono"
                      class="my-4"
                    ></v-text-field>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Correo electrónico (medio de contacto)
                    </div>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="st_mail_name"
                          hide-details
                          filled
                          label="Correo electrónico *"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="st_mail_domain"
                          :items="mails"
                          hide-details
                          outlined
                          single-line
                          prefix="@"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Información del acta de nacimiento
                    </div>
                    <v-text-field
                      v-model="student.birth_date"
                      type="date"
                      hide-details
                      filled
                      dense
                      label="Fecha de nacimiento"
                    ></v-text-field>
                    <v-text-field
                      v-model="student.birth_city"
                      hide-details
                      filled
                      dense
                      label="Localidad de nacimiento"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="student.birth_municipality"
                      hide-details
                      filled
                      dense
                      label="Municipio de nacimiento"
                    ></v-text-field>
                    <v-text-field
                      v-model="student.birth_state"
                      hide-details
                      filled
                      dense
                      label="Entidad de nacimiento"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="student.nationality"
                      hide-details
                      filled
                      dense
                      label="Nacionalidad"
                    ></v-text-field>
                    <v-text-field
                      v-model="student.curp"
                      maxlength="18"
                      hide-details
                      filled
                      dense
                      label="Clave única de registro de población *"
                      class="mt-4"
                    ></v-text-field>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Domicilio actual
                    </div>
                    <v-text-field
                      v-model="student.street"
                      hide-details
                      filled
                      dense
                      label="Calle o avenida"
                    ></v-text-field>
                    <v-text-field
                      v-model="student.number"
                      onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                      maxlength="10"
                      hide-details
                      filled
                      dense
                      label="Número"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="student.postal_code"
                      onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                      maxlength="5"
                      hide-details
                      filled
                      dense
                      label="Código postal"
                    ></v-text-field>
                    <v-text-field
                      v-model="student.colony"
                      hide-details
                      filled
                      dense
                      label="Colonia"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="student.city"
                      hide-details
                      filled
                      dense
                      label="Ciudad o localidad"
                    ></v-text-field>
                    <v-text-field
                      v-model="student.municipality"
                      hide-details
                      filled
                      dense
                      label="Municipio"
                      class="mt-4"
                    ></v-text-field>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Salud y servicios medicos
                    </div>
                    <v-select
                      v-model="student.blood_type"
                      :items="blood_types"
                      hide-details
                      filled
                      dense
                      label="Tipo sanguíneo"
                    ></v-select>
                    <v-checkbox
                      v-model="student.imss"
                      hide-details
                      label="IMSS"
                      class="my-4"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="student.issste"
                      hide-details
                      label="ISSSTE"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="student.insabi"
                      hide-details
                      label="INSABI"
                      class="my-4"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="student.other"
                      hide-details
                      label="Otro"
                    ></v-checkbox>
                    <v-text-field
                      v-model="student.social_security"
                      onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                      maxlength="11"
                      hide-details
                      filled
                      dense
                      label="Número de seguro social"
                      class="my-4"
                    ></v-text-field>
                    <v-expand-transition>
                      <div v-show="error">
                        <v-alert dense text type="error">
                          <div class="text-subtitle-2">{{ errorInfo }}</div>
                        </v-alert>
                      </div>
                    </v-expand-transition>
                    <v-btn
                      @click="saveStudent()"
                      color="primary"
                      dark
                      elevation="0"
                      class="my-4"
                      :disabled="post_ins"
                    >
                      <div
                        class="text-subtitle-2 mx-2"
                        style="text-transform: none"
                      >
                        Guardar alumno
                      </div>
                    </v-btn>
                  </div>
                </v-expand-transition>
                <v-expand-transition>
                  <div class="post-inscription" v-show="post_ins">
                    <div class="text-h5 font-weight-bold text--primary mt-10">
                      Tutor(es)
                    </div>
                    <div class="text-subtitle-2 mt-2 mb-4">
                      Obligatoriamente se debe registrar al menos 1 tutor por
                      alumno
                    </div>
                    <v-card
                      style="border-radius: 10px"
                      :style="{
                        background: $vuetify.theme.themes[theme].images,
                      }"
                    >
                      <v-list-item
                        @click="getTutor(tutor.id)"
                        v-for="tutor in tutors"
                        :key="tutor.id"
                        link
                      >
                        <v-list-item-avatar>
                          <v-icon>mdi-account-circle</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title class="text-subtitle-2">
                          {{ tutor.paternal_surname }}
                          {{ tutor.maternal_surname }} {{ tutor.names }}
                        </v-list-item-title>
                        <v-list-item-action>
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                    <v-card
                      style="border-radius: 10px"
                      :style="{
                        background: $vuetify.theme.themes[theme].images,
                      }"
                      class="my-5"
                    >
                      <v-list-item @click="expandTutor()" link>
                        <v-list-item-title class="text-subtitle-2">
                          {{ insert_tutor ? "Nuevo tutor" : "Datos del tutor" }}
                        </v-list-item-title>
                        <v-list-item-action>
                          <v-icon>
                            {{
                              tutor_expand
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            }}
                          </v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-expand-transition>
                        <v-card-text v-show="tutor_expand">
                          <v-text-field
                            v-model="tutor.paternal_surname"
                            hide-details
                            filled
                            dense
                            label="Apellido paterno *"
                            class="mb-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.maternal_surname"
                            hide-details
                            filled
                            dense
                            label="Apellido materno *"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.names"
                            hide-details
                            filled
                            dense
                            label="Nombre(s) *"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.occupation"
                            hide-details
                            filled
                            dense
                            label="Ocupación"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.street"
                            hide-details
                            filled
                            dense
                            label="Calle"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.number"
                            hide-details
                            filled
                            dense
                            label="Número"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.colony"
                            hide-details
                            filled
                            dense
                            label="Colonia"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.postal_code"
                            hide-details
                            filled
                            dense
                            label="Código postal"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.municipality"
                            hide-details
                            filled
                            dense
                            label="Municipio"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.city"
                            hide-details
                            filled
                            dense
                            label="Localidad"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.birth_date"
                            type="date"
                            hide-details
                            filled
                            dense
                            label="Fecha de nacimiento"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.curp"
                            maxlength="18"
                            hide-details
                            filled
                            dense
                            label="CURP *"
                          ></v-text-field>
                          <v-select
                            :items="yes_no"
                            v-model="tutor.knows_read"
                            hide-details
                            filled
                            dense
                            label="¿Sabe leer?"
                            class="my-4"
                          ></v-select>
                          <v-text-field
                            v-model="tutor.scholarship"
                            hide-details
                            filled
                            dense
                            label="Escolaridad"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.civil_status"
                            hide-details
                            filled
                            dense
                            label="Estado civil"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.residence_state"
                            hide-details
                            filled
                            dense
                            label="Entidad de residencia"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.phone"
                            onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                            maxlength="10"
                            hide-details
                            filled
                            dense
                            label="Teléfono"
                            class="my-4"
                          ></v-text-field>
                          <v-text-field
                            v-model="tutor.movil"
                            onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                            maxlength="10"
                            hide-details
                            filled
                            dense
                            label="Celular *"
                          ></v-text-field>
                          <v-row class="mt-4">
                            <v-col cols="6">
                              <v-text-field
                                v-model="tr_mail_name"
                                hide-details
                                filled
                                label="Correo electrónico *"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-select
                                v-model="tr_mail_domain"
                                :items="mails"
                                hide-details
                                outlined
                                single-line
                                prefix="@"
                              ></v-select>
                            </v-col>
                          </v-row>
                          <v-expand-transition>
                            <div v-show="error">
                              <v-alert dense text type="error">
                                <div class="text-subtitle-2">
                                  {{ errorInfo }}
                                </div>
                              </v-alert>
                            </div>
                          </v-expand-transition>
                          <v-btn
                            @click="saveTutor()"
                            color="primary"
                            dark
                            elevation="0"
                            class="mt-4"
                          >
                            <div
                              class="text-subtitle-2 mx-2"
                              style="text-transform: none"
                            >
                              {{
                                insert_tutor
                                  ? "Guardar tutor"
                                  : "Actualizar tutor"
                              }}
                            </div>
                          </v-btn>
                        </v-card-text>
                      </v-expand-transition>
                    </v-card>
                  </div>
                </v-expand-transition>
                <v-expand-transition>
                  <div class="inscription_grid" v-show="post_tut">
                    <div class="text-h5 font-weight-bold text--primary mt-10">
                      Inscripción
                    </div>
                    <div class="text-subtitle-2 mt-2 mb-4">
                      Semestre y grupo que cursará el alumno durante el periodo
                      {{ period }}
                    </div>
                    <v-row class="mb-4">
                      <v-col cols="12" md="8">
                        <v-select
                          v-model="student_active.semester"
                          v-on:input="getGroups()"
                          :items="semesters"
                          item-value="id"
                          item-text="name"
                          outlined
                          hide-details
                          label="Semestre *"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-select
                          v-model="student_active.groups"
                          :items="groups"
                          item-value="groups"
                          item-text="groups"
                          no-data-text="Sin grupos"
                          outlined
                          hide-details
                          label="Grupo *"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-expand-transition>
                      <div v-show="error">
                        <v-alert dense text type="error">
                          <div class="text-subtitle-2">{{ errorInfo }}</div>
                        </v-alert>
                      </div>
                    </v-expand-transition>
                    <v-btn
                      @click="saveActive()"
                      color="primary"
                      dark
                      elevation="0"
                      class="mb-4"
                    >
                      <div
                        class="text-subtitle-2 mx-2"
                        style="text-transform: none"
                      >
                        Inscribir alumno
                      </div>
                    </v-btn>
                  </div>
                </v-expand-transition>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-slide-y-reverse-transition>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">{{ responseMessage }}</div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import auth from "../../../../plugins/auth";
import settings from "../../../../plugins/settings";
import periods from "../../../../plugins/periods";
import students from "../../../../plugins/students";
import students_active from "../../../../plugins/students_active";
import tutors from "../../../../plugins/tutors";
import groups from "../../../../plugins/groups";

// resources
import endpoint from "../../../../plugins/endpoint";
import profile_img from "../../../../assets/images/profile.png";
import functions from "../../../../plugins/functions";

export default {
  name: "Inscription",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    loading: false,
    compiler: false,
    locked: true,
    custom: false,

    period: "",

    photo_url: profile_img,
    photo: [],

    insert: false,

    // Expandir datos personales
    data_expand: true,

    // Mostrar los campos post inscripcion
    post_ins: false,

    // Control de inserción para el tutor
    insert_tutor: true,
    tutor_expand: true,

    // Control de inserción para inscripción
    post_tut: false,

    genders: [
      { id: "F", name: "FEMENINO" },
      { id: "M", name: "MASCULINO" },
    ],
    semesters: [
      { id: 1, name: "Primer semestre" },
      { id: 2, name: "Segundo semestre" },
      { id: 3, name: "Tercer semestre" },
      { id: 4, name: "Cuarto semestre" },
      { id: 5, name: "Quinto semestre" },
      { id: 6, name: "Sexto semestre" },
    ],
    groups: [],

    student: {},
    // student mail
    st_mail_name: "",
    st_mail_domain: "alumno.msev.gob.mx",
    student_active: {},

    // Tutor del estudiante
    tutor: {},
    // tutor mail
    tr_mail_name: "",
    tr_mail_domain: "hotmail.com",
    tutors: [],

    // Correos
    mails: [
      "alumno.msev.gob.mx",
      "hotmail.com",
      "hotmail.es",
      "hotmail.com.es",
      "outlook.com",
      "outlook.es",
      "outlook.com.es",
      "gmail.com",
      "yahoo.com",
      "yahoo.es",
      "yahoo.com.es",
    ],

    admin_permission: true,

    blood_types: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
    yes_no: ["SI", "NO"],

    error: false,
    errorInfo: "",

    responseView: false,
    responseMessage: "",
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;
        this.back_url = endpoint.BASE_PATH;

        await auth.permission().then((response) => {
          if (response.data.directive == "admin") this.getSettings();
          else if (response.data.directive == "supervisor") {
            this.admin_permission = false;
            this.getSettings();
          } else this.$router.push("/");
        });
      }
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;

        this.getPeriod();
      });
    },

    async getPeriod() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.loading = false;
        this.compiler = true;
      });
    },

    // Metodos llamados
    async saveStudent() {
      this.student.mail = this.st_mail_name + "@" + this.st_mail_domain;
      // Validación de campos
      if (
        this.student.paternal_surname != "" &&
        this.student.paternal_surname != null &&
        this.student.maternal_surname != "" &&
        this.student.maternal_surname != null &&
        this.student.names != "" &&
        this.student.names != null &&
        this.student.mail != "" &&
        this.student.mail != null &&
        this.student.curp != "" &&
        this.student.curp != null
      ) {
        // Validación de email (IMPORTANTE)
        if (this.mailValidate(this.student.mail.trim().toLowerCase())) {
          // Completar el modelo de datos
          this.uppercaseModel();

          // Proceso de inserción
          this.loading = true;
          await students.insert(this.student).then(async (response) => {
            this.responseMessage = response.data.message;
            // Despues de insertar, lo buscamos para obtener su ID
            await students
              .getByName(
                this.student.paternal_surname,
                this.student.maternal_surname,
                this.student.names
              )
              .then((response) => {
                // Completamos el modelo
                this.student = response.data;

                // Mostramos resultados
                this.data_expand = false;
                this.responseView = true;
                this.post_ins = true;
                this.loading = false;
              });
          });
        } else this.showError("Correo electrónico no válido");
      } else this.showError("Completar campos obligatorios (*)");
    },

    async saveActive() {
      if (
        this.student_active.semester != "" &&
        this.student_active.semester != null &&
        this.student_active.groups != "" &&
        this.student_active.groups != null
      ) {
        this.buildReg();
      } else this.showError("Seleccionar el semestre y el grupo");
    },

    mailValidate(mail) {
      if (
        /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
          mail
        )
      )
        return true;
      else return false;
    },

    async getTutor(id) {
      await tutors.getOne(id).then((response) => {
        this.tutor = response.data;

        if (this.tutor.mail != null && this.tutor.mail != "") {
          var mail = this.tutor.mail.toLowerCase().split("@");
          this.tr_mail_name = mail[0];
          this.tr_mail_domain = mail[1];
        }

        this.insert_tutor = false;
        this.tutor_expand = true;
      });
    },

    async saveTutor() {
      this.tutor.mail = this.tr_mail_name + "@" + this.tr_mail_domain;
      if (
        this.tutor.paternal_surname != "" &&
        this.tutor.paternal_surname != null &&
        this.tutor.maternal_surname != "" &&
        this.tutor.maternal_surname != null &&
        this.tutor.names != "" &&
        this.tutor.names != null &&
        this.tutor.curp != "" &&
        this.tutor.curp != null &&
        this.tutor.phone != "" &&
        this.tutor.phone != null &&
        this.tutor.mail != "" &&
        this.tutor.mail != null
      ) {
        if (this.mailValidate(this.tutor.mail.trim().toLowerCase())) {
          this.loading = true;
          this.uppercaseModelTutor();
          if (this.insert_tutor)
            await tutors.insert(this.tutor).then((response) => {
              this.responseMessage = response.data.message;
              this.responseView = true;

              this.getTutors();
              this.tutor_expand = false;
              this.post_tut = true;
              this.loading = false;
            });
          else
            await tutors.update(this.tutor.id, this.tutor).then((response) => {
              this.responseMessage = response.data.message;
              this.responseView = true;

              this.getTutors();
              this.tutor_expand = false;
              this.post_tut = true;
              this.loading = false;
            });
        } else this.showError("Correo electrónico no válido (*)");
      } else this.showError("Completar campos obligatorios (*)");
    },

    async getTutors() {
      await tutors.get(this.student.id).then((response) => {
        this.tutors = response.data;

        this.tutor = {};
      });
    },

    showError(message) {
      this.error = true;
      this.errorInfo = message;
      setTimeout(() => (this.error = false), 3000);
    },

    expandTutor() {
      this.tutor_expand = !this.tutor_expand;
      this.insert_tutor = true;
      this.tutor = {};
    },

    back() {
      this.$router.push("/ad/ins");
    },

    // support
    async getGroups() {
      await groups
        .get(this.student_active.semester, this.period)
        .then((response) => {
          this.groups = response.data;
        });
    },

    // Validar y dar formato al alumno
    uppercaseModel() {
      // Datos en mayusculas y sin acentos
      this.student.paternal_surname = functions.normalizeInput(
        this.student.paternal_surname.trim().toUpperCase()
      );
      this.student.maternal_surname = functions.normalizeInput(
        this.student.maternal_surname.trim().toUpperCase()
      );
      this.student.names = functions.normalizeInput(
        this.student.names.trim().toUpperCase()
      );
      this.student.mail = this.student.mail.trim().toLowerCase();
      this.student.curp = this.student.curp.trim().toUpperCase();
      this.student.street = this.student.street.trim().toUpperCase();
      this.student.colony = this.student.colony.trim().toUpperCase();
      this.student.city = this.student.city.trim().toUpperCase();
      this.student.municipality = this.student.municipality
        .trim()
        .toUpperCase();
      this.student.birth_city = this.student.birth_city.trim().toUpperCase();
      this.student.birth_municipality = this.student.birth_municipality
        .trim()
        .toUpperCase();
      this.student.birth_state = this.student.birth_state.trim().toUpperCase();
      this.student.nationality = this.student.nationality.trim().toUpperCase();

      // Datos faltantes
      this.student.enrollment = "";
      this.student.status = 0;
      this.student.photo = "uploads/students/default.png";
    },

    // Validar y dar formato al tutor
    uppercaseModelTutor() {
      // Datos en mayusculas
      this.tutor.paternal_surname = this.tutor.paternal_surname
        .trim()
        .toUpperCase();
      this.tutor.maternal_surname = this.tutor.maternal_surname
        .trim()
        .toUpperCase();
      this.tutor.names = this.tutor.names.trim().toUpperCase();
      this.tutor.occupation = this.tutor.occupation.trim().toUpperCase();
      this.tutor.street = this.tutor.street.trim().toUpperCase();
      this.tutor.colony = this.tutor.colony.trim().toUpperCase();
      this.tutor.city = this.tutor.city.trim().toUpperCase();
      this.tutor.municipality = this.tutor.municipality.trim().toUpperCase();
      this.tutor.curp = this.tutor.curp.trim().toUpperCase();
      this.tutor.scholarship = this.tutor.scholarship.trim().toUpperCase();
      this.tutor.civil_status = this.tutor.civil_status.trim().toUpperCase();
      this.tutor.residence_state = this.tutor.residence_state
        .trim()
        .toUpperCase();
      this.tutor.mail = this.tutor.mail.trim().toLowerCase();

      // Datos faltantes
      this.tutor.student = this.student.id;
    },
    async buildReg() {
      let date = new Date();

      // Datos de la tabla cei_students (estudiante: matricula y estado)
      var enroll = this.period.split("-");
      var st = enroll[0];
      var nd = enroll[1];
      var first = st.split("20");
      var second = nd.split("20");
      this.student.enrollment =
        "BAC" + first[1] + second[1] + "0" + this.student.id;

      // Va a estar activo (Al seleccionar su semestre y grupo)
      this.student.status = 1;
      await students
        .update(this.student.id, this.student)
        .then(async (response) => {
          // Datos de la tabla cei_students_active (inscripcion actual)
          this.student_active.student = this.student.id;
          this.student_active.period = this.period;
          this.student_active.inscription_date = date.toISOString();
          this.student_active.inscription_hour = date.getHours();
          this.student_active.inscription_user = "";

          // Va a estar activo (Al seleccionar su semestre y grupo)
          this.student_active.status = 1;

          this.responseMessage = response.data.message;
          this.responseView = true;

          await students_active.insert(this.student_active).then((response) => {
            this.responseMessage = response.data.message;

            this.loading = false;
            this.back();
          });
        });
    },
  },
};
</script>