import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "concepts/";

export default {
    get(type) {
        const token = auth.getSession();
        return axios.get(`${ENDPOINT}get/${type}`, { headers: { token } });
    },

    getOne(id) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + id, { headers: { token } });
    },

    insert(concept) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, concept, { headers: { token } });
    },

    update(id, concept) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, concept, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },
};