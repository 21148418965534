<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container>
      <v-row>
        <v-col cols="12">
          <a @click="goBack()">
            <div class="text-subtitle-2">
              <v-icon class="mr-2" color="primary">mdi-chevron-left</v-icon>
              Control de asignatura
            </div>
          </a>
          <v-alert type="info" dismissible class="d-flex d-sm-none mt-4">
            Recomendamos rotar el dispositivo horizontalmente
          </v-alert>
          <v-row class="my-4">
            <v-slide-y-reverse-transition>
              <v-col cols="12" v-show="compiler">
                <v-card
                  style="border-radius: 10px"
                  :style="{
                    background: $vuetify.theme.themes[theme].images,
                  }"
                  class="mb-6"
                >
                  <v-card-text>
                    <div class="text-h6 text--primary">Calificaciones</div>
                    <div class="text-subtitle-2 text--primary mt-4">
                      Examen escrito de
                      {{ subject.name }}
                    </div>
                    <div class="text-body-2">
                      {{ lesson.semester }}° {{ lesson.groups }}
                    </div>
                    <v-alert type="info" class="mt-2" outlined text>
                      <div class="text-subtitle-2">Atención</div>
                      <div class="text-body-2">
                        Esta información será registrada en el parcial activado
                        globalmente, que es:
                      </div>
                      <div class="text-h4 mt-2">
                        {{ period_model.partial }}° parcial
                      </div>
                    </v-alert>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-list-item
                    @click="
                      openValue(
                        student.id,
                        student.paternal_surname +
                          ' ' +
                          student.maternal_surname +
                          ' ' +
                          student.names
                      )
                    "
                    link
                    v-for="student in students_list"
                    :key="student.id"
                  >
                    <v-list-item-avatar size="30">
                      <v-img
                        lazy-src="../../../../assets/images/default.png"
                        :src="back_url + student.photo"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title class="text-subtitle-2">
                      {{ student.number }}.
                      {{ student.paternal_surname }}
                      {{ student.maternal_surname }}
                      {{ student.names }}
                      <strong class="primary--text">
                        ({{ student.exam }})
                      </strong>
                      <span class="font-italic text--disabled">
                        Parcial: {{ student.rating_number }} ({{
                          student.rating_letter
                        }})
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-slide-y-reverse-transition>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="action" width="550" persistent>
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-card-title class="text-h6 text-truncate" style="display: block">
            {{ selected_student }}
          </v-card-title>
          <v-card-text>
            <v-alert type="info" outlined text>
              <div class="text-subtitle-2">
                Calificación de PRUEBA ESCRITA correspondiente al:
              </div>
              <div class="text-h3 my-1">
                {{ period_model.partial }}° parcial
              </div>
              <div class="text-caption">
                Esta información es resaltada debido a los constantes cambios en
                el parcial activo, ocasionados por los módulos de primer
                semestre. Lamentamos los inconvenientes.
              </div>
            </v-alert>
            <v-text-field
              v-model="partial.exam"
              outlined
              hide-details
              label="Calificación (0 - 10)*"
              class="mt-6 mb-4"
            ></v-text-field>
            <div
              v-show="subject.complementary"
              class="text-body-2 font-weight-bold primary--text mb-5"
            >
              <v-icon small color="primary" class="mr-2">
                mdi-information-outline
              </v-icon>
              Esta materia es complementaria, si la suma parcial es mayor a 6
              será A (Aprobada), de lo contrario, será NA (No aprobada)
            </div>
            <div class="text-subtitle-2 mb-2">
              Evidencias entregadas:
              <span class="primary--text">
                {{ partial.evidences }} ({{ partial.evidences_value }}%)
              </span>
              . Porcentaje de examen:
              <span class="primary--text"> {{ partial.exam_value }}% </span>
            </div>
            <v-expand-transition>
              <div v-show="error">
                <v-alert dense text type="error">
                  <div class="text-subtitle-2">{{ errorInfo }}</div>
                </v-alert>
              </div>
            </v-expand-transition>
            <v-expand-transition>
              <div class="integration" v-show="finals">
                <v-alert type="info" border="left" text>
                  <div class="text-subtitle-2 mb-2">Calificación final</div>
                  <div class="text-h5">
                    1° P:
                    <span
                      class="font-weight-bold"
                      :class="
                        first_partial.rating_number ? '' : 'text--disabled'
                      "
                    >
                      {{
                        first_partial.rating_number
                          ? first_partial.rating_number
                          : "NP"
                      }}
                    </span>
                  </div>
                  <div class="text-h5">
                    2° P:
                    <span
                      class="font-weight-bold"
                      :class="
                        second_partial.rating_number ? '' : 'text--disabled'
                      "
                    >
                      {{
                        second_partial.rating_number
                          ? second_partial.rating_number
                          : "NP"
                      }}
                    </span>
                  </div>
                  <div class="text-h5">
                    3° P:
                    <span
                      class="font-weight-bold"
                      :class="
                        third_partial.rating_number ? '' : 'text--disabled'
                      "
                    >
                      {{
                        third_partial.rating_number
                          ? third_partial.rating_number
                          : "NP"
                      }}
                    </span>
                  </div>
                  <div class="text-h6 mt-2">
                    Final:
                    <span
                      class="font-weight-bold"
                      :class="final.number ? 'info--text' : 'text--disabled'"
                    >
                      {{
                        final.number
                          ? final.average + " (" + final.number + ")"
                          : "NP"
                      }}
                    </span>
                  </div>
                </v-alert>
              </div>
            </v-expand-transition>
          </v-card-text>
          <v-card-text
            :style="{
              background: $vuetify.theme.themes[theme].background,
            }"
          >
            <v-row align="center">
              <v-col cols="6">
                <v-btn
                  @click="setValue()"
                  color="primary"
                  dark
                  elevation="0"
                  block
                >
                  <div class="text-subtitle-2" style="text-transform: none">
                    Guardar
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn @click="action = false" outlined elevation="0" block>
                  <div class="text-subtitle-2" style="text-transform: none">
                    Cancelar
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="updater" width="80" persistent>
        <v-card style="border-radius: 10px" loading="true">
          <div class="text-center my-6">
            <v-progress-circular
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">
          {{ responseMessage }}
        </div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import functions from "../../../../plugins/functions";
import auth from "../../../../plugins/auth";
import settings from "../../../../plugins/settings";
import periods from "../../../../plugins/periods";
import lessons from "../../../../plugins/lessons";
import curriculum from "../../../../plugins/curriculum";
import students_active from "../../../../plugins/students_active";
import partial from "../../../../plugins/partial";
import final from "../../../../plugins/final";

// resources
import endpoint from "../../../../plugins/endpoint";

export default {
  name: "Ratings",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    clave: "",
    period_model: {},

    lesson: {},
    subject: {},
    subject_icon: "",

    students: [],
    students_list: [],

    action: false,
    partial: {},
    selected_student: "",

    // registro de todos los parciales (calificación final)
    first_partial: {},
    second_partial: {},
    third_partial: {},

    // finales
    final: {},
    insert_final: true,

    pes: false,
    act: false,

    // Integración de calificación final
    finals: true,

    // Reportes
    reports: false,

    back_url: "",

    loading: false,
    downloading: false,
    updater: false,
    compiler: false,

    responseView: false,
    responseMessage: "",

    error: false,
    errorInfo: "",
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;
        this.back_url = endpoint.BASE_PATH;
        this.subject.icon = "Ninguna";

        await auth.permission().then((response) => {
          if (response.data.directive == "teacher") this.getLesson();
          else this.$router.push("/");
        });
      }
    },

    async getLesson() {
      await lessons.getOne(this.$route.params.id).then((response) => {
        this.lesson = response.data;
        if (response.data.status == "error") this.goBack();
        else this.directive();
      });
    },

    async directive() {
      await auth.identity().then((response) => {
        if (response.data.directive != this.lesson.teacher) this.goBack();
        else this.getSubject();
      });
    },

    async getSubject() {
      await curriculum.getOne(this.lesson.subject).then((response) => {
        this.subject = response.data;
        this.subject_icon = response.data.icon;

        this.getSettings();
      });
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;
        this.clave = response.data.clave;

        this.getPeriods();
      });
    },

    async getPeriods() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        // Mostrar la información para la integración final
        if (this.period_model.partial == 3) this.finals = true;
        else this.finals = false;

        this.getStudents();
      });
    },

    async getStudents() {
      await students_active
        .getLesson(
          this.subject.id,
          this.lesson.semester,
          this.lesson.groups,
          this.period
        )
        .then((response) => {
          this.students = response.data;

          this.testPartial();
        });
    },

    async testPartial() {
      this.buildPartial();

      Promise.all(
        this.students.map(async (std) => {
          await partial
            .getBy(
              this.period_model.partial,
              this.lesson.id,
              std.student,
              this.period
            )
            .then(async (response) => {
              if (response.data.status == "error") {
                this.partial.student = std.student;

                await partial.insert(this.partial).then((response) => {
                  this.responseMessage = response.data.message;
                });
              }
            });
        })
      ).then(() => {
        this.getPartial();
      });
    },

    async getPartial() {
      await partial
        .get(
          this.subject.id,
          this.lesson.semester,
          this.lesson.groups,
          this.lesson.period,
          this.period_model.partial
        )
        .then((response) => {
          this.students_list = response.data;
          this.loading = false;
          this.compiler = true;
        });
    },

    // invoked
    async openValue(id, student) {
      this.loading = true;
      await partial.getOne(id).then(async (response) => {
        this.partial = response.data;
        this.selected_student = student;

        // Obtener los registros de todos los parciales (si es final)
        if (this.finals) {
          await partial
            .getBy(1, this.lesson.id, this.partial.student, this.period)
            .then(async (response) => {
              if (response.data.status != "error")
                this.first_partial = response.data;
              else this.first_partial = {};
              await partial
                .getBy(2, this.lesson.id, this.partial.student, this.period)
                .then(async (response) => {
                  if (response.data.status != "error")
                    this.second_partial = response.data;
                  else this.second_partial = {};
                  await partial
                    .getBy(3, this.lesson.id, this.partial.student, this.period)
                    .then(async (response) => {
                      if (response.data.status != "error")
                        this.third_partial = response.data;
                      else this.third_partial = {};
                      await final
                        .getBy(
                          this.lesson.id,
                          this.partial.student,
                          this.period
                        )
                        .then((response) => {
                          if (response.data.status != "error") {
                            this.final = response.data;
                            this.insert_final = false;
                          } else {
                            this.final = {};
                            this.insert_final = true;
                          }
                          this.action = true;
                          this.loading = false;
                        });
                    });
                });
            });
        } else {
          this.action = true;
          this.loading = false;
        }
      });
    },

    async setValue() {
      if (this.partial.exam != null && this.partial.exam != "") {
        // -- validation
        var rating = parseFloat(this.partial.exam);
        if (isNaN(rating)) this.showError("Ingresar número válido");
        else if (rating < 0 || rating > 10)
          this.showError("Ingresar valor del 0 al 10");
        // -- end validation
        else {
          this.loading = true;

          // -- computed
          var exam_cent =
            parseFloat(this.partial.exam) *
            parseFloat(this.subject.exam_percent);
          var exam = parseFloat(exam_cent / 10);
          if (isNaN(exam)) exam = 0;

          var evidences = parseFloat(this.partial.evidences_value);
          var rating_cent = parseFloat(evidences + exam) / 10;
          var rating = parseFloat(rating_cent)
            .toString()
            .match(/^-?\d+(?:\.\d{0,1})?/)[0];
          if (isNaN(rating)) rating = 0;
          // -- end computed

          this.partial.exam_value = exam;

          // -- rating logic
          if (parseFloat(rating) >= 5) {
            this.partial.rating_number = rating;
            this.partial.rating_letter = functions.spellRating(rating);
          } else {
            if (parseFloat(rating) == 0) {
              this.partial.rating_number = "0";
              this.partial.rating_letter = "NO PRESENTÓ";
            } else {
              this.partial.rating_number = "5";
              this.partial.rating_letter = "CINCO";
            }
          }
          // -- end logic

          await partial
            .update(this.partial.id, this.partial)
            .then(async (response) => {
              this.responseMessage = response.data.message;

              // Integración de la calificación final
              if (this.finals) {
                // Comprobación de las calificaciones parciales (evitar errores)
                if (
                  this.first_partial.rating_number == "" ||
                  this.first_partial.rating_number == null
                )
                  this.first_partial.rating_number = 0;
                if (
                  this.second_partial.rating_number == "" ||
                  this.second_partial.rating_number == null
                )
                  this.second_partial.rating_number = 0;
                if (
                  this.partial.rating_number == "" ||
                  this.partial.rating_number == null
                )
                  this.partial.rating_number = 0;

                // Suma de los 3 parciales
                var partial_sum =
                  parseFloat(this.first_partial.rating_number) +
                  parseFloat(this.second_partial.rating_number) +
                  parseFloat(this.partial.rating_number);

                // Calculo del promedio
                var average = partial_sum / 3;

                // Logica de calificaciones
                if (average == 0) {
                  // No presentó
                  this.final.number = "0";
                  this.final.letter = "NO PRESENTÓ";
                  this.final.average = "0";
                  this.final.status = false;
                } else {
                  if (Math.trunc(average) <= 5) {
                    // Reprobado (lógica de redondeo)
                    if (Math.trunc(average) < 5) {
                      // Reprobado con menor a 5 (5 obligatorio)
                      this.final.average = "5";
                    }
                    if (Math.trunc(average) == 5) {
                      // Reprobado con decimales en el 5
                      this.final.average = Math.trunc(average * 10) / 10;
                    }
                    this.final.number = "5";
                    this.final.letter = "CINCO";
                    this.final.status = false;
                  } else {
                    // No reprobado (calculos de redondeo)
                    this.final.number = Math.round(average);
                    this.final.letter = functions.spellRating(
                      this.final.number.toString()
                    );
                    this.final.average = Math.trunc(average * 10) / 10;
                    this.final.status = true;
                  }
                }

                // Inserción/edición  del final
                if (this.insert_final) {
                  // Construcción del final
                  this.final.lesson = this.partial.lesson;
                  this.final.subject = this.partial.subject;
                  this.final.student = this.partial.student;
                  this.final.period = this.period;
                  this.final.clave = this.clave;
                  this.final.type = "F";
                  await final.insert(this.final).then((response) => {
                    this.responseMessage = response.data.message;
                    this.getPartial();
                    this.action = false;
                    this.responseView = true;
                  });
                } else {
                  await final
                    .update(this.final.id, this.final)
                    .then((response) => {
                      this.responseMessage = response.data.message;
                      this.getPartial();
                      this.action = false;
                      this.responseView = true;
                    });
                }
              } else {
                this.getPartial();
                this.action = false;
                this.responseView = true;
              }
            });
        }
      } else this.showError("Ingrese una calificación");
    },

    goBack() {
      this.$router.push("/op/ls/" + this.lesson.id);
    },

    // support
    buildPartial() {
      this.partial.partial = this.period_model.partial;
      this.partial.lesson = this.lesson.id;
      this.partial.subject = this.subject.id;
      this.partial.fouls = 0;
      this.partial.evidences = "0";
      this.partial.evidences_value = "0";
      this.partial.exam = "0";
      this.partial.exam_value = "0";
      this.partial.rating_number = "";
      this.partial.rating_letter = "";
      this.partial.period = this.period;
    },
    showError(message) {
      this.errorInfo = message;
      this.error = true;
      setTimeout(() => (this.error = false), 3000);
    },
  },
};
</script>