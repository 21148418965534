<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="11" lg="10" xl="9">
          <a @click="goBack()">
            <div class="text-subtitle-2">
              <v-icon class="mr-2" color="primary">mdi-chevron-left</v-icon>
              Control de asignatura
            </div>
          </a>
          <v-row class="my-4">
            <v-slide-y-transition>
              <v-col cols="12" sm="4" lg="3" v-show="compiler">
                <v-card color="primary" style="border-radius: 10px">
                  <v-img
                    lazy-src="../../../../assets/images/default.png"
                    src="../../../../assets/images/evidences.png"
                    height="300"
                    contain
                  ></v-img>
                </v-card>
              </v-col>
            </v-slide-y-transition>
            <v-fade-transition>
              <v-col cols="12" sm="8" lg="9" v-show="compiler">
                <div class="text-h5 font-weight-bold">
                  Evidencia ({{ students_list.length }})
                </div>
                <div class="text-subtitle-2 text--secondary mt-2">
                  Contabilizada dentro del {{ evidence.partial }}° parcial
                </div>
                <v-row class="my-4">
                  <v-col cols="12" md="6">
                    <v-select
                      :items="evidences_types"
                      v-model="evidence.evidence_type"
                      item-value="id"
                      item-text="type"
                      filled
                      dense
                      hide-details
                      label="Actividad calificada"
                      :disabled="!edit"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="evidence.date"
                      type="date"
                      filled
                      dense
                      hide-details
                      label="Fecha de registro"
                      :disabled="!edit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="edit"
                      label="Cambiar fecha y actividad"
                      hide-details
                    ></v-checkbox>
                    <v-btn
                      @click="updateEvidence()"
                      color="primary"
                      dark
                      elevation="0"
                      :disabled="!edit"
                      class="mt-4"
                    >
                      <div
                        class="text-subtitle-2 mx-2"
                        style="text-transform: none"
                      >
                        Actualizar registro
                      </div>
                    </v-btn>
                    <v-alert
                      type="info"
                      dismissible
                      class="d-flex d-sm-none mt-4"
                    >
                      Recomendamos rotar el dispositivo horizontalmente
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>
            </v-fade-transition>
            <v-slide-y-reverse-transition>
              <v-col cols="12" v-show="compiler">
                <v-card
                  style="border-radius: 10px"
                  :style="{
                    background: $vuetify.theme.themes[theme].images,
                  }"
                  class="mb-6"
                >
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img
                        lazy-src="../../../../assets/images/default.png"
                        :src="
                          require('../../../../assets/subjects/' +
                            subject.icon +
                            '.png')
                        "
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title class="text-subtitle-2">
                      Calificando para
                      {{ subject.name }}, en {{ lesson.semester }}
                      {{ lesson.groups }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item @click="deleted()" link>
                            <v-list-item-icon>
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Eliminar evidencia
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    @click="
                      openValue(
                        student.id,
                        student.paternal_surname +
                          ' ' +
                          student.maternal_surname +
                          ' ' +
                          student.names
                      )
                    "
                    link
                    v-for="student in students_list"
                    :key="student.id"
                  >
                    <v-list-item-avatar size="30">
                      <v-img
                        lazy-src="../../../../assets/images/default.png"
                        :src="back_url + student.photo"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title class="text-subtitle-2">
                      {{ student.number }}.
                      {{ student.paternal_surname }}
                      {{ student.maternal_surname }}
                      {{ student.names }}
                      <strong class="primary--text">
                        ({{ student.rating }})
                      </strong>
                      <span class="font-italic text--disabled">
                        {{ student.comment }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-slide-y-reverse-transition>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="action" width="550" persistent>
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-card-title class="text-h6 text-truncate" style="display: block">
            {{ selected_student }}
          </v-card-title>
          <v-card-text>
            <div class="text-subtitle-2">Calificando su actividad</div>
            <v-text-field
              v-model="evidence_list.rating"
              outlined
              hide-details
              label="Calificación (0 - 10)*"
              class="my-4"
            ></v-text-field>
            <v-select
              :items="evidences_comments"
              item-value="comment"
              item-text="comment"
              v-model="evidence_list.comment"
              filled
              dense
              hide-details
              clearable
              label="Comentario"
              class="mb-4"
            ></v-select>
            <v-expand-transition>
              <div v-show="error">
                <v-alert dense text type="error">
                  <div class="text-subtitle-2">{{ errorInfo }}</div>
                </v-alert>
              </div>
            </v-expand-transition>
          </v-card-text>
          <v-card-text
            :style="{
              background: $vuetify.theme.themes[theme].background,
            }"
          >
            <v-row align="center">
              <v-col cols="6">
                <v-btn
                  @click="setValue()"
                  color="primary"
                  dark
                  elevation="0"
                  block
                >
                  <div class="text-subtitle-2" style="text-transform: none">
                    Guardar
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn @click="action = false" outlined elevation="0" block>
                  <div class="text-subtitle-2" style="text-transform: none">
                    Cancelar
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="updater" width="80" persistent>
        <v-card style="border-radius: 10px" loading="true">
          <div class="text-center my-6">
            <v-progress-circular
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">
          {{ responseMessage }}
        </div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import functions from "../../../../plugins/functions";
import auth from "../../../../plugins/auth";
import settings from "../../../../plugins/settings";
import periods from "../../../../plugins/periods";
import lessons from "../../../../plugins/lessons";
import curriculum from "../../../../plugins/curriculum";
import students_active from "../../../../plugins/students_active";
import partial from "../../../../plugins/partial";
import evidences from "../../../../plugins/evidences";
import evidences_list from "../../../../plugins/evidences_list";
import evidences_types from "../../../../plugins/evidences_types";
import evidences_comments from "../../../../plugins/evidences_comments";

// resources
import endpoint from "../../../../plugins/endpoint";

export default {
  name: "Evidence",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    period_model: {},

    evidences: [],
    evidence: {},
    new_evidence: {},
    evidence_date: "",

    evidences_types: [],
    evidences_comments: [],

    lesson: {},
    subject: {},

    students: [],
    partial: {},
    students_list: [],

    action: false,
    selected_student: "",
    evidence_list: {},

    edit: false,

    back_url: "",

    loading: false,
    updater: false,
    compiler: false,

    responseView: false,
    responseMessage: "",

    error: false,
    errorInfo: "",
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;
        this.back_url = endpoint.BASE_PATH;
        this.subject.icon = "Ninguna";

        await auth.permission().then((response) => {
          if (response.data.directive == "teacher") this.getEvidence();
          else this.$router.push("/");
        });
      }
    },

    async getEvidence() {
      await evidences.getOne(this.$route.params.evidence).then((response) => {
        this.evidence = response.data;
        this.evidence.date = functions.inputDate(this.evidence.date);
        this.evidence_date = this.evidence.date;

        if (response.data.status == "error") this.goBack();
        else this.getLesson();
      });
    },

    async getLesson() {
      await lessons.getOne(this.$route.params.id).then((response) => {
        this.lesson = response.data;

        if (response.data.status == "error") this.goBack();
        else this.directive();
      });
    },

    async directive() {
      await auth.identity().then((response) => {
        if (response.data.directive != this.lesson.teacher) this.goBack();
        else this.getSubject();
      });
    },

    async getSubject() {
      await curriculum.getOne(this.lesson.subject).then((response) => {
        this.subject = response.data;

        this.getSettings();
      });
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;

        this.getPeriod();
      });
    },

    async getPeriod() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.getTypes();
      });
    },

    async getTypes() {
      await evidences_types.get().then((response) => {
        this.evidences_types = response.data;

        this.getComments();
      });
    },

    async getComments() {
      await evidences_comments.get().then((response) => {
        this.evidences_comments = response.data;

        this.getStudents();
      });
    },

    async getStudents() {
      await students_active
        .getLesson(
          this.subject.id,
          this.lesson.semester,
          this.lesson.groups,
          this.period
        )
        .then((response) => {
          this.students = response.data;

          this.updater = true;
          this.testPartial();
        });
    },

    async testPartial() {
      this.buildPartial();

      Promise.all(
        this.students.map(async (std) => {
          await partial
            .getBy(
              this.evidence.partial,
              this.lesson.id,
              std.student,
              this.period
            )
            .then(async (response) => {
              if (response.data.status == "error") {
                this.partial.student = std.student;

                await partial.insert(this.partial).then((response) => {
                  this.responseMessage = response.data.message;
                });
              }
            });
        })
      ).then(() => {
        this.testStudents();
      });
    },

    async testStudents() {
      this.buildEvidenceList();

      Promise.all(
        this.students.map(async (std) => {
          await evidences_list
            .getBy(this.evidence.id, std.student)
            .then(async (response) => {
              if (response.data.status == "error") {
                this.new_evidence.student = std.student;

                await evidences_list
                  .insert(this.new_evidence)
                  .then((response) => {
                    this.responseMessage = response.data.message;
                  });
              }
            });
        })
      ).then(() => {
        this.getList();
      });
    },

    async getList() {
      this.loading = true;
      await evidences_list
        .get(
          this.subject.id,
          this.lesson.semester,
          this.lesson.groups,
          this.period,
          this.evidence.id
        )
        .then((response) => {
          this.students_list = response.data;

          this.getEvidences();
          this.loading = false;
        });
    },

    async getEvidences() {
      await evidences
        .get(this.period, this.evidence.partial, this.lesson.id)
        .then((response) => {
          this.evidences = response.data;

          this.updater = false;
          this.loading = false;
          this.compiler = true;
        });
    },

    // invoked
    async updateEvidence() {
      this.loading = true;
      await evidences
        .update(this.evidence.id, this.evidence)
        .then((response) => {
          this.responseMessage = response.data.message;

          this.evidence_list = {};
          this.evidence_list.evidence_type = this.evidence.evidence_type;
          this.evidence_list.date = this.evidence.date;
          this.updateList(this.evidence.id);
        });
    },

    async openValue(id, student) {
      this.loading = true;
      await evidences_list.getOne(id).then((response) => {
        this.evidence_list = response.data;
        this.selected_student = student;

        this.action = true;
        this.loading = false;
      });
    },

    async setValue() {
      if (
        this.evidence_list.rating != null &&
        this.evidence_list.rating != ""
      ) {
        // -- validation
        var rating = parseFloat(this.evidence_list.rating);
        if (isNaN(rating)) this.showError("Ingresar número válido");
        else if (rating < 0 || rating > 10)
          this.showError("Ingresar valor del 0 al 10");
        // -- end validation
        else {
          this.loading = true;

          await evidences_list
            .update(this.evidence_list.id, this.evidence_list)
            .then(async (response) => {
              this.responseMessage = response.data.message;

              await evidences_list
                .getTotal(
                  this.evidence_list.lesson,
                  this.evidence_list.student,
                  this.evidence_list.partial,
                  this.evidence_list.period
                )
                .then(async (response) => {
                  // -- computed
                  var total = parseFloat(this.evidences.length) * 10;

                  var decimals =
                    parseFloat(response.data.percent) *
                    parseFloat(this.subject.evidence_percent);
                  if (isNaN(decimals)) decimals = 0;
                  var evidences_decimals = parseFloat(decimals / total)
                    .toString()
                    .match(/^-?\d+(?:\.\d{0,1})?/)[0];
                  var evidences = parseFloat(evidences_decimals);
                  if (isNaN(evidences)) evidences = 0;

                  var exam_cent =
                    parseFloat(response.data.exam) *
                    parseFloat(this.subject.exam_percent);
                  var exam = parseFloat(exam_cent / 10);
                  if (isNaN(exam)) exam = 0;

                  var rating_cent = parseFloat(evidences + exam) / 10;
                  var rating = parseFloat(rating_cent)
                    .toString()
                    .match(/^-?\d+(?:\.\d{0,1})?/)[0];
                  if (isNaN(rating)) rating = 0;
                  // -- end computed

                  this.partial = {};
                  this.partial.evidences = response.data.total;
                  this.partial.evidences_value = evidences;
                  this.partial.exam = response.data.exam || 0;
                  this.partial.exam_value = exam;

                  // -- rating logic
                  if (parseFloat(rating) >= 5) {
                    this.partial.rating_number = rating;
                    this.partial.rating_letter = functions.spellRating(rating);
                  } else {
                    if (parseFloat(rating) == 0) {
                      this.partial.rating_number = "0";
                      this.partial.rating_letter = "NO PRESENTÓ";
                    } else {
                      this.partial.rating_number = "5";
                      this.partial.rating_letter = "CINCO";
                    }
                  }
                  // -- end logic

                  await partial
                    .updateBy(
                      this.evidence_list.partial,
                      this.evidence_list.lesson,
                      this.evidence_list.student,
                      this.evidence_list.period,
                      this.partial
                    )
                    .then((response) => {
                      this.responseMessage = response.data.message;

                      this.getList();
                      this.responseView = true;
                      this.action = false;
                      this.loading = false;
                    });
                });
            });
        }
      } else this.showError("Asigne una calificación");
    },

    async deleted() {
      await evidences_list.delete(this.evidence.id).then(async (response) => {
        console.log(response.data.message);

        await evidences.delete(this.evidence.id).then(async (response) => {
          console.log(response.data.message);

          Promise.all(
            this.students.map(async (student) => {
              await evidences_list
                .getTotal(
                  this.lesson.id,
                  student.student,
                  this.evidence.partial,
                  this.period
                )
                .then(async (response) => {
                  // -- computed
                  var total = parseFloat(this.evidences.length - 1) * 10;

                  var decimals =
                    parseFloat(response.data.percent) *
                    parseFloat(this.subject.evidence_percent);
                  if (isNaN(decimals)) decimals = 0;
                  var evidences_decimals = parseFloat(decimals / total)
                    .toString()
                    .match(/^-?\d+(?:\.\d{0,1})?/)[0];
                  var evidences = parseFloat(evidences_decimals);
                  if (isNaN(evidences)) evidences = 0;

                  var exam_cent =
                    parseFloat(response.data.exam) *
                    parseFloat(this.subject.exam_percent);
                  var exam = parseFloat(exam_cent / 10);
                  if (isNaN(exam)) exam = 0;

                  var rating_cent = parseFloat(evidences + exam) / 10;
                  var rating = parseFloat(rating_cent)
                    .toString()
                    .match(/^-?\d+(?:\.\d{0,1})?/)[0];
                  if (isNaN(rating)) rating = 0;
                  // -- end computed

                  this.partial = {};
                  this.partial.evidences = response.data.total;
                  this.partial.evidences_value = evidences;
                  this.partial.exam = response.data.exam || 0;
                  this.partial.exam_value = exam;

                  // -- rating logic
                  if (parseFloat(rating) >= 5) {
                    this.partial.rating_number = rating;
                    this.partial.rating_letter = functions.spellRating(rating);
                  } else {
                    if (parseFloat(rating) == 0) {
                      this.partial.rating_number = "0";
                      this.partial.rating_letter = "NO PRESENTÓ";
                    } else {
                      this.partial.rating_number = "5";
                      this.partial.rating_letter = "CINCO";
                    }
                  }
                  // -- end logic

                  await partial
                    .updateBy(
                      this.evidence.partial,
                      this.lesson.id,
                      student.student,
                      this.period,
                      this.partial
                    )
                    .then((response) => {
                      console.log(response.data.message);
                    });
                });
            })
          ).then(() => {
            this.goBack();
          });
        });
      });
    },

    goBack() {
      this.$router.push("/op/ls/" + this.lesson.id);
    },

    // support
    async updateList(evidence) {
      await evidences_list
        .evidenceUpdate(evidence, this.evidence_list)
        .then((response) => {
          this.responseMessage = response.data.message;

          this.responseView = true;
          this.edit = false;
          this.loading = false;
        });
    },
    buildPartial() {
      this.partial.partial = this.evidence.partial;
      this.partial.lesson = this.lesson.id;
      this.partial.subject = this.subject.id;
      this.partial.fouls = 0;
      this.partial.evidences = "0";
      this.partial.evidences_value = "0";
      this.partial.exam = "0";
      this.partial.exam_value = "0";
      this.partial.rating_number = "0";
      this.partial.rating_letter = "CERO";
      this.partial.period = this.period;
    },
    buildEvidenceList() {
      this.new_evidence.evidence = this.evidence.id;
      this.new_evidence.lesson = this.evidence.lesson;
      this.new_evidence.date = this.evidence.date;
      this.new_evidence.rating = "0";
      this.new_evidence.comment = "";
      this.new_evidence.partial = this.evidence.partial;
      this.new_evidence.period = this.evidence.period;
      this.new_evidence.evidence_type = this.evidence.evidence_type;
    },
    showError(message) {
      this.errorInfo = message;
      this.error = true;
      setTimeout(() => (this.error = false), 3000);
    },
  },
};
</script>