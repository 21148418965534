<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="11" lg="10" xl="9">
          <a @click="goBack()">
            <div class="text-subtitle-2">
              <v-icon class="mr-2" color="primary">mdi-chevron-left</v-icon>
              Periodo escolar
            </div>
          </a>
          <v-alert v-show="editions" type="warning" class="my-4">
            Edición de campos importantes activada.
          </v-alert>
          <v-row class="my-4">
            <v-slide-y-transition>
              <v-col cols="12" sm="4" lg="3" v-show="compiler">
                <v-card color="primary" style="border-radius: 10px">
                  <v-img
                    lazy-src="../../../../assets/images/default.png"
                    src="../../../../assets/images/curriculum.png"
                    height="300"
                    contain
                  ></v-img>
                </v-card>
              </v-col>
            </v-slide-y-transition>
            <v-fade-transition>
              <v-col cols="12" sm="8" lg="9" v-show="compiler">
                <div class="text-h5 font-weight-bold">Mapa curricular</div>
                <div class="text-subtitle-2 text--secondary mt-2">
                  Correspondiente del periodo {{ period_model.period }}
                </div>
                <v-row class="my-4">
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="semester"
                      v-on:input="getCurriculum()"
                      :items="semesters"
                      item-value="id"
                      item-text="name"
                      label="Seleccionar semestre"
                      outlined
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-expand-transition>
                    <v-col v-show="isArea" cols="12" md="6">
                      <v-select
                        v-model="area"
                        v-on:input="getCurriculum()"
                        :items="areas"
                        label="Área propedéutica"
                        outlined
                        hide-details
                      ></v-select>
                    </v-col>
                  </v-expand-transition>
                  <v-col cols="12">
                    <v-btn
                      @click="openCurriculum(false, 0)"
                      color="primary"
                      dark
                      elevation="0"
                      :disabled="locked"
                      class="mt-4"
                    >
                      <div class="text-subtitle-2" style="text-transform: none">
                        Nueva asignatura
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-fade-transition>
            <v-slide-y-reverse-transition>
              <v-col cols="12" v-show="compiler">
                <v-card
                  style="border-radius: 10px"
                  :style="{
                    background: $vuetify.theme.themes[theme].images,
                  }"
                  class="mb-6"
                >
                  <v-list-item>
                    <v-list-item-title class="text-subtitle-2">
                      Asignaturas {{ header }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-row no-gutters>
                    <v-col
                      cols="6"
                      md="4"
                      lg="3"
                      v-for="curriculum in curriculums"
                      :key="curriculum.id"
                    >
                      <v-card
                        @click="openCurriculum(true, curriculum.id)"
                        link
                        outlined
                        style="border-radius: 10px"
                        :style="{
                          background: $vuetify.theme.themes[theme].images,
                        }"
                      >
                        <div class="text-center">
                          <v-avatar size="120" class="my-4">
                            <v-img
                              lazy-src="../../../../assets/images/default.png"
                              :src="
                                require('../../../../assets/subjects/' +
                                  curriculum.icon +
                                  '.png')
                              "
                            ></v-img>
                          </v-avatar>
                        </div>
                        <v-card-text class="text-center">
                          <div
                            class="text-subtitle-2 text--primary text-truncate"
                          >
                            {{ curriculum.name }}
                          </div>
                          <div class="text-caption text--secondary">
                            {{
                              curriculum.complementary
                                ? "Complementaria"
                                : "Regular"
                            }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-slide-y-reverse-transition>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="action" width="650">
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-card-title
            class="text-h5 text--primary font-weight-bold text-truncate"
            style="display: block"
          >
            <v-btn
              v-show="editions"
              @click="leaveCRM()"
              color="warning"
              dark
              elevation="0"
              class="mr-2"
              :disabled="edit_disabled"
            >
              <div class="text-subtitle-2 mx-2" style="text-transform: none">
                Baja
              </div>
            </v-btn>
            Asignatura
          </v-card-title>
          <v-card-text>
            <div class="text-subtitle-2 text--primary mt-2">
              {{ semester }}° semestre {{ area }}
            </div>
            <v-row class="mt-2">
              <v-col cols="10">
                <v-text-field
                  v-model="curriculum.name"
                  dense
                  filled
                  hide-details
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="curriculum.app_order"
                  type="number"
                  dense
                  filled
                  hide-details
                  label="Orden"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="curriculum.exam_percent"
                  dense
                  filled
                  hide-details
                  label="Examen (%)"
                  :disabled="!percents"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="curriculum.evidence_percent"
                  dense
                  filled
                  hide-details
                  label="Evidencias (%)"
                  :disabled="!percents"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-checkbox
              v-model="percents"
              label="Editar porcentajes (no recomendado)"
              hide-details
            ></v-checkbox>
            <v-row class="mt-4">
              <v-col cols="12" sm="6">
                <v-select
                  v-model="curriculum.type"
                  :items="types"
                  item-value="id"
                  item-text="name"
                  label="Tipo de asignatura"
                  hide-details
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-switch
                  v-model="curriculum.complementary"
                  label="Evaluada por letra (A - N/A)"
                ></v-switch>
              </v-col>
            </v-row>
            <v-expand-transition>
              <div v-show="error">
                <v-alert dense text type="error">
                  <div class="text-subtitle-2">{{ errorInfo }}</div>
                </v-alert>
              </div>
            </v-expand-transition>
          </v-card-text>
          <v-card-text
            :style="{
              background: $vuetify.theme.themes[theme].background,
            }"
          >
            <v-row align="center">
              <v-col cols="4">
                <v-card outlined>
                  <v-select
                    v-model="curriculum.icon"
                    :items="icons"
                    solo
                    flat
                    dense
                    hide-details
                    label="Icono"
                  ></v-select>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-btn @click="save()" color="primary" dark elevation="0" block>
                  <div class="text-subtitle-2" style="text-transform: none">
                    Guardar
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn @click="action = false" outlined elevation="0" block>
                  <div class="text-subtitle-2" style="text-transform: none">
                    Cancelar
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">
          {{ responseMessage }}
        </div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import auth from "../../../../plugins/auth";
import settings from "../../../../plugins/settings";
import periods from "../../../../plugins/periods";
import curriculum from "../../../../plugins/curriculum";
import lessons from "../../../../plugins/lessons";

export default {
  name: "Curriculum",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    period_model: {},
    periods: [],

    curriculums: [],
    header: "(Seleccionar)",
    curriculum: {},
    percents: false,

    // Baja de curriculum
    editions: false,
    edit_disabled: true,

    // Curriculum inicial (2021-2021)
    init_curriculum: [],

    action: false,
    insert: true,

    types: [
      { id: 1, name: "Curricular" },
      { id: 2, name: "Paraescolar" },
      { id: 3, name: "Capacitación" },
    ],

    semesters: [
      { id: 1, name: "Primer semestre" },
      { id: 2, name: "Segundo semestre" },
      { id: 3, name: "Tercer semestre" },
      { id: 4, name: "Cuarto semestre" },
      { id: 5, name: "Quinto semestre" },
      { id: 6, name: "Sexto semestre" },
    ],
    semester: 0,

    areas: [
      "Físico-Matemáticas",
      "Químico-Biológicas",
      "Sociales-Humanidades",
      "Económico-Administrativas",
    ],
    area: "",
    isArea: false,

    loading: false,
    compiler: false,

    responseView: false,
    responseMessage: "",

    error: false,
    errorInfo: "",

    locked: true,
    icons: [
      "Ninguna",
      "Etica",
      "Etica II",
      "Dibujo",
      "Fisica",
      "Fisica II",
      "Biologia",
      "Biologia II",
      "Historia",
      "Historia II",
      "Hechos",
      "Metodo",
      "Leyes",
      "Informatica",
      "Informatica II",
      "Ingles",
      "Ingles II",
      "Ingles III",
      "Ingles IV",
      "Matematicas",
      "Matematicas II",
      "Matematicas III",
      "Matematicas IV",
      "Quimica",
      "Quimica II",
      "Lectura",
      "Lectura II",
      "Literatura",
      "Literatura II",
      "Ciencias Sociales",
      "Orientacion",
      "Orientacion II",
    ],
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;

        await auth.permission().then((response) => {
          if (response.data.directive == "admin") this.getSettings();
          else this.$router.push("/");
        });
      }
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;
        this.editions = response.data.editions;

        this.getPeriod();
      });
    },

    async getPeriod() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.getPeriods();
      });
    },

    async getPeriods() {
      this.loading = true;
      await periods.get().then((response) => {
        this.periods = response.data;

        this.comprobed();
      });
    },

    async comprobed() {
      await curriculum.get(this.period).then(async (response) => {
        if (response.data.length == 0) {
          await curriculum.get("2022-2022").then(async (response) => {
            this.init_curriculum = response.data;

            // -- Insertar las materias en el periodo actual (importación)
            Promise.all(
              this.init_curriculum.map(async (crm) => {
                // Cambio de periodo
                delete crm.id;
                crm.period = this.period;

                // Inserción
                await curriculum.insert(crm).then((response) => {
                  console.log(response.data.message);
                });
              })
            ).then(() => {
              this.loading = false;
              this.compiler = true;
            });
          });
        } else {
          this.loading = false;
          this.compiler = true;
        }
      });
    },

    // invoked
    async getCurriculum() {
      this.loading = true;
      await curriculum
        .getBySemester(this.semester, this.period)
        .then((response) => {
          if (this.semester == 5 || this.semester == 6) {
            this.isArea = true;

            this.curriculums = Object.values(response.data).filter(
              (area) => area.area === this.area
            );

            if (this.area != "") this.locked = false;
            else this.locked = true;
          } else {
            this.isArea = false;
            this.curriculums = response.data;
            this.locked = false;
          }
          this.header = "(" + this.curriculums.length + ")";
          this.loading = false;
        });
    },

    async openCurriculum(edit, id) {
      if (!edit) {
        this.edit_disabled = true;
        this.curriculum = {};

        this.getDefault();
        this.insert = true;
        this.action = true;
      } else {
        this.edit_disabled = false;
        this.loading = true;
        await curriculum.getOne(id).then((response) => {
          this.curriculum = response.data;

          this.insert = false;
          this.action = true;
          this.loading = false;
        });
      }
    },

    getDefault() {
      this.curriculum.type = 1;
      this.curriculum.exam_percent = "40";
      this.curriculum.evidence_percent = "60";
      this.curriculum.icon = "Ninguna";
      this.curriculum.period = this.period;
    },

    async save() {
      if (this.curriculum.name != null) {
        var total_percent =
          parseFloat(this.curriculum.exam_percent) +
          parseFloat(this.curriculum.evidence_percent);
        if (total_percent == 100) {
          this.loading = true;
          this.curriculum.semester = this.semester;

          if (this.semester == 5 || this.semester == 6)
            this.curriculum.area = this.area;
          else this.curriculum.area = "";

          if (this.insert) {
            await curriculum.insert(this.curriculum).then((response) => {
              this.responseMessage = response.data.message;

              this.refresh();
            });
          } else {
            await curriculum
              .update(this.curriculum.id, this.curriculum)
              .then((response) => {
                this.responseMessage = response.data.message;

                this.refresh();
              });
          }
        } else this.showError("La suma de porcentajes debe ser 100.");
      } else this.showError("La asignatura necesita un nombre.");
    },

    refresh() {
      this.percents = false;
      this.getCurriculum();
      this.action = false;
      this.responseView = true;
      this.loading = false;
    },

    showError(message) {
      this.errorInfo = message;
      this.error = true;
      setTimeout(() => (this.error = false), 3000);
    },

    async leaveCRM() {
      // Eliminar las clases que imparten la materiaF
      await lessons
        .deleteCurriculum(this.curriculum.id)
        .then(async (response) => {
          console.log(response.data.message);

          // Eliminar la materia
          await curriculum.delete(this.curriculum.id).then((response) => {
            this.refresh();

            this.responseMessage = response.data.message;
            this.responseView = true;
          });
        });
    },

    goBack() {
      this.$router.push("/ad/cl");
    },
  },
};
</script>