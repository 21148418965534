import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "evidences-types/";

export default {
    get() {
        const token = auth.getSession();
        return axios.get(ENDPOINT, { headers: { token } });
    },

    getOne(id) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + id, { headers: { token } });
    },

    insert(type) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, type, { headers: { token } });
    },

    update(id, type) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, type, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },
};