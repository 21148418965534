import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "students-active/";

export default {
    get(semester, groups, period) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + semester + "/" + groups + "/" + period, { headers: { token } });
    },

    getLesson(subject, semester, groups, period) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + "lesson/" + subject + "/" + semester + "/" + groups + "/" + period, { headers: { token } });
    },

    getOne(id) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + id, { headers: { token } });
    },

    getByStudent(id, period) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + "by/student/" + id + "/" + period, { headers: { token } });
    },

    insert(active) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, active, { headers: { token } });
    },

    update(id, active) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, active, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },
};