import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "curriculum/";

export default {
    get(period) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + "list/" + period, { headers: { token } });
    },

    getOne(id) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + id, { headers: { token } });
    },

    insert(curriculum) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, curriculum, { headers: { token } });
    },

    update(id, curriculum) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, curriculum, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },

    getBySemester(semester, period) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + "semester/" + period + "/" + semester, { headers: { token } });
    },
};