import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT_PATH = end.PATH + "tutors/";

export default {
    get(student) {
        const token = auth.getSession();
        return axios.get(ENDPOINT_PATH + "list/" + student, { headers: { token } });
    },

    getOne(id) {
        const token = auth.getSession();
        return axios.get(ENDPOINT_PATH + id, { headers: { token } });
    },

    insert(tutor) {
        const token = auth.getSession();
        return axios.post(ENDPOINT_PATH, tutor, { headers: { token } });
    },

    update(id, tutor) {
        const token = auth.getSession();
        return axios.put(ENDPOINT_PATH + id, tutor, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT_PATH + id, { headers: { token } });
    },
};