var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contain"},[_c('v-progress-linear',{attrs:{"fixed":"","active":_vm.loading,"indeterminate":true}}),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('a',{on:{"click":function($event){return _vm.goBack()}}},[_c('div',{staticClass:"text-subtitle-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-chevron-left")]),_vm._v(" Control de asignatura ")],1)]),_c('v-alert',{staticClass:"d-flex d-sm-none mt-4",attrs:{"type":"info","dismissible":""}},[_vm._v(" Recomendamos rotar el dispositivo horizontalmente ")]),_c('v-row',{staticClass:"my-4"},[_c('v-slide-y-reverse-transition',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.compiler),expression:"compiler"}],attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-6",staticStyle:{"border-radius":"10px"},style:({
                  background: _vm.$vuetify.theme.themes[_vm.theme].images,
                })},[_c('v-card-text',[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-h6 text--primary"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v(" mdi-alert-rhombus-outline ")]),_vm._v(" Alumnos en riesgo ")],1),_c('div',{staticClass:"text-subtitle-2 text--primary mt-4"},[_vm._v(" Alumnos con "),_c('span',{attrs:{"color":"error"}},[_vm._v("menos del 50%")]),_vm._v(" del valor de las evidencias ")]),_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.subject.name)+" de "+_vm._s(_vm.lesson.semester)+"° "+_vm._s(_vm.lesson.groups)+" ("+_vm._s(_vm.period_model.partial)+"° parcial) ")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"warning","dark":"","elevation":"0"}},[_c('div',{staticClass:"text-subtitle-2 mx-2",staticStyle:{"text-transform":"none"}},[_vm._v(" Informe general ")])])],1)],1)],1),_c('v-divider'),_vm._l((_vm.students_list),function(student){return _c('v-list-item',{key:student.id,attrs:{"link":""},on:{"click":function($event){return _vm.openValue(
                      student.id,
                      student.paternal_surname +
                        ' ' +
                        student.maternal_surname +
                        ' ' +
                        student.names,
                      student.photo
                    )}}},[_c('v-list-item-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"lazy-src":require("../../../../assets/images/default.png"),"src":_vm.back_url + student.photo}})],1),_c('v-list-item-content',[_c('div',{staticClass:"text-subtitle-2 text-truncate"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning"}},[_vm._v(" mdi-alert-circle-outline ")]),_vm._v(" "+_vm._s(student.number)+". "+_vm._s(student.paternal_surname)+" "+_vm._s(student.maternal_surname)+" "+_vm._s(student.names)+" ")],1),_c('div',{staticClass:"text-subtitle-2"},[_c('strong',{staticClass:"warning--text"},[_vm._v(" "+_vm._s(student.evidences_value)+"% ")]),_c('span',{staticClass:"text--secondary"},[_vm._v(" | "+_vm._s(student.evidences)+" entregadas, "),_c('span',{staticClass:"font-weight-medium",class:student.fouls > 0
                              ? 'error--text'
                              : 'success--text'},[_vm._v(" "+_vm._s(student.fouls)+" faltas ")])])])])],1)})],2)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"550","persistent":""},model:{value:(_vm.action),callback:function ($$v) {_vm.action=$$v},expression:"action"}},[_c('v-card',{staticStyle:{"border-radius":"10px"},style:({
          background: _vm.$vuetify.theme.themes[_vm.theme].images,
        })},[_c('v-card-title',{staticClass:"text-h6 text-truncate",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.selected_student)+" ")]),_c('v-card-text',[_c('div',{staticClass:"text-subtitle-2"},[_vm._v(" Reporte de actividades al "+_vm._s(_vm.period_model.partial)+"° parcial, (en riesgo) ")]),_c('div',{staticClass:"text-center mt-5"},[_c('v-avatar',{attrs:{"size":"150"}},[_c('v-img',{attrs:{"lazy-src":require("../../../../assets/images/default.png"),"src":_vm.back_url + _vm.selected_photo}})],1),_c('div',{staticClass:"text-h6 warning--text mt-4"},[_vm._v(" "+_vm._s(_vm.partial.evidences_value)+"% total ")]),_c('div',{staticClass:"text-subtitle-2 info--text"},[_vm._v(" "+_vm._s(_vm.partial.evidences)+" de "+_vm._s(_vm.evidences_total)+" entregadas ")]),_c('div',{staticClass:"text-subtitle-2 mb-2"},[_c('span',{class:_vm.fouls_color},[_vm._v(_vm._s(_vm.partial.fouls)+" faltas")]),_vm._v(" en "+_vm._s(_vm.assists_total)+" clases "+_vm._s(_vm.beca_info)+" ")])],1)]),_c('v-card-text',{style:({
            background: _vm.$vuetify.theme.themes[_vm.theme].background,
          })},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"warning","dark":"","elevation":"0","block":""}},[_c('div',{staticClass:"text-subtitle-2",staticStyle:{"text-transform":"none"}},[_vm._v(" Informe ")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"outlined":"","elevation":"0","block":""},on:{"click":function($event){_vm.action = false}}},[_c('div',{staticClass:"text-subtitle-2",staticStyle:{"text-transform":"none"}},[_vm._v(" Cancelar ")])])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"80","persistent":""},model:{value:(_vm.updater),callback:function ($$v) {_vm.updater=$$v},expression:"updater"}},[_c('v-card',{staticStyle:{"border-radius":"10px"},attrs:{"loading":"true"}},[_c('div',{staticClass:"text-center my-6"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1)])],1),_c('v-dialog',{attrs:{"width":"350","persistent":""},model:{value:(_vm.downloading),callback:function ($$v) {_vm.downloading=$$v},expression:"downloading"}},[_c('v-card',{staticStyle:{"border-radius":"10px"},style:({
          background: _vm.$vuetify.theme.themes[_vm.theme].images,
        })},[_c('v-card-text',[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"mt-10 mb-5",attrs:{"color":"primary","size":"50","indeterminate":""}}),_c('div',{staticClass:"text-h6 mt-2 text--primary"},[_vm._v("Descargando")]),_c('div',{staticClass:"text-subtitle-2 text--primary mt-5"},[_vm._v(" Creación de archivo en curso ")]),_c('div',{staticClass:"text-caption font-weight-medium mt-2"},[_vm._v(" Se esta ensamblando el reporte solicitado, "),_c('span',{staticClass:"primary--text"},[_vm._v(" el tiempo de espera puede ser hasta de 2 minutos (120 seg.) ")]),_vm._v(" . Gracias por su paciencia. ")])],1)])],1)],1),_c('v-snackbar',{attrs:{"timeout":"2000","color":"info","top":"","right":""},model:{value:(_vm.responseView),callback:function ($$v) {_vm.responseView=$$v},expression:"responseView"}},[_c('div',{staticClass:"text-center text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.responseMessage)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }